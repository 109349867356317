import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import RoleList from "./RoleList";
import UserList from "./UserList";
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";

const SecurityManager = () => {
    const strings = useLocalizedStrings();
    const location = useLocation();
    const theme = useTheme();
    const { user, checkAccess } = useUser();

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} aria-label="basic tabs example">
                {checkAccess("security_user", Access.Read, true) &&
                    <Tab value="/security/users" label={strings.usersTabLabel} component={Link} to="users" />
                }
                {checkAccess("security_role", Access.Read, true) &&
                    <Tab value="/security/roles" label={strings.rolesTabLabel} component={Link} to="roles" />
                }
                </Tabs>
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Routes>
                    <Route path="users" element={<UserList />} />
                    <Route path="roles" element={<RoleList />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default SecurityManager;