import { Status as MapStatus, Wrapper } from "@googlemaps/react-wrapper";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
//import PinSuccessIcon from "../../../images/pin_success.svg";
//import PinErrorIcon from "../../../images/pin_error.svg";
//import PinWarningIcon from "../../../images/pin_warning.svg";
import { createStyles, makeStyles } from "@mui/styles";
import axios from "axios";
import { createCustomEqual, deepEqual } from "fast-equals";
import React, { ReactNode, useEffect } from 'react';
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import { getPropertyById, listProperties, getNetworkStatus } from "../../../api/dashboard/network/networkMonitorApi";
import { getPotsStatus } from "../../../api/dashboard/network/potsMonitorApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { NetworkStatus as NetworkStatusModel } from "../../../models/dashboard/network/NetworkStatus";
import { PotsStatus as PotsStatusModel } from "../../../models/dashboard/network/PotsStatus";
import { PropertyItemDetailModel as PropertyModel } from "../../../models/propertyModels";
import { useUser } from "../../../providers/UserProvider";
import { NotificationOptions } from "../../common/NotificationMessage";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import themePrimary from "../../../styles/themePrimary";
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from "@mui/material/Typography";
import TimeSpan from "../../../utilities/classes/TimeSpan";
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { UserProperty } from "../../../models/configuration/security/User";

const useStyles = makeStyles(() => 
    createStyles({
        markerLabel: {            
            //textStroke: "0.3px #ffffff",
            //webkitTextStrokeColor: "#ffffff"            
        },
        markerLabelHidden: {
            display: "none"
        },
        tabs: {
            "& .MuiTabs-indicator": {
                backgroundColor: themePrimary.palette.primary.main,                
            },
            "& .MuiTab-root.Mui-selected": {
                color: themePrimary.palette.primary.main
            }
        }
    })
);

const render = (status: MapStatus) => {
    return <h1>{status}</h1>;
};

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children?: React.ReactNode;
}

const Map: React.FC<MapProps> = ({
    onClick,
    onIdle,
    children,
    style,
    ...options
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    React.useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};

interface InfoWindowContentProps {            
    title: string;
    propertyStatus?: PropertyStatusModel;
    hasNetwork: boolean;
    hasPots: boolean;
    tabValue: number;
    onTabChanged: (tabValue: number) => void;
}

const InfoWindowContent = (props: InfoWindowContentProps) => {    
    const theme = useTheme();
    const strings = useLocalizedStrings();
    const classes = useStyles();  
    const { hasIncludedPropertyFeatures } = useUser();  

    //React.useEffect(() => {
    //    console.log(props.tabValue);
    //    }, [props.tabValue]);

    function handleTabChange(event: React.SyntheticEvent, newValue: number) {        
        props.onTabChanged(newValue);
    }

    function getStatus(status: string, statusText: string, statusDescription: string): ReactNode {
        switch (status)
        {
            case "Online": //Status.Online:
                return (
                    <Tooltip title={statusDescription} placement="bottom-start">
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CheckCircleIcon fontSize="small" color="success" />
                            {statusText}
                        </Stack>
                    </Tooltip>
                )
            case "Offline": //Status.Offline:
                return (
                    <Tooltip title={statusDescription} placement="bottom-start">
                        <Stack direction="row" alignItems="center" gap={1}>
                            <ErrorIcon fontSize="small" color="error" />
                            {statusText}
                        </Stack>
                    </Tooltip>
                )
            case "Other": //Status.Other:
            default:
                return (
                    <Tooltip title={statusDescription} placement="bottom-start">
                        <Stack direction="row" alignItems="center" gap={1}>
                            <WarningIcon fontSize="small" color="warning" />
                            {statusText}
                        </Stack>
                    </Tooltip>
                )                
        }
    }

    //if (props.propertyStatus?.network || props.propertyStatus?.pots) {      
        const networkStatus = props.propertyStatus?.network;
        const potsStatus = props.propertyStatus?.pots;
        
        // Render a table of the sensors. If there are multiple areas at this property, append the area to the name (eg. Circuit - Bldg. 2).
        const NetworkTab = () => (
            <div style={{ width: "600px", height: "382px" }}>
                {networkStatus ?
                    <table cellSpacing={theme.spacing(1)}> 
                        <thead>
                            <tr style={{ textAlign: "left" }}>                           
                                <th style={{ width: "120px", fontWeight: "bold" }}>{strings.status}</th>                                     
                                <th style={{ width: "200px", fontWeight: "bold" }}>{strings.sensor}</th>                    
                                <th style={{ width: "160px", fontWeight: "bold" }}>{strings.device}</th>                                 
                                <th style={{ width: "120px", fontWeight: "bold" }}>{strings.upDowntime}</th> 
                            </tr> 
                        </thead>
                        <tbody>
                            { networkStatus?.areas.map((a, idx) => {
                                return (
                                    <React.Fragment key={`cg_${idx}`}>
                                        <tr key={`c_${idx}`}>
                                            <td style={{ fontWeight: "normal" }}>{getStatus(a.circuit.status, a.circuit.statusText, a.circuit.statusDescription)}</td>     
                                            { networkStatus !== null && networkStatus.areas.length > 1 ? <td>{strings.circuit} - {a.name}</td> : <td>Circuit</td> }
                                            <td>{a.circuit.device}</td>                                            
                                            <td>{a.circuit.status.toUpperCase() === "ONLINE" ? new TimeSpan(a.circuit.uptime).toString("dhms") : new TimeSpan(a.circuit.downtime).toString("dhms")}</td>
                                        </tr>
                                        { a.gateway &&
                                            <tr key={`g_${idx}`}>
                                                <td style={{ fontWeight: "normal" }}>{getStatus(a.gateway.status, a.gateway.statusText, a.gateway.statusDescription)}</td>                                                
                                                { networkStatus !== null && networkStatus.areas.length > 1 ? <td>{strings.gateway} - {a.name}</td> : <td>Gateway</td> }                                                                                                                           
                                                <td>{a.gateway.device}</td>                                            
                                                <td>{a.gateway.status.toUpperCase() === "ONLINE" ? new TimeSpan(a.gateway.uptime).toString("dhms") : new TimeSpan(a.gateway.downtime).toString("dhms")}</td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table> :
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <CircularProgress sx={{ color: themePrimary.palette.primary.main }} />
                    </div>
                }
            </div>
        );
        
        // Render a table of the pots lines. If There are multiple areas at this property, append the area to the name (eg. Alarm 1 - Bldg. 2).
        const PotsTab = () => (
            <div style={{ width: "600px", height: "382px" }}>
                {potsStatus ?
                    <table cellSpacing={theme.spacing(1)}> 
                        <thead>
                            <tr style={{ textAlign: "left" }}>                           
                                <th style={{ width: "120px", fontWeight: "bold" }}>{strings.status}</th>                                     
                                <th style={{ width: "200px", fontWeight: "bold" }}>{strings.name}</th>                    
                                <th style={{ width: "160px", fontWeight: "bold" }}>{strings.phoneNumber}</th>
                            </tr> 
                        </thead>
                        <tbody>
                            { potsStatus?.areas.map((a, idx) => {
                                return a.lines.map((l, idx) => {
                                    // Skip any unused lines
                                    if (l.status === "Disabled") {
                                        return <React.Fragment key={`p_${idx}`}></React.Fragment>
                                    }

                                    return (                                    
                                        <tr key={`p_${idx}`}>
                                            <td style={{ fontWeight: "normal" }}>{getStatus(l.status, l.statusText, l.statusText)}</td>    
                                            { potsStatus !== null && potsStatus.areas.length > 1 ? <td style={{ fontWeight: "normal" }}>{l.name} - {a.name}</td> : <td style={{ fontWeight: "normal" }}>{l.name}</td> }
                                            <td style={{ fontWeight: "normal" }}>{l.phoneNumber}</td>
                                        </tr>                                    
                                    )
                            })})}
                        </tbody>
                    </table> :
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <CircularProgress sx={{ color: themePrimary.palette.primary.main }} />
                    </div>
                }
            </div>
        );

        // Render popup with either network, pots, or both, based on available scopes (show in tabs when both)
        const BodyContent = () => {
            if (props.hasNetwork && props.hasPots) {
                return  ( 
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs 
                                value={props.tabValue} 
                                onChange={handleTabChange} 
                                className={classes.tabs}
                                aria-label="basic tabs example"
                            >
                                <Tab label={strings.network} />
                                <Tab label={strings.pots} />
                            </Tabs>
                        </Box>
                        <TabPanelKeepMounted value={props.tabValue} index={0} noPadding={true}>
                            <NetworkTab />
                        </TabPanelKeepMounted>
                        <TabPanelKeepMounted value={props.tabValue} index={1} noPadding={true}>
                            <PotsTab />
                        </TabPanelKeepMounted>
                    </>
                )
            }
            else if (props.hasNetwork) {
                return (
                    <Typography component={"div"}>
                        <NetworkTab />
                    </Typography>                    
                );
            }
            else if (props.hasPots) {
                 return (
                    <Typography component={"div"}>
                        <PotsTab />
                    </Typography>                    
                );
            }
            else {
                return (<></>);
            }
        }
        
        // For some reason the dialog (Google code) has padding-right in an inline style, so add it back below, or it will look weird.
        return (            
            <div id="content">
                <div id="siteNotice">
                </div>
                <h2 id="firstHeading" className="firstHeading" style={{ paddingRight: "12px" }}>{props.title}</h2>
                <div id="bodyContent" style={{ paddingRight: "12px" }}>                    
                    <BodyContent />
                </div>
            </div>                          
        );    
    //}
    //else {
    //    return (
    //        <div id="content">
    //            <div id="siteNotice">
    //            </div>
    //            <h2 id="firstHeading" className="firstHeading">{props.title}</h2>
    //        </div>
    //    );
    //}
}

interface MarkerOptions extends google.maps.MarkerOptions {   
    propertyId: string;    
}

interface PropertyStatusModel {
    network?: NetworkStatusModel | undefined;
    pots?: PotsStatusModel | undefined;
    status: string;
}

/*
 * We will show color coded marker icons for each property. They will draw in gray initially, and then update as we get responses back.
 * The map starts out zoomed to a level that will show all the properties, and centered between them. You can click on a marker to see
 * the status of the Circut and Gateway sensors and POTS lines at the propery.
*/
const Marker: React.FC<MarkerOptions> = (options) => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const { propertyId } = options;
    const [marker, setMarker] = React.useState<google.maps.Marker>();
    const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();
    const theme = useTheme();  
    const strings = useLocalizedStrings();
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState); 
    const axiosInstance = useCreateAxios();
    const [propertyStatus, setPropertyStatus] = React.useState<PropertyStatusModel>();    
    const { hasIncludedPropertyFeatures } = useUser();  
    const [tabValue, setTabValue] = React.useState(0);
    const [loadPots, setLoadPots] = React.useState(false);

    // Each marker loads its own data
    React.useEffect(() => {
        var running = true;
        const cancelToken = axios.CancelToken.source();

        async function load() {
            if (!running) {
                return;
            }

            // While page is loaded refresh every 5-6 min (random)
            var refreshTimeout = getRandomInt(300000, 360000);                       

            setLoadPots(false);

            try {                
                var networkPayload: NetworkStatusModel;
                if (hasIncludedPropertyFeatures(["NETWORK", "NETWORK_CIRCUITONLY"], propertyId)) {
                    console.log(`Fetching network status ${propertyId}`);
                    networkPayload = await getNetworkStatus(axiosInstance, propertyId, cancelToken);

                    // If we got an error refresh in a min
                    //if (networkPayload.status === "Offline") {
                    //    refreshTimeout = 60000;
                    //}

                    console.log(`Got network status ${propertyId}`);
                    setPropertyStatus((prevState) => ({
                        ...prevState,
                        network: networkPayload,
                        status: getStatus(networkPayload, prevState?.pots)
                    }));                    
                }                                
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingAccessPoints.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }                
            
            setLoadPots(true);

            if (running) {
                setTimeout(() => { load(); }, refreshTimeout);
            }
        }
        
        load();

        return () => {
            cancelToken.cancel();            
            running = false;
        }
    }, [options.propertyId]);

    React.useEffect(() => {       
        //var running = true;        
        const cancelToken = axios.CancelToken.source();

        async function load() {
            //if (!running) {
            //    return;
            //}

            // While page is loaded refresh every 5-10 min (random)
            //var refreshTimeout = getRandomInt(300000, 600000);                       

            try {                               
                var potsPayload: PotsStatusModel;
                if (hasIncludedPropertyFeatures(["POTS"], propertyId)) {
                    console.log(`Fetching pots status ${propertyId}`);
                    potsPayload = await getPotsStatus(axiosInstance, propertyId, cancelToken);

                    console.log(`Got pots status ${propertyId}`);
                    setPropertyStatus((prevState) => ({
                        ...prevState,
                        pots: potsPayload,
                        status: getStatus(prevState?.network, potsPayload)
                    }));
                }                
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingAccessPoints.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }       

            //if (running) {
            //    setTimeout(() => { load(); }, refreshTimeout);
            //}
        }
        
        if (loadPots) {
            load();
        }

        return () => {
            cancelToken.cancel();
            //console.log("Stop pots timer");
            //running = false;
        }
    }, [loadPots]);

    function handleTabChanged(tabValue: number) {
        setTabValue(tabValue);
    }

    function getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }

    // Get a combined status for network and POTS
    function getStatus(network?: NetworkStatusModel, pots?: PotsStatusModel): string {
        if (network && pots) {
            if (network.status === "Online" && pots.status === "Online") {
                return "Online";
            }
            else if (network.status === "Offline" || pots.status === "Offline") {
                return "Offline";
            }
            else {
                return "Other";
            }
        }
        else if (network) {
            return network.status;
        }
        else if (pots) {
            return pots.status;
        }
        else {
            return "Other";
        }
    }

    // Get an icon for the marker that corresponds with the status. The server will return a "down" status if any sensor is down.
    function getStatusIcon(status: string) {
        let path;
        let color;

        if (hasIncludedPropertyFeatures(["NETWORK", "NETWORK_CIRCUITONLY", "POTS"], propertyId)) {
            // Not crazy about the transparent part of the svg. Maybe they'll add functionality for multi-color svgs in the future (or we can hack as below).
            // https://stackoverflow.com/questions/40991564/creating-a-multi-color-svg-icon-for-google-map-marker
            // https://stackoverflow.com/questions/14514274/multi-colored-google-map-svg-symbols
            switch (status.toUpperCase()) {
                case "ONLINE":
                    path = "M12.2,3.2c-4.2,0-8,3.2-8,8.2c0,3.3,2.7,7.2,8,11.8c5.3-4.6,8-8.5,8-11.8C20.2,6.4,16.4,3.2,12.2,3.2z M10.5,15.9L7,12.5l1.4-1.4l2.1,2l5.1-5L17,9.5L10.5,15.9z";
                    color = theme.palette.success.main;
                    break;
                case "OFFLINE": 
                    path = "M12,2c-4.2,0-8,3.22-8,8.2c0,3.32,2.67,7.25,8,11.8c5.33-4.55,8-8.48,8-11.8C20,5.22,16.2,2,12,2z M13,15h-2v-2h2V15z M13,11h-2V6h2V11z";
                    color = theme.palette.error.main;
                    break;
                case "OTHER":   
                    path = "M12,2c-4.2,0-8,3.22-8,8.2c0,3.32,2.67,7.25,8,11.8c5.33-4.55,8-8.48,8-11.8C20,5.22,16.2,2,12,2z M13,15h-2v-2h2V15z M13,11h-2V6h2V11z";
                    color = theme.palette.warning.main;
                    break;
                default:
                    path = "M12,2c-4.2,0-8,3.2-8,8.2c0,3.3,2.7,7.3,8,11.8c5.3-4.5,8-8.5,8-11.8C20,5.2,16.2,2,12,2z M12.9,16.3h-1.8v-1.8h1.8V16.3z M12.9,13.4h-1.8c0-2.8,2.6-2.6,2.6-4.4c0-1-0.8-1.7-1.8-1.7S10.2,8.1,10.2,9H8.5c0-1.9,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5 C15.5,11.2,12.9,11.4,12.9,13.4z";
                    color = theme.palette.grey[700];
                    break;
            }
        }
        else {
            path = "M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Zm0,9.5A2.5,2.5,0,1,1,14.5,9,2.5,2.5,0,0,1,12,11.5Z";
            color = theme.palette.primary.main;            
        }

        const icon = {
            path: path,
            fillColor: color,
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: 2,
            anchor: new google.maps.Point(12, 24),
            labelOrigin: new google.maps.Point(12, -2)
        };
            
        //let icon;
        //switch (options.status.toUpperCase()) {
        //    case "ONLINE":
        //        icon = PinSuccessIcon;
        //        break;
        //    case "OFFLINE": 
        //        icon = PinErrorIcon;
        //        break;
        //    case "UNKNOWN":
        //    default:
        //        icon = PinWarningIcon;
        //        break;
        //}  

        return icon;
    }

    function getInfoWindowContent() {
        // Get the InfoWindowContent component and render it in the popup                
        // https://stackoverflow.com/questions/29586411/react-js-is-it-possible-to-convert-a-react-component-to-html-doms
        const div = document.createElement('div'); 
        ReactDOM.render(<InfoWindowContent title={(options.label as google.maps.MarkerLabel).text} propertyStatus={propertyStatus} tabValue={tabValue} onTabChanged={handleTabChanged} hasNetwork={hasIncludedPropertyFeatures(["NETWORK", "NETWORK_CIRCUITONLY"], propertyId)} hasPots={hasIncludedPropertyFeatures(["POTS"], propertyId)} />, div);  
        return div;
    }
    
    React.useEffect(() => {
        if (!marker) {                 
            var status = propertyStatus?.status ?? "UNKNOWN";
            const icon = getStatusIcon(status);                

            // https://developers.google.com/maps/documentation/javascript/advanced-markers/accessible-markers            
            const marker = new google.maps.Marker({                
                icon: icon                
            });    

            const iw = new google.maps.InfoWindow();    
            iw.setContent(getInfoWindowContent());
            setInfoWindow(iw); // Store info window in state so we can update it later

            marker.addListener("click", ({ domEvent, latLng }: any) => {
                const { target } = domEvent;                                     
                iw.close();                            
                iw.open(null, marker);                
            });            

            setMarker(marker);
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker, propertyStatus]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
            
            // Update icon when status changes
            var status = propertyStatus?.status ?? "UNKNOWN";
            const icon = getStatusIcon(status); 
            marker.setIcon(icon);

            // Rerender and update popup content
            infoWindow?.setContent(getInfoWindowContent()); 
        }
    }, [marker, options, propertyStatus, tabValue]);

    return null;
};

// This code (from the Google example) is left commented out on purpose (see new code below).
//const deepCompareEqualsForMaps = createCustomEqual(
//    (deepEqual) => (a: any, b: any) => {
//        if (
//            isLatLngLiteral(a) ||
//            a instanceof google.maps.LatLng ||
//            isLatLngLiteral(b) ||
//            b instanceof google.maps.LatLng
//        ) {
//            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
//        }

//        // TODO extend to other types

//        // use fast-equals for other objects
//        return deepEqual(a, b);
//    }
//);

// https://githubrecord.com/issue/googlemaps/js-samples/1221/1198482513
// There is an issue with the Google example code above. This is the fix.
const deepCompareEqualsForMaps = createCustomEqual(() => ({
    areObjectsEqual: (a, b) => {
        if (isLatLngLiteral(a) || a instanceof google.maps.LatLng || isLatLngLiteral(b) || b instanceof google.maps.LatLng) {
            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
        }

        return deepEqual(a, b);
    },
}));

function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(
    callback: React.EffectCallback,
    dependencies: any[]
) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

// https://stackoverflow.com/questions/6671183/calculate-the-center-point-of-multiple-latitude-longitude-coordinate-pairs
// Get a point in the middle of all locations
function findMapCenter(points: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral {
    let sumX: number = 0.0;
    let sumY: number = 0.0;
    let sumZ: number = 0.0;    
   
    points.forEach((p: google.maps.LatLngLiteral) => {
        sumX += Math.cos(Math.degToRad(p.lat)) * Math.cos(Math.degToRad(p.lng));
        sumY += Math.cos(Math.degToRad(p.lat)) * Math.sin(Math.degToRad(p.lng));
        sumZ += Math.sin(Math.degToRad(p.lat));
    });

    const x = sumX / points.length;
    const y = sumY / points.length;
    const z = sumZ / points.length;

    const lon = Math.atan2(y, x);
    const hyp = Math.sqrt(x * x + y * y);
    const lat = Math.atan2(z, hyp);

    return { lat: Math.radToDeg(lat), lng: Math.radToDeg(lon) };
}

// Get a zoom level that will show all locations (might not work exactly on a small screen/browser window).
function findMapZoom(points: google.maps.LatLngLiteral[]): number {
    var distance = 0;
    var lastPoint = points[0];

    // Find the max distance between any 2 locations
    for (var i = 1; i < points.length; i++) {
        var temp = Math.haversine(points[i].lat, points[i].lng, lastPoint.lat, lastPoint.lng);
        if (temp > distance) {
            distance = temp;
        }
        lastPoint = points[i];
    }

    // https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to
    var a = [591657550.500000, 295828775.300000, 147914387.600000, 73957193.820000, 36978596.910000, 18489298.450000, 9244649.227000, 4622324.614000, 2311162.307000, 1155581.153000, 577790.576700, 288895.288400, 144447.644200, 72223.822090, 36111.911040, 18055.955520, 9027.977761, 4513.988880, 2256.994440, 1128.497220];
   
    // Zoom all the way out
    if (distance >= 591657550.500000) {
        return 0;
    }

    // Find zoom level that shows all locations
    for (var i = 0; i < a.length - 1; i++) {
        if (distance < a[i] && distance >= a[i + 1]) {
            return i - 2 < 0 ? 0 : i - 2; // There seems to be an issue here. Using the array above will be zoomed in too far (by 2 levels)
        }
    }

    // Zoom all the way in
    return 19;
}

const Status = () => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [properties, setProperties] = React.useState<UserProperty[]>([]);        
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);    
    const strings = useLocalizedStrings();
    const classes = useStyles();
    const axiosInstance = useCreateAxios();
    const { user, hasIncludedPropertyFeatures } = useUser();  
    const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
    const [zoom, setZoom] = React.useState(5); // Initial zoom (entier US)
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({ lat: 47.116386, lng: -101.299591 }); // Initial center (center of US)        

    useEffect(() => {        
        async function load() {
            try {   
                // This will kill any existing markers, which will cancel any http requests running.
                setProperties([]);               
                var properties = getAllProperties();
                setProperties(properties);
                var pts: google.maps.LatLngLiteral[] = properties.map(p =>  ({ lat: p.latitude ?? 0, lng: p.longitude ?? 0 })); // () are required to return an object literal

                // Set map center and zoom                
                if (pts && pts.length > 0) {
                    setCenter(findMapCenter(pts));
                    setZoom(findMapZoom(pts));
                }                                      
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingProperties.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }
        }

        load();

    }, [user.currentProperty?.code, strings.errorRetrievingAccessPoints]);       

    function getAllProperties() {
        var array: UserProperty[] = [];
        user.brands.forEach(b => {
            array = array.concat(b.properties);
        });
        array.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
        return array;
    }   

    const onIdle = (m: google.maps.Map) => {
        console.log("onIdle");
        setZoom(m.getZoom()!);
        setCenter(m.getCenter()!.toJSON());
    };

    /*    
     * In order to hide the marker label when zoomed out we conditionally set the CSS class to set display: none. This allows us to still pass the 
     * label so it can be used in the popup regardless of the zoom level.
     * 
     * NOTE: The <style> is a hack to remove scrollbars on the info window
    */
    return (
        <>
            <style>
                {`
                    .gm-style-iw-d {
                        overflow: hidden !important; 
                    }
                `}
            </style>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""} render={render}>
                <Map
                    center={center}                
                    onIdle={onIdle}
                    zoom={zoom}                
                    style={{ width: "100%", height: "100%" }}
                    mapTypeControl={false}
                    streetViewControl={false}
                    fullscreenControl={false}    
                    styles={[ { featureType: "poi", stylers: [{ visibility: 'off' }]} ]}
                >       
                    {properties.map((property: UserProperty) => (                             
                        <Marker key={property.id} position={{ lat: property.latitude ?? 0, lng: property.longitude ?? 0 }} propertyId={property.id ?? ""} label={{ text: property.name?.en ?? "", fontSize: "16px", fontWeight: "bold", className: zoom > 9 ? classes.markerLabel : classes.markerLabelHidden }} clickable={true} /> 
                    ))}
                </Map>
            </Wrapper>
        </>
    );
};

export default Status;
