import {useEffect, useState } from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DataGrid from "../../common/datatable/DataGridWrapper";
import { GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import LinearProgress from '@mui/material/LinearProgress';
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { Button, TextField as MuiTextField, MenuItem, FormControl, InputLabel, Grid, Box, Typography, Card } from "@mui/material";
import TextField from "../../common/details/TextField";
import { Theme, useTheme } from "@mui/material/styles";
import themePrimary from "../../../styles/themePrimary";
import { makeStyles } from "@mui/styles";
import AppDialog from "../../common/AppDialog";
import TicketNoteList from "./TicketNoteList";
import { useUser } from "../../../providers/UserProvider";
import axios from "axios";
import { ServiceTicket } from "../../../models/dashboard/support/ServiceTicket";
import TicketDocumentList from "./TicketDocumentList";
import { getTicket } from "../../../api/dashboard/support/ticketApi";
import { createNote } from "../../../api/dashboard/support/noteApi";
import Spacer from "../../common/Spacer";
import AddIcon from '@mui/icons-material/Add';
import DetailsDialog from "../../common/details/DetailsDialog";
import validator from "validator";
import { ServiceTicketNote } from "../../../models/dashboard/support/ServiceTicketNote";
import { Access } from "../../../models/configuration/security/Permission";

const useStyles = makeStyles({
    detailContainer: {
        margin: themePrimary.spacing(1),
    },
    detailGrid: {
        padding: themePrimary.spacing(2),
        backgroundColor: themePrimary.palette.primary.main,
        color: themePrimary.palette.primary.contrastText,
    },
    detailLabel: {
        fontWeight: 'bold',
    },
});

interface ValidationErrors {
    note: string;
}

export interface TicketNoteDialogProps {
    notesDialogOpen: boolean;
    serviceTicket: ServiceTicket;
    closeDialogCallback: () => void;
}

const TicketNoteDialog = (props: TicketNoteDialogProps) => {  
    const initialErrorState: ValidationErrors = {
        note: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const { notesDialogOpen, serviceTicket, closeDialogCallback } = props;
    const [loadedServiceTicket, setLoadedServiceTicket] = useState<ServiceTicket>(props.serviceTicket);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const classes = useStyles(theme);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [noteDialogOpen, setNoteDialogOpen] = useState(false);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [note, setNote] = useState("");
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        async function loadTicket() {
            
            try {                
                const payload = await getTicket(axiosInstance, user.currentProperty?.code ?? "", props.serviceTicket.id);                
                setLoadedServiceTicket(payload);
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingActivities.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            } 
            finally {
                setIsLoading(false);
            }
        }

        setIsLoading(true);

        if (props.serviceTicket.mustGetDetails) {
            loadTicket();
        }
        else {
            setLoadedServiceTicket(props.serviceTicket);
        }
    }, [serviceTicket, refresh, strings.errorRetreivingActivities, strings.errorRetreivingTypes]);

    function handleNotesDialogCancelClick(event: React.MouseEvent<HTMLButtonElement>) {        
        closeDialogCallback();
    }

    function formatOpenedDateTime() {
        return new Date(loadedServiceTicket!!.openDateTime).toLocaleDateString() + " " + new Date(loadedServiceTicket!!.openDateTime).toLocaleTimeString()
    }

    function formatClosedDateTime() {
        return new Date(loadedServiceTicket!!.closedDateTime).toLocaleDateString() + " " + new Date(loadedServiceTicket!!.closedDateTime).toLocaleTimeString()
    }

    function formatLastUpdatedDateTime() {
        return new Date(loadedServiceTicket!!.lastUpdatedDateTime).toLocaleDateString() + " " + new Date(loadedServiceTicket!!.lastUpdatedDateTime).toLocaleTimeString()
    }

    const handleNotesLoaded = () => {    
        setIsLoading(false);
    }

    function getTitle() {
        if (loadedServiceTicket.summary && loadedServiceTicket.summary !== "") {
            return strings.ticketDialogTitle.replace("{{ticket}}", loadedServiceTicket.number.toString() + " - " + loadedServiceTicket.summary);
        }
        else {
            return strings.ticketDialogTitle.replace("{{ticket}}", loadedServiceTicket.number.toString());
        }
    }    

    function markdownToHtml(text: string) {
        var text = text.replaceAll("\n", "<br/ >");

        // Fix images [image](<url>) -> <img src='<url>'>
        const regEx = RegExp(/!\[[^\]]*\]\((?<filename>.*?)(?=\"|\))(?<optionalpart>\".*\")?\)/g);
        text = text.replace(regEx, "<img src='$1' style='max-width:1100px;' />");

        return text;
    }

    function handleAddNote(event: React.MouseEvent<HTMLButtonElement>) {
        setNoteDialogOpen(true);
    }

    function handleOpenNoteDialogCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setNoteDialogOpen(false);
        setNote("");
    }

    async function handleAddNoteDialogSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        var newNote: ServiceTicketNote = {
            ticketId: loadedServiceTicket.id,
            text: note
        }

        try {            
            await createNote(axiosInstance, loadedServiceTicket.id, newNote);            
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorSavingNote.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }    

        setNoteDialogOpen(false);
        setNote("");
        setRefresh(!refresh);

        setNotify({
            isOpen: true,
            message: strings.noteSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleNoteChange(event: React.ChangeEvent<HTMLInputElement>) {
        setNote(event.target.value)
    }

    function handleValidateNote() {
        if (note === "") {
            setErrors({ note: strings.validationErrorTicketNoteRequired });
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <>
            <AppDialog
                open={notesDialogOpen}
                title={getTitle()}
                onCancelClick={handleNotesDialogCancelClick}
                contentSize={{ width: 1000, height: 563 }}            
            >
                {isLoading &&
                    <LinearProgress />
                }
                <Box sx={{ marginTop: isLoading ? `${(parseInt(theme.spacing(2)) - 4)}px` : theme.spacing(2), marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                    {loadedServiceTicket !== undefined && loadedServiceTicket !== null &&
                        <Card>
                            <Grid container spacing={2} className={classes.detailGrid}>
                                <Grid item xs={10}>
                                    <Typography><span className={classes.detailLabel}>{strings.ticketPropertyNameLabel}</span> {loadedServiceTicket!!.companyName}</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography><span className={classes.detailLabel}>{strings.ticketSummaryLabel}</span> {loadedServiceTicket!!.summary}</Typography>
                                </Grid>
                                <Grid item xs={10} sm={6}>
                                    <Typography><span className={classes.detailLabel}>{strings.ticketTicketNumberLabel}</span> {loadedServiceTicket!!.number}</Typography>
                                </Grid>  
                                <Grid item xs={10} sm={6}>
                                    <Typography><span className={classes.detailLabel}>{strings.ticketStatusLabel}</span> {loadedServiceTicket!!.status}</Typography>
                                </Grid>   
                                {loadedServiceTicket!!.status.toString().toLowerCase() !== "closed" &&                 
                                    <Grid item xs={10} sm={6}>
                                        <Typography><span className={classes.detailLabel}>{strings.ticketOwnerLabel}</span> {loadedServiceTicket!!.owner}</Typography>
                                    </Grid>
                                }
                                {loadedServiceTicket!!.status.toString().toLowerCase() !== "closed" &&
                                    <Grid item xs={10} sm={6}>
                                        <Typography><span className={classes.detailLabel}>{strings.ticketLastUpdatedLabel}</span> {formatLastUpdatedDateTime()}</Typography>
                                    </Grid>
                                }
                                <Grid item xs={10} sm={6}>
                                    <Typography><span className={classes.detailLabel}>{strings.ticketOpenedLabel}</span> {formatOpenedDateTime()}</Typography>
                                </Grid>
                                <Grid item xs={10} sm={6}>
                                    <Typography><span className={classes.detailLabel}>{strings.ticketOpenedByLabel}</span> {loadedServiceTicket!!.openedBy}</Typography>
                                </Grid>
                                {loadedServiceTicket!!.status.toString().toLowerCase() === "closed" &&  
                                    <Grid item xs={10} sm={6}>
                                        <Typography><span className={classes.detailLabel}>{strings.ticketClosedLabel}</span> {formatClosedDateTime()}</Typography>
                                    </Grid>
                                }
                                {loadedServiceTicket!!.status.toString().toLowerCase() === "closed" &&  
                                    <Grid item xs={10} sm={6}>
                                        <Typography><span className={classes.detailLabel}>{strings.ticketClosedByLabel}</span> {loadedServiceTicket!!.closedBy}</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Card>
                    }                                                                               
                    <Spacer y={2} />
                    {loadedServiceTicket.description && loadedServiceTicket.description !== "" &&
                        <>
                            <Box sx={{ paddingTop: themePrimary.spacing(2), paddingBottom: themePrimary.spacing(2), borderBottom: '1px solid ' + themePrimary.palette.primary.main }}>
                                <div dangerouslySetInnerHTML={{ __html: markdownToHtml(loadedServiceTicket.description) }} />
                            </Box>
                            <Spacer y={2} />
                        </>
                    }
                    {loadedServiceTicket.documents &&
                        <>
                            <TicketDocumentList 
                                ticketId={loadedServiceTicket.id} 
                                documents={loadedServiceTicket.documents}
                                onDocumentsChanged={() => { setRefresh(!refresh) }} />                
                            <Spacer y={2} />
                        </>
                    }
                    {checkAccess("dashboard_support", Access.Create) &&
                        <div style={{ textAlign: "right" }}>
                            <Button
                                startIcon={<AddIcon />}                            
                                variant="contained"
                                size="large"
                                onClick={handleAddNote}
                                aria-label="Add Note"                    
                            >
                                {strings.ticketAddNoteLabel}                    
                            </Button>
                            <Spacer y={2} />
                        </div>
                    }
                    <TicketNoteList 
                        ticketId={loadedServiceTicket.id}
                        mustGetNotes={!loadedServiceTicket.mustGetDetails} // If we didn't get the details we need to get the notes
                        notes={loadedServiceTicket.notes}
                        onNotesLoaded={handleNotesLoaded}
                        onNotesChanged={() => { setRefresh(!refresh) }} />
                </Box>
            </AppDialog>

            <DetailsDialog
                permissionKey="dashboard_support"
                adding={true}
                hideAudit={true}
                open={noteDialogOpen}
                title={strings.ticketAddNoteTitle}
                onValidateForm={handleValidateNote}
                onCancelClick={handleOpenNoteDialogCancelClick}
                onSaveClick={handleAddNoteDialogSaveClick}
                contentSize={{ width: 600, height: 239.91 }}
            >
                <Box sx={{ width: "100%", padding: theme.spacing(2) }}>                        
                    <TextField
                        id="ticket-description"
                        label=""
                        width="100%"
                        multiline
                        rows={8}            
                        value={note}
                        onChange={handleNoteChange}
                        error={Boolean(errors.note)}
                        helperText={errors.note} />
                </Box>
            </DetailsDialog>
        </>
    );
}

export default TicketNoteDialog;