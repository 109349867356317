import Typography from "@mui/material/Typography";
import React from "react";
import { useUser } from "../../providers/UserProvider";

const PropertyClock = () => {
    const { user } = useUser(); 
    const [date, setDate] = React.useState<string | null>();   
    const [intervalId, setIntervalId] = React.useState<any>();

    React.useEffect(() => {
        if (user.currentProperty && user.currentProperty.timeZoneId) {    
            if (intervalId) {
                clearInterval(intervalId);
            }

            const locale = window.navigator.language;            

            setIntervalId(setInterval(() => {                   
                const newDate = new Date().toLocaleTimeString(locale, { hour: 'numeric', minute:'2-digit', timeZone: user.currentProperty?.timeZoneId });
                setDate(newDate);                
            }, 1000));
        }
    }, [user.currentProperty]);

    return (
        <Typography fontWeight="inherit">{date ?? ""}</Typography>
    );
};

export default PropertyClock;