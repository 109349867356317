import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import Box from '@mui/material/Box';
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { getBrandDropdownData, getPropertyGridData } from "../../../api/accounts/propertyApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { BrandItemModel } from "../../../models/brandModels";
import { PropertyItemModel } from "../../../models/propertyModels";
import { useUser } from "../../../providers/UserProvider";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper";
import { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import SystemGraphic from "../../common/image/SystemGraphic";
import { useLocation } from "react-router-dom";

const SystemGraphics = () => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const { user } = useUser();   
    const location = useLocation();
    const [brandData, setBrandData] = useState<BrandItemModel[]>();
    const [selectedBrandId, setSelectedBrandId] = useState(location.pathname === "/skin/default/graphics" ? "DEFAULT" : "");
    const [propertyData, setPropertyData] = useState<PropertyItemModel[]>();
    const [selectedPropertyId, setSelectedPropertyId] = useState("");    
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();    
    const axiosInstance = useCreateAxios();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);    

    useEffect(() => {
        async function getBrands() {
            try {
                const payload = await getBrandDropdownData(axiosInstance);
                setBrandData(payload);
            } catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingBrands,
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        if (location.pathname !== "/skin/default/graphics") {
            getBrands();   
        }
    }, [strings.errorRetrievingBrands]);

    useEffect(() => {
        async function getProperties() {
            if (selectedBrandId === "") {
                setPropertyData([]);
                return;
            }

            try {
                const payload = await getPropertyGridData(axiosInstance, selectedBrandId);
                const properties = payload.map(pr => parseLanguageStringsFromJson(pr, "en"));
                setPropertyData(properties);
            } catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingProperties,
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        if (selectedBrandId !== "") {
            getProperties();
        }
    }, [selectedBrandId, strings.errorRetreivingProperties]);

    function handleBrandSelectionChange(e: SelectChangeEvent) {
        const value = e.target.value as string;        
        setSelectedBrandId(value);
        setSelectedPropertyId("ALL");        
    }

    function handlePropertySelectionChange(e: SelectChangeEvent) {
        setSelectedPropertyId(e.target.value as string);
    }

    function getBrands() {
        let kvps: { key: string; value: string }[] = [];
        if (brandData) {
            brandData.forEach(b => {
                kvps.push({ key: b.id, value: JSON.parse(b.name).en });
            });
        }              
        return kvps;
    }

    function getProperties() {
        let kvps: { key: string; value: string }[] = [{ key: "ALL", value: "All" }];
        if (propertyData) {
            propertyData.forEach(b => {
                kvps.push({ key: b.id, value: b.name });
            });
        }              
        return kvps;
    } 

    return (
        <Box sx={{ padding: theme.spacing(2) }}>      
        {location.pathname !== "/skin/default/graphics" &&
            <>
                <Stack direction="row">
                    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-label">
                            {strings.brandSelectLabel}
                        </InputLabel>
                        <Select
                            id="selectFieldPropertyBrandList"
                            value={selectedBrandId}
                            label={strings.brandSelectLabel}
                            onChange={handleBrandSelectionChange}
                            MenuProps={{
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                transformOrigin: { vertical: "top", horizontal: "left" }
                            }}
                        >
                            {getBrands().map((kvp, index) => (
                                <MenuItem key={index} value={kvp.key}>{kvp.value}</MenuItem>
                            ))}                        
                        </Select>
                        </FormControl>
                        <Spacer x={2}/>                        
                        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-label">
                                {strings.propertySelectLabel}
                            </InputLabel>
                            <Select
                                id="selectFieldPropertyPropertyList"
                                value={selectedPropertyId}
                                label={strings.propertySelectLabel}
                                onChange={handlePropertySelectionChange}
                                MenuProps={{
                                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                    transformOrigin: { vertical: "top", horizontal: "left" }
                                }}
                            >                                                                                    
                                {getProperties().map((kvp, index) => (
                                    <MenuItem key={index} value={kvp.key}>{kvp.value}</MenuItem>
                                ))}                                
                            </Select>
                        </FormControl>                        
                    </Stack>
                    <Spacer y={2} />
                </>
            }
            {selectedBrandId !== "" &&
                <>
                    <SystemGraphic
                        imageKey="logo"
                        label="Logo"
                        height={160}
                        width={856}
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId} />
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="loading"
                        label="Loading"
                        height={1231}
                        width={1480} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="promotion_placeholder"
                        label="Promotion Placeholder"
                        height={540}
                        width={1920} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="confirm"
                        label="Confirm"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="information"
                        label="Information"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="question"
                        label="Question"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="error"
                        label="Error"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="arrow_down"
                        label="Arrow Down"
                        height={90}
                        width={120} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />
            
                    <SystemGraphic
                        imageKey="arrow_up"
                        label="Arrow Up"
                        height={90}
                        width={120} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="message_read"
                        label="Message Read"
                        height={90}
                        width={90} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="message_unread"
                        label="Message Not Read"
                        height={90}
                        width={90} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="message_read_highpriority"
                        label="Message Read High Priority"
                        height={90}
                        width={90} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="message_unread_highpriority"
                        label="Message Not Read High Priority"
                        height={90}
                        width={90} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="opentable_logo"
                        label="Open Table Logo"
                        height={140}
                        width={375} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_1"
                        label="Weather 1"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_2"
                        label="Weather 2"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_3"
                        label="Weather 3"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_4"
                        label="Weather 4"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_5"
                        label="Weather 5"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_6"
                        label="Weather 6"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_7"
                        label="Weather 7"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_8"
                        label="Weather 8"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_9"
                        label="Weather 9"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_10"
                        label="Weather 10"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />
            
                    <SystemGraphic
                        imageKey="weather_11"
                        label="Weather 11"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_12"
                        label="Weather 12"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_13"
                        label="Weather 13"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_14"
                        label="Weather 14"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_15"
                        label="Weather 15"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_16"
                        label="Weather 16"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_17"
                        label="Weather 17"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />

                    <SystemGraphic
                        imageKey="weather_18"
                        label="Weather 18"
                        height={100}
                        width={100} 
                        brandId={selectedBrandId}
                        propertyId={selectedPropertyId}/>
                    <Spacer y={2} />
                </>
            }
        </Box>
    );
};

export default SystemGraphics;