import * as React from "react";
import { StringLanguageDict } from "../../../models/common/LanguageModel";
import RichTextField from "../details/RichTextField";
import { useSelectedLanguage } from "./LanguageForm";

export type LanguageRichTextFieldChangeHandler = (content: string, langauge: string) => void;

//type RichTextFieldStyleProps = {
//    RichTextFieldWidth: number;
//};

export interface LanguageRichTextFieldProps {
    id?: string;
    label?: string;
    values: StringLanguageDict;
    onChange: LanguageRichTextFieldChangeHandler;
    width?: string | number;
    height?: string | number;
    disabled?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
    permissionKey?: string;
    customButtons?: string[];
    customHandlers?: any;
}

const LanguageRichTextField = (props: LanguageRichTextFieldProps) => {
    const { selectedLanguage, setSelectedLanguage } = useSelectedLanguage();

    const handleChange = (content: string) => {        
       props.onChange(content, selectedLanguage);
    };

    return (
        <RichTextField {...props} value={props.values[selectedLanguage]} onChange={handleChange} />
    );
}

export default LanguageRichTextField;