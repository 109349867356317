import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import * as React from 'react';
import { createLocation, deleteLocation, getLocations, updateLocation } from '../../../api/settings/locationApi';
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { LocationModel, initialLocationState } from "../../../models/configuration/settings/Location";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from '../../common/Spacer';
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from '../../common/datatable/DataGridEditButton';
import DataGrid from '../../common/datatable/DataGridWrapper';
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";

interface LocationValidationErrors {
    name: string;
    description: string;
}

function Locations() {
    const initialLocationErrorState: LocationValidationErrors = {
        name: "",
        description: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [locations, setLocations] = React.useState<LocationModel[]>([]);   
    const [selectedLocationId, setSelectedLocationId] = React.useState("");
    const [selectedLocation, setSelectedLocation] = React.useState<LocationModel>(initialLocationState);   
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);   
    const [gridRefresh, setGridRefresh] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<LocationValidationErrors>(initialLocationErrorState);    
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const alertDialog = useAlertDialog();
    const theme = useTheme();

    React.useEffect(() => {
        async function loadLocations() {
            try {
                setIsLoading(true);
                const response = await getLocations(axiosInstance, user.currentProperty?.code ?? "");
                setLocations(response);
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingLocations.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        loadLocations();
    }, [gridRefresh, strings.errorRetrievingLocations, user.currentProperty?.code]);

    function handleEditRowClick(id: string) {        
        setSelectedLocationId(id);
        var activity = locations?.find(p => p.id === id);
        if (activity) {
            setSelectedLocation(activity);
            setDetailsDialogOpen(true);
        }
    }

    function handleAddClick() {
        setSelectedLocationId("");
        setSelectedLocation(initialLocationState);
        setDetailsDialogOpen(true);
    }

    async function deleteSelectedLocation(id: string) {
        try {
            await deleteLocation(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorDeletingLocation.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.locationDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedLocationId("");
        setSelectedLocation(initialLocationState);
    }

    function handleDeleteClick(id: string) {
        const name = locations.find(p => p.id === id)?.name?.en ?? "";

        alertDialog({
            title: strings.deleteLocationAlertTitle,
            message: strings.deleteLocationAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedLocation(id);
        });
    }

    function handleCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setDetailsDialogOpen(false);
    }

    async function handleSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {   
         setErrors(initialLocationErrorState);

        var id = selectedLocationId;
        const isUpdate = selectedLocationId !== "";
        try {
            if (isUpdate) {
                await updateLocation(axiosInstance, user.currentProperty?.code ?? "", selectedLocation);
            }
            else {
                id = await createLocation(axiosInstance, user.currentProperty?.code ?? "", selectedLocation);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
               message: (!isUpdate
                    ? strings.errorAddingActivity
                    : strings.errorUpdatingActivity
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            })

            return false;
        }

        setDetailsDialogOpen(false);        
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.locationSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateLocation() {
        var errors = validate<LocationModel, LocationValidationErrors>([
            { property: "name", type: ValidationType.Required, message: strings.validationPropertyLocationName }            
        ], selectedLocation);
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

    function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedLocation((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleDescriptionChange(content: string, language: string) {
        setSelectedLocation((prevState) => ({
            ...prevState,
            description: {
                ...prevState.description,
                [language]: content,
            },
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedLocation((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    const gridColumns: GridColDef[] = [       
        { field: "name", headerName: strings.location, valueGetter: (value, row) => row.name.en, flex: 4 },
        { field: "enabled", headerName: strings.enabled, type: "boolean", flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="configuration_settings"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditRowClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="configuration_settings"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
            ],
        },
    ];

    const dialogTitle = selectedLocationId === "" ? strings.addLocationTitle : strings.editLocationTitle.replace("{{name}}", selectedLocation?.name?.en ?? ""); // TODO: default language
    
    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 123px)" }}> 
            <DataGrid
                permissionKey="configuration_settings"                    
                addButtonText={strings.addLocationButton}
                onAddButtonClick={handleAddClick}
                columns={gridColumns}
                rows={locations}
            />
            <DetailsDialog
                permissionKey="configuration_settings"
                open={detailsDialogOpen}
                adding={selectedLocationId === ""}
                title={dialogTitle}
                onValidateForm={handleValidateLocation}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                contentSize={{ width: 879.5, height: 512.56 }}
            >
                <Box m={2}>
                    <LanguageForm>
                        <LanguageSelect />
                        <Spacer />
                        <LanguageTextField
                            id="item-name"
                            label={strings.name}
                            values={selectedLocation.name ?? {}}
                            width={410}
                            onChange={handleNameChange}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                        />
                        <Spacer />
                        <LanguageRichTextField
                            values={selectedLocation.description ?? {}}
                            label={strings.description}
                            id="item-description"
                            onChange={handleDescriptionChange}                                
                            width="100%"
                        />
                        <Spacer />
                        <Checkbox
                            label={strings.enabled}
                            checked={selectedLocation.enabled}
                            onChange={handleEnabledChange}
                        />
                    </LanguageForm>  
                </Box>
            </DetailsDialog>
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>      
    );    
}

export default Locations