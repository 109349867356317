import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { createFeature, deleteFeature, getFeature, listFeatures, updateFeature } from "../../../../api/accounts/featureApi";
import { useCreateAxios } from "../../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../../localization/LocalizedStringsProvider";
import { Feature, initialFeatureState } from "../../../../models/accounts/Feature";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../../utilities/Validator";
import { useAlertDialog } from "../../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../../common/NotificationMessage";
import Spacer from "../../../common/Spacer";
import TabPanelKeepMounted from "../../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../../common/datatable/DataGridEditButton";
import DataGrid from "../../../common/datatable/DataGridWrapper";
import Checkbox from "../../../common/details/Checkbox";
import DetailsDialog from "../../../common/details/DetailsDialog";
import TextField from "../../../common/details/TextField";
import { useTheme } from '@mui/material/styles';

interface ValidationErrors {
    id: string;
    name: string;
}

const initialErrorState: ValidationErrors = {
    id: "",
    name: "",
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
}

function FeatureList() {
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [gridRefresh, setGridRefresh] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [featureList, setFeatureList] = useState<Feature[]>([]);
    const [selectedFeatureId, setSelectedFeatureId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [selectedFeature, setSelectedFeature] = useState<Feature>(initialFeatureState);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();

    useEffect(() => {
        async function getFeatures() {
            try {
                const payload = await listFeatures(axiosInstance);
                setFeatureList(payload);
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingFeatures.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        getFeatures();
    }, [gridRefresh])

    function handleAddClick() {
        setSelectedFeatureId("");
        setSelectedFeature(initialFeatureState);
        setDetailsDialogOpen(true);
    }

    async function handleEditRowClick(id: string) {
        setSelectedFeatureId(id);

        try {
            const payload = await getFeature(axiosInstance, id);
            setSelectedFeature(payload);
            setDetailsDialogOpen(true);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingFeature.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    async function handleSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const isUpdate = selectedFeatureId.length > 0;

        try {
            if (isUpdate) {
                await updateFeature(axiosInstance, selectedFeature);
            } else {
                await createFeature(axiosInstance, selectedFeature);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorCreatingFeature
                    : strings.errorUpdatingFeature
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }

        setSelectedFeatureId("");
        setGridRefresh(!gridRefresh);
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
        setNotify({
            isOpen: true,
            message: strings.featureSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleDeleteClick(id: string) {
        const displayName = featureList.find(feature => feature.id === id)?.name ?? "";

        alertDialog({
            title: strings.deleteFeatureAlertTitle,
            message: strings.deleteFeatureAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedFeature(id);
        });
    }

    async function deleteSelectedFeature(id: string) {
        try {
            await deleteFeature(axiosInstance, id);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorDeletingFeature.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.featureDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedFeatureId("");
        setSelectedFeature(initialFeatureState);
    }

    function handleCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
        setSelectedFeature(initialFeatureState);
    }

    function handleValidateForm() {
        setErrors(initialErrorState);

        const fields: ValidationField[] = [
            {
                property: "name",
                type: ValidationType.Required,
                message: strings.validationErrorFeatureNameRequired
            },
            {
                property: "id",
                type: ValidationType.Custom,
                custom: (value) => !(value.trim().length < 1 || value.trim().length > 20),
                message: strings.validationErrorFeatureIdLength
            },
            {
                property: "id",
                type: ValidationType.Pattern,
                pattern: /^[A-Za-z0-9_]*$/,
                message: strings.validationErrorFeatureIdInvalidChars
            }
        ];

        const errors = validate<Feature, ValidationErrors>(fields, selectedFeature);

        if (errors) {
            setErrors(errors);
            return false;
        }

        return true;
    }

    function handleTabChange(event: React.SyntheticEvent<Element, Event>, newValue: any) {
        setTabValue(newValue);
    }

    function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedFeature((current: Feature) => ({
            ...current,
            name: e.currentTarget.value
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedFeature((prevState: Feature) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    function handleIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedFeature((prevState: Feature) => ({
            ...prevState,
            id: event.target.value,
        }));
    }

    function handlePageSizeChange(pageSize: number) {
        setPageSize(pageSize);
    }

    const dialogTitle = selectedFeatureId === ""
        ? strings.addFeatureButtonText
        : strings.featureDialogTitleEdit.replace("{{name}}", selectedFeature?.name ?? "");

    const gridColumns: GridColDef[] = [
        { field: "id", headerName: strings.idLabel, hideable: false, flex: 1 },
        { field: "name", headerName: strings.name, flex: 1 },
        { field: "enabled", headerName: strings.enabled, type: "boolean", flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="administration_company"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditRowClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="administration_company"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
            ],
        },
    ];

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}> 
            <DataGrid
                permissionKey="administration_company"
                rows={featureList}
                columns={gridColumns}                
                //onPageSizeChange={handlePageSizeChange}
                //rowsPerPageOptions={[5, 10, 20]}
                onAddButtonClick={handleAddClick}
                addButtonText={strings.addFeatureButtonText}
                loading={isLoading}                
            />
            <DetailsDialog
                permissionKey="administration_company"
                open={detailsDialogOpen}
                title={dialogTitle}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                onValidateForm={handleValidateForm}
                adding={selectedFeatureId === ""}
                contentSize={{ width: 500, height: 325 }}
            >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="Feature Details Tab"
                    >
                        <Tab label={strings.detailsTabTitle} />
                    </Tabs>
                </Box>
                <TabPanelKeepMounted value={tabValue} index={0}>
                    <TextField
                        id="feature-id"
                        label={strings.idLabel}
                        width={300}
                        disabled={selectedFeatureId.trim().length > 1}
                        value={selectedFeature.id}
                        onChange={handleIdChange}
                        error={Boolean(errors.id)}
                        helperText={errors.id}
                    />
                    <Spacer />
                    <TextField
                        id="feature-name"
                        label={strings.name}
                        width={300}
                        value={selectedFeature.name}
                        onChange={handleNameChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />
                    <Spacer />
                    <Checkbox
                        label={strings.enabled}
                        checked={selectedFeature.enabled}
                        onChange={handleEnabledChange}
                    />
                </TabPanelKeepMounted>
            </DetailsDialog>
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    )
}

export default FeatureList;