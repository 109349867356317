import AddBoxIcon from "@mui/icons-material/AddBox";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useRef, useState } from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import getAspectRatio from "../../../utilities/GraphicsHelper";

// styles
const addButtonStyle = {
    float: "right",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: "primary.main",
    backgroundColor: "transparent",
} as const;

const imageErrorStyle = {
    color: "error.main",
} as const;

const fileUploadStyle = {
    opacity: 0,
    zIndex: -1,
    position: "absolute",
} as const;

const imagePreviewStyle = {
    border: "1px black solid",
} as const;

export interface GraphicUploaderProps {
    height: number;
    width: number;    
    imageKey: string;
    onImageUploadCompleteHandler: (image: GraphicModel) => any;
}

function GraphicUploader({
    height,
    width,    
    imageKey,
    onImageUploadCompleteHandler,
}: GraphicUploaderProps) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<GraphicModel>();
    const [ratioError, setRatioError] = useState(false);
    const [sizeError, setSizeError] = useState(false);
    const [invalidFormatError, setInvalidFormatError] = useState(false);
    const [previewImageLoaded, setPreviewImageLoaded] = useState(false);
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const strings = useLocalizedStrings();
    const MAX_SIZE_GRAPHIC_BYTES = 5242880;    

    function getAspectRatioString(imageKey: string): string {
        switch (imageKey.toLocaleLowerCase()) {
            case "banner":
                return "32:9";
            case "main":
                return "16:9";
            case "landing":
                return "124:45";
            case "logo": 
                return "16:5"
            default:
                return "1:1";
        }
    }

    function getAspectRatioError(imageKey: string): string {
        switch (imageKey.toLocaleLowerCase()) {
            case "banner":
                return strings.validationErrorInvalidAspectRatioBanner;
            case "main":
                return strings.validationErrorInvalidAspectRatioMain;
            case "landing":
                return strings.validationErrorInvalidAspectRatioLanding;
            case "logo": 
                return strings.validationErrorInvalidAspectRatioLogo;
            default:
                return "1:1";
        }
    }

    function onImageUploadOpen() {
        if (fileUploadRef !== null) {
            if (fileUploadRef.current !== null) {
                fileUploadRef.current.click();
            }
        }
    }

    function onImageUploadChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPreviewImageLoaded(false);

        const pattern = /image-*/;
        const files = event.target.files;
        if (files !== null && files.length > 0) {
            if (files[0].size > MAX_SIZE_GRAPHIC_BYTES) {
                setSizeError(true);
            } else {
                setSizeError(false);
            }

            if (!files[0].type.match(pattern)) {
                setInvalidFormatError(true);
            } else {
                setInvalidFormatError(false);
            }

            setSelectedImage({
                url: URL.createObjectURL(files[0]),
                fileData: files[0],
                imageKey: imageKey,
            });
            setDialogOpen(true);
        }
    }

    function onImageUploadComplete() {
        setDialogOpen(false);

        if (selectedImage) {
            onImageUploadCompleteHandler(selectedImage);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement, Event>): any {
        // examples of 16:9 -
        // 1920 x 1080. 3840 x 2160. 1024 x 576.
        // 1152 x 648. 1280 x 720. 1600 x 900

        const w = e.currentTarget.naturalWidth;
        const h = e.currentTarget.naturalHeight;
        const ratio = getAspectRatio(w, h);

        if (ratio !== getAspectRatioString(imageKey)) {
            setRatioError(true);
        } else {
            setRatioError(false);
        }
        setPreviewImageLoaded(true);
    }

    function closeDialog() {
        if (fileUploadRef.current) {
            fileUploadRef.current.value = "";
        }

        setDialogOpen(false);
    }

    return (
        <Box>
            <Tooltip title={strings.addButtonTitle}>
                <Avatar
                    id="grahic-add-icon-container"
                    sx={addButtonStyle}
                    onClick={onImageUploadOpen}
                >
                    <AddBoxIcon id="graphic-add-icon" />
                </Avatar>
            </Tooltip>
            <input
                id="graphic-upload-input"
                type="file"
                accept=".png, .jpg, .jpeg"
                ref={fileUploadRef}
                onChange={onImageUploadChange}
                style={fileUploadStyle}
            />
            <Dialog
                id="graphic-dialog"
                open={dialogOpen}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {strings.newGraphicTitle}
                </DialogTitle>
                <DialogContent
                    style={{
                        visibility:
                            previewImageLoaded || invalidFormatError ? "visible" : "hidden",
                    }}
                >
                    {ratioError && (
                        <Typography variant={"subtitle2"} sx={imageErrorStyle}>
                            {getAspectRatioError(imageKey)}
                        </Typography>
                    )}
                    {sizeError && (
                        <Typography variant={"subtitle2"} sx={imageErrorStyle}>
                            {strings.validationErrorMaxGraphicSize.replace("{{size}}", (MAX_SIZE_GRAPHIC_BYTES / 1048576).toString())}                            
                        </Typography>
                    )}
                    {invalidFormatError && (
                        <Typography variant={"subtitle2"} sx={imageErrorStyle}>
                            {strings.validationErrorInvalidGraphicType}
                        </Typography>
                    )}
                    <img
                        id="graphic-preview"
                        src={selectedImage?.url}
                        alt="Preview"
                        height={height}
                        width={width}
                        onLoad={onImageLoad}
                        style={imagePreviewStyle}
                    />
                </DialogContent>

                <DialogActions id="graphic-dialog-action-container">
                    <Button
                        id="graphic-cancel-selection"
                        onClick={() => closeDialog()}
                        color="primary"
                    >
                        {strings.cancelButtonTitle}
                    </Button>
                    <Button
                        id="graphic-accept-selection"
                        onClick={onImageUploadComplete}
                        color="primary"
                        disabled={ratioError || sizeError || invalidFormatError}
                    >
                        {strings.okButtonTitle}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default GraphicUploader;
