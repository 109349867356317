import AddBoxIcon from "@mui/icons-material/AddBox";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import getAspectRatio from "../../../utilities/GraphicsHelper";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    colorPickerWhite: {
        backgroundColor: '#ffffff',
        height: '25px',
        width: '25px',
        border: '1px solid #000000',
        marginLeft: '10px',
        marginRight: '10px',
        cursor: 'pointer',
    },
    colorPickerBlack: {
        backgroundColor: '#000000',
        height: '25px',
        width: '25px',
        cursor: 'pointer',
    },
    imageContainer: {
        display: 'flex',
    },
    imageBackgroundWhite: {
        backgroundColor: '#ffffff',
    },
    imageBackgroundBlack: {
        backgroundColor: '#000000',
    },
}));

// styles
const addButtonStyle = {
    float: "right",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: "primary.main",
    backgroundColor: "transparent",
} as const;

const imageErrorStyle = {
    color: "error.main",
} as const;

const fileUploadStyle = {
    opacity: 0,
    zIndex: -1,
    position: "absolute",
} as const;

const imagePreviewStyle = {
    border: "1px black solid",
} as const;

export interface SystemGraphicUploaderProps {
    height: number;
    width: number;    
    imageKey: string;
    open: boolean
    onImageUploadCompleteHandler: (image: GraphicModel) => any;
    onImageUploadCloseHandler: () => any;
}

function SystemGraphicUploader({
    height,
    width,    
    imageKey,
    open,
    onImageUploadCompleteHandler,
    onImageUploadCloseHandler,
}: SystemGraphicUploaderProps) {
    const [selectedImage, setSelectedImage] = useState<GraphicModel>();
    const [isBackgroundWhite, setIsBackgroundWhite] = useState(true);
    const [ratioError, setRatioError] = useState(false);
    const [sizeError, setSizeError] = useState(false);
    const [invalidFormatError, setInvalidFormatError] = useState(false);
    const [previewImageLoaded, setPreviewImageLoaded] = useState(false);
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const strings = useLocalizedStrings();
    const MAX_SIZE_GRAPHIC_BYTES = 5242880;    
    const theme = useTheme();
    const classes = useStyles(theme);

    const changeBackgroundWhite = (event: React.MouseEvent) => {
        setIsBackgroundWhite(true)
    }

    const changeBackgroundBlack = (event: React.MouseEvent) => {
        setIsBackgroundWhite(false)
    }

    useEffect(() => {
        if (open && !previewImageLoaded) {
            onImageUploadOpen()
        }
    });

    function onImageUploadOpen() {
        if (fileUploadRef !== null) {
            if (fileUploadRef.current !== null) {
                fileUploadRef.current.click();
            }
        }
    }

    function onImageUploadChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPreviewImageLoaded(false);

        const pattern = /image-*/;
        const files = event.target.files;
        if (files !== null && files.length > 0) {
            if (files[0].size > MAX_SIZE_GRAPHIC_BYTES) {
                setSizeError(true);
            } else {
                setSizeError(false);
            }

            if (!files[0].type.match(pattern)) {
                setInvalidFormatError(true);
            } else {
                setInvalidFormatError(false);
            }

            setSelectedImage({
                url: URL.createObjectURL(files[0]),
                fileData: files[0],
                imageKey: imageKey,
            });
        }
    }

    function onImageUploadComplete() {
        if (selectedImage) {
            onImageUploadCompleteHandler(selectedImage);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement, Event>): any {
        setPreviewImageLoaded(true);
    }

    function closeDialog() {
        if (fileUploadRef.current) {
            fileUploadRef.current.value = "";
        }

        onImageUploadCloseHandler();
    }

    return (
        <Box>
            <input
                id="graphic-upload-input"
                type="file"
                accept=".png, .jpg, .jpeg"
                ref={fileUploadRef}
                onChange={onImageUploadChange}
                style={fileUploadStyle}
            />
            <Dialog
                id="graphic-dialog"
                open={open}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {strings.newGraphicTitle}
                </DialogTitle>
                <DialogContent>
                    {sizeError && (
                        <Typography variant={"subtitle2"} sx={imageErrorStyle}>
                            {strings.validationErrorMaxGraphicSize.replace("{{size}}", (MAX_SIZE_GRAPHIC_BYTES / 1048576).toString())}
                        </Typography>
                    )}
                    {invalidFormatError && (
                        <Typography variant={"subtitle2"} sx={imageErrorStyle}>
                            {strings.validationErrorInvalidGraphicType}
                        </Typography>
                    )}

                <div className={classes.imageContainer}>
                    <img
                        id="graphic-preview"
                        className={isBackgroundWhite ? classes.imageBackgroundWhite : classes.imageBackgroundBlack}
                        src={selectedImage?.url}
                        alt="Preview"
                        height={height}
                        width={width}
                        onLoad={onImageLoad}
                        style={imagePreviewStyle}
                        onDoubleClick={(e) => {onImageUploadOpen()}}
                    />
                    <div className={classes.colorPickerWhite} onClick={changeBackgroundWhite}></div>
                    <div className={classes.colorPickerBlack} onClick={changeBackgroundBlack}></div>
                </div>
                    
                </DialogContent>

                <DialogActions id="graphic-dialog-action-container">
                    <Button
                        id="graphic-cancel-selection"
                        onClick={() => closeDialog()}
                        color="primary"
                    >
                        {strings.cancelButtonTitle}
                    </Button>
                    <Button
                        id="graphic-accept-selection"
                        onClick={onImageUploadComplete}
                        color="primary"
                        disabled={ratioError || sizeError || invalidFormatError}
                    >
                        {strings.okButtonTitle}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default SystemGraphicUploader;
