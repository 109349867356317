import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemText from '@mui/material/ListItemText';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import dayjs, { Dayjs } from 'dayjs';
import React from "react";
import { deleteGraphics } from "../../../api/graphicsApi";
import { deleteItem, getItems, saveDisplayOrder as saveItemDisplayOrder, toggleItemSoldOut } from "../../../api/roomservice/itemApi";
import { addItem, createMenu, createSection, deleteMenu, deleteSection, getMenus, removeItem, saveSectionDisplayOrder, updateMenu, updateSection } from "../../../api/roomservice/menuApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { Item as ItemModel, initialItemState } from "../../../models/modules/roomservice/Item";
import { Menu as MenuModel, Section, initialMenuState, initialSectionState } from "../../../models/modules/roomservice/Menu";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import DisplayOrderGrid, { DisplayOrderGridRefObject } from '../../common/datatable/DisplayOrderGrid';
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import RoomClasses from "../../common/details/RoomClasses";
import TimePicker from "../../common/details/TimePicker";
import ValidateTab from "../../common/details/ValidateTab";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import DataGridSoldOutButton from './DataGridSoldOutButton';
import Item from './Item';

// Validation Errors
interface MenuValidationErrors {
    name: string;
    timeRange: string;    
    roomClasses: string;
}

interface SectionValidationErrors {
    name: string;
}

interface ItemValidationErrors {
    name: string;
}

const initialMenuErrorState: MenuValidationErrors = {
    name: "",
    timeRange: "",
    roomClasses: ""
}

const initialSectionErrorState: SectionValidationErrors = {
    name: ""
}

const initialItemErrorState: ItemValidationErrors = {
    name: ""
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function MenuList() {
    const theme = useTheme(); 
    const strings = useLocalizedStrings(); 

    const [loading, setLoading] = React.useState(true);
    const [gridRefresh, setGridRefresh] = React.useState(true);
    const [menus, setMenus] = React.useState<MenuModel[]>([]);  
    const [allItems, setAllItems] = React.useState<ItemModel[]>([]);

    // Menu
    const [menuTabValue, setMenuTabValue] = React.useState<number>(0);
    const [selectedMenu, setSelectedMenu] = React.useState<MenuModel>(initialMenuState);
    const [menuDialogOpen, setMenuDialogOpen] = React.useState(false);
    const [selectedMenuId, setSelectedMenuId] = React.useState("");
    const [menuErrors, setMenuErrors] = React.useState<MenuValidationErrors>(initialMenuErrorState);   

    // Section
    const [sectionDialogOpen, setSectionDialogOpen] = React.useState(false);
    const [selectedSectionId, setSelectedSectionId] = React.useState("");
    const [selectedSection, setSelectedSection] = React.useState<Section>(initialSectionState);
    const [sectionErrors, setSectionErrors] = React.useState<SectionValidationErrors>(initialSectionErrorState);
    const sectionGridRef = React.useRef<DisplayOrderGridRefObject>(null);

    // Item
    const [selectedItemId, setSelectedItemId] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState<ItemModel>(initialItemState);
    const [addItemMenuAnchor, setAddItemMenuAnchor] = React.useState<null | HTMLElement>(null);
    const addItemMenuOpen = Boolean(addItemMenuAnchor);
    const [deleteItemMenuAnchorEl, setDeleteItemMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const deleteItemMenuOpen = Boolean(deleteItemMenuAnchorEl);   
    const [itemDialogOpen, setItemDialogOpen] = React.useState(false);    
    const itemGridRef = React.useRef<DisplayOrderGridRefObject>(null);
        
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const {user} = useUser();    

    type RowType = 'Menu' | 'Section' | 'Item';

    React.useEffect(() => {
        const getInitialData = async() => {                        
            try {          
                clearRows("Menu");

                const menuResponse = await getMenus(axiosInstance, user?.currentProperty?.code ?? "");
                setMenus(menuResponse);
                
                // Reselect rows
                var menu = menuResponse.find(m => m.id === selectedMenuId);
                if (menu) {
                    setSelectedMenu(menu);                     
                    var section = menu.menuSections.find(s => s.id === selectedSectionId);
                    if (section) {
                        setSelectedSection(section);                    
                    } 
                }

                const itemResponse = await getItems(axiosInstance, user?.currentProperty?.code ?? "");
                itemResponse.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
                setAllItems(itemResponse);
            }
            catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.roomServiceErrorRetreivingMenus.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

                return;
            }
            finally {
                setLoading(false);
            }
        }

        getInitialData();
    }, [user.currentProperty?.code, gridRefresh, strings.roomServiceErrorRetreivingMenus])

    // clear children at each level when parent changes
    React.useEffect(() => {
        clearRows('Section');
    }, [selectedMenuId])

    React.useEffect(() => {
        clearRows('Item')
    }, [selectedSectionId])      

    function selectRow(id: string, rowType: RowType, openDialogOnSelect: boolean = false) { 
        switch (rowType) {            
            case 'Menu': {
                setSelectedMenuId(id);

                const menu = menus?.find(m => m.id === id);
                if (menu) {
                    setSelectedMenu(menu);
                    return menu;                                   
                }

                break;
            }
            case 'Section': {
                setSelectedSectionId(id); 

                const section = selectedMenu?.menuSections.find(s => s.id === id);
                if (section) {
                    setSelectedSection(section);
                    return section;
                }

                break;
            }
            case 'Item': {
                setSelectedItemId(id);
               
                const item = selectedSection?.items.find(i => i.id === id);

                if (item) {
                    setSelectedItem(item);
                    return item;
                }

                break;
            }
            default: {
                return null;
            }
        }

        return null;
    }

    function clearRows(type: RowType) {
        switch (type) {            
            case 'Menu': {
                setSelectedMenuId("");
                setSelectedMenu(initialMenuState);

                setSelectedSectionId("");
                setSelectedSection(initialSectionState);

                setSelectedItemId("");
                setSelectedItem(initialItemState);                

                break;
            }
            case 'Section': {
                setSelectedSectionId("");
                setSelectedSection(initialSectionState);

                setSelectedItemId("");
                setSelectedItem(initialItemState);
               
                break;
            }
            case 'Item': {                
                setSelectedItemId("");
                setSelectedItem(initialItemState);
               
                break;
            }
            default: {

            }
        }
    }        

    // Menu    
    function handleMenuCanPerformAction() {
        return !sectionGridRef.current?.isDirty() && !itemGridRef.current?.isDirty();
    }

    function handleMenuSelectionChange (menuSelectionModel: GridRowSelectionModel) {
        if (menuSelectionModel.length > 0) {
            selectRow(menuSelectionModel[0].toString(), 'Menu');
        }
    };    

    function handleAddMenuButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        clearRows('Menu');
        setMenuDialogOpen(true);        
    }

    function handleEditMenuClick(id: string) {
        if (!handleMenuCanPerformAction()) {
            return;
        }

        if (selectRow(id, 'Menu', true)) {
            setMenuDialogOpen(true); 
        }
    }

    function handleMenuDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (!handleMenuCanPerformAction()) {
            return;
        }

        var menu = selectRow(id, 'Menu');
                
        alertDialog({
            title: strings.roomServiceDeleteMenuAlertTitle,
            message: strings.roomServiceDeleteMenuAlertMessage.replace("{{name}}", menu?.name?.en ?? ""),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedMenu(id); 
        });     
    }

    async function deleteSelectedMenu(id: string) {        
        try {            
            await deleteMenu(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.roomServiceErrorDeletingMenu.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.roomServiceMenuDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        clearRows("Menu");
    }    

    function handleMenuTabChange(event: React.SyntheticEvent, newValue: number) {        
        setMenuTabValue(newValue);        
    }

    function handleMenuNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedMenu((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }    

    function handleStartTimeChange(newValue: Dayjs | null) {       
        if (newValue !== null) {
            setSelectedMenu((prevState) => ({
                ...prevState,
                startTime: newValue.toDate()
            }));
        }
    }

    function handleEndTimeChange(newValue: Dayjs | null) {
        if (newValue !== null) {
            setSelectedMenu((prevState) => ({
                ...prevState,
                endTime: newValue.toDate()
            }));
        }
    }

    function handleRoomClassesChange(values: { id: string; name: string }[]) {
        setSelectedMenu((prevState) => ({
            ...prevState,
            roomClasses: values
        }));
    }

    function handleMenuCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setMenuErrors(initialMenuErrorState);        
        setMenuDialogOpen(false);
        setMenuTabValue(0);
    }

    function handleValidateMenu() {
        let errors = validate<MenuModel, MenuValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationRoomServiceMenuName }            
        ], selectedMenu);        

        if ((selectedMenu.startTime && !selectedMenu.endTime) || (!selectedMenu.startTime && selectedMenu.endTime)) {
            if (!errors) {
                errors = { 
                    name: "", 
                    timeRange: strings.validationRoomServiceInvalidTimeRange,
                    roomClasses: ""
                }
            }
            else {
                errors.timeRange = strings.validationRoomServiceInvalidTimeRange;
            }
        }

        if (errors) {
            setMenuErrors(errors);
            return false;
        }
        return true;    
    }

    async function handleMenuSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setMenuErrors(initialMenuErrorState);
       
        const isUpdate = selectedMenuId.length > 0; 
        try {
            if (isUpdate) {
                await updateMenu(axiosInstance, user?.currentProperty?.code ?? "", selectedMenu);
            }
            else {                
                await createMenu(axiosInstance, user?.currentProperty?.code ?? "", selectedMenu);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.roomServiceErrorAddingSection
                    : strings.roomServiceErrorUpdatingSection
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }

        setNotify({
            isOpen: true,
            message: strings.roomServiceMenuSavedSuccessfully,
            msgType: "success",
        });

        clearRows("Menu");
        setMenuDialogOpen(false);
        setGridRefresh(!gridRefresh);  
        setMenuTabValue(0);

        return true;
    }    

    // SECTION
    function handleSectionCanPerformAction() {
        return !itemGridRef.current?.isDirty();
    }

    function handleSectionSelectionChange (sectionSelectionModel: GridRowSelectionModel) {
        if (sectionSelectionModel.length > 0) {
            selectRow(sectionSelectionModel[0].toString(), 'Section');
        }
    };  

    /*function handleSectionSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const sections = [...selectedMenu.menuSections];
        sections.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateSectionOrder(sections);        
    }

    function updateSectionOrder(sectionList: Section[]) {*/
    function handleSectionSaveDisplayOrder(idList: any[]) {
        //const idList = sectionList.map(s => s.id);
        saveSectionDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", selectedMenuId, idList)
            .then(() => setGridRefresh(!gridRefresh))
            .catch((error: unknown) => {
                
                    
                    
                setNotify({
                    isOpen: true,
                    message: strings.roomServiceErrorUpdatingSection.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            })
    }    

    function handleAddSectionButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        clearRows('Section');
        setSectionDialogOpen(true); 
    }

    function handleEditSectionClick(id: string) {
        if (sectionGridRef.current?.isDirty() || !handleSectionCanPerformAction()) {
            return;
        }

        if (selectRow(id, 'Section', true)) {
            setSectionDialogOpen(true); 
        }
    }

    function handleSectionDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (sectionGridRef.current?.isDirty() || !handleSectionCanPerformAction()) {
            return;
        }

        var section = selectRow(id, 'Section');
                
        alertDialog({
            title: strings.roomServiceDeleteSectionAlertTitle,
            message: strings.roomServiceDeleteSectionAlertMessage.replace("{{name}}", section?.name?.en ?? ""),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedSection(id); 
        });
    }

    async function deleteSelectedSection(id: string) {
       try {            
            await deleteSection(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.roomServiceErrorDeletingSection.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.roomServiceSectionDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        clearRows("Section");
    }

    function handleSectionNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedSection((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }

    function handleSectionCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSectionErrors (initialSectionErrorState)
        setSectionDialogOpen(false);
    }    

    function handleValidateSection() {
        let errors = validate<Section, SectionValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationRoomServiceSectionName }                 
        ], selectedSection);
        if (errors) {
            setSectionErrors(errors);
            return false;
        }
        return true;    
    }
    
    async function handleSectionSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSectionErrors(initialSectionErrorState);
       
        const isUpdate = selectedSectionId.length > 0; 
        try {
            if (isUpdate) {
                await updateSection(axiosInstance, user?.currentProperty?.code ?? "", selectedSection);
            }
            else {                
                await createSection(axiosInstance, user?.currentProperty?.code ?? "", selectedSection, selectedMenuId);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.roomServiceErrorAddingSection
                    : strings.roomServiceErrorUpdatingSection
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }
        
        setNotify({
            isOpen: true,
            message: strings.roomServiceSectionSavedSuccessfully,
            msgType: "success",
        });

        clearRows("Section");
        setSectionDialogOpen(false);
        setGridRefresh(!gridRefresh);        

        return true;
    }

    // ITEM
    function handleItemSelectionChange(itemSelectionModel: GridRowSelectionModel) {
        if (itemSelectionModel.length > 0) {
            selectRow(itemSelectionModel[0].toString(), 'Item');
        }
    }
    
    /*function handleItemSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const items = [...selectedSection.items];
        items.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateItemOrder(items);        
    }

    function updateItemOrder(itemList: ItemModel[]) {*/
    function handleItemSaveDisplayOrder(idList: any[]) {
        //const idList = itemList.map(i => i.id);
        saveItemDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", selectedSectionId, idList)
            .then(() => setGridRefresh(!gridRefresh))
            .catch((error: unknown) => {
                
                    
                    
                setNotify({
                    isOpen: true,
                    message: strings.roomServiceErrorUpdatingItem.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            })
    }    

    function handleAddItemButtonClick(event: React.MouseEvent<HTMLButtonElement>) {       
        if (getFilteredItems().length > 0) {
            setAddItemMenuAnchor(event.currentTarget);
        }
        else {
            handleAddNewItemMenuClick();
        }   
    }

    function handleAddItemMenuClose() {
        setAddItemMenuAnchor(null);
    }

    function handleAddNewItemMenuClick() {
        clearRows('Item');
        setAddItemMenuAnchor(null);
        setItemDialogOpen(true);
    }    

    async function handleAddExistingItemMenuClick(id: string) {
        setAddItemMenuAnchor(null);       

        try {
            await addItem(axiosInstance, user?.currentProperty?.code ?? "", selectedSectionId, id);
        }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorUpdatingItem.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            setSelectedItem(initialItemState);
            return false;
        }

        setGridRefresh(!gridRefresh);
        
        return true;
    }

    async function handleEditItemClick(id: string) {
        if (itemGridRef.current?.isDirty()) {
            return;
        }

        if (selectRow(id, "Item")) {
            setItemDialogOpen(true);
        }
    }

    function handleDeleteItemMenuClose() {
        setDeleteItemMenuAnchorEl(null);
    }

    function handleItemDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (itemGridRef.current?.isDirty()) {
            return;
        }

        selectRow(id, 'Item');
        setDeleteItemMenuAnchorEl(event.currentTarget);
    }

    async function deleteSelectedItem(id: string) {
        const images: GraphicModel[] = [{ imageKey: "main", url: "", fileData: null }];

        try {
            await deleteGraphics(axiosInstance, "roomServiceItem", id, images, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);            
            await deleteItem(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.roomServiceErrorDeletingItem.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.roomServiceItemDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        clearRows("Item");
    }

    async function handleRemoveItem() {
        setDeleteItemMenuAnchorEl(null);       

        try {            
            await removeItem(axiosInstance, user?.currentProperty?.code ?? "", selectedSectionId, selectedItemId);
        }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.roomServiceErrorUpdatingItem.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            setSelectedItem(initialItemState);
            return false;
        }

        setGridRefresh(!gridRefresh);
        
        return true;
    }

    function handleDeleteItem() {        
        setDeleteItemMenuAnchorEl(null);

        alertDialog({
            title: strings.roomServiceDeleteItemAlertTitle,
            message: strings.roomServiceDeleteItemAlertMessage.replace("{{name}}", selectedItem.name?.en ?? ""),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedItem(selectedItemId); 
        });
    }     

    function handleItemCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setItemDialogOpen(false);
        clearRows("Item");        
    } 

    function handleItemSaved(event: React.MouseEvent<HTMLButtonElement>) {
        setItemDialogOpen(false);
        clearRows("Item");
        setGridRefresh(!gridRefresh);
    }       
        
    function handleItemNotification(options: NotificationOptions) {
        setNotify(options);
    }

    function getFormattedTime(value: Date | null) {
        if (value) {
            return (value as Date).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'});
        }
        else {
            return "";
        }
    }

    const menuColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, valueGetter: (value, row) => row.name.en, flex: 2 },        
        { field: "startTime", headerName: strings.start, type: "dateTime", valueGetter: (value, row) => row.startTime, valueFormatter: (value?: Date) => value ? getFormattedTime(value) : "", flex: 1 },
        { field: "endTime", headerName: strings.end, type: "dateTime", valueGetter: (value, row) => row.endTime, valueFormatter: (value?: Date) => value ? getFormattedTime(value) : "", flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditMenuClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleMenuDeleteClick(params.id.toString(), event)}
                />
            ],
        }
    ]

    const sectionColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, valueGetter: (value, row) => row.name.en, flex: 2 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditSectionClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleSectionDeleteClick(params.id.toString(), event)}
                />
            ],
        }
    ]

    const itemColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, valueGetter: (value, row) => row.name.en, flex: 2 },
        { field: "price", headerName: strings.price, type: "number", valueGetter: (value, row) => row.pricePerUnit, valueFormatter: (value?: number) => value && value !== 0 ? value.toLocaleString(undefined, { style: "currency", currency: "USD" }) : "", flex: 1 }, 
        //{ field: "soldOut", headerName: strings.available, type: "boolean", valueGetter: (value, row) => !row.soldOut, flex: 1 },
        { field: "previewOnly", headerName: strings.previewOnly, type: "boolean", valueGetter: (value, row) => row.previewOnly, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditItemClick(params.id.toString())}
                />,
                <DataGridSoldOutButton
                    rowId={params.id.toString()}                       
                    soldOut={selectedMenu?.menuSections?.find(s => s.id === selectedSectionId)?.items.find(i => i.id === params.id)?.soldOut ?? true}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleSoldOutClick(params.id.toString(), event)}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleItemDeleteClick(params.id.toString(), event)}
                />
            ],
        }
    ]

    async function handleSoldOutClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (itemGridRef.current?.isDirty()) {
            return;
        }

        try {            
            await toggleItemSoldOut(axiosInstance, user?.currentProperty?.code ?? "", id);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.roomServiceErrorUpdatingItem.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
            
            return false;
        }

        setGridRefresh(!gridRefresh);
    } 

    function getSectionRows() {
        return selectedMenu?.menuSections ?? [];
    }

    function getItemRows() {
        return selectedSection?.items ?? [];
    }

    function getFilteredItems() {
        const selectedIds = getItemRows().map(i => i.id);
        return  allItems.filter(i => !selectedIds.includes(i.id));
    }

    const menuDialogTitle = selectedMenuId === "" ? strings.roomServiceAddMenuTitle : strings.roomServiceEditMenuTitle.replace("{{name}}", selectedMenu?.name?.en ?? ""); // TODO: default language
    const sectionDialogTitle = selectedSectionId === "" ? strings.roomServiceAddSectionTitle : strings.roomServiceEditSectionTitle.replace("{{name}}", selectedSection?.name?.en ?? ""); // TODO: default language

    const DateRange = () => {
        return (
            <Box sx={{ height: 78.91 }}>
                <Stack direction="row" sx={{ height: 56 }}>                    
                    <TimePicker
                        label={strings.startTime}
                        value={dayjs(selectedMenu.startTime) ?? null}
                        onChange={handleStartTimeChange}                    
                    />  
                    <Spacer x={2} />
                    <TimePicker
                        label={strings.endTime}
                        value={dayjs(selectedMenu.endTime) ?? null}
                        onChange={handleEndTimeChange}
                    />  
                </Stack>
                <Box sx={{ height: 19.91 }}>
                    { menuErrors.timeRange &&
                        <Typography sx={{ marginLeft: "14px", marginTop: "3px" }} variant="caption" color="error">{menuErrors.timeRange}</Typography>
                    }
                </Box>                
            </Box>
        )
    }

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 155px)" }}>                  
            <Grid container spacing={2} sx={{ height: "100%" }}>                
                <Grid item xs={4} sx={{ height: "100%" }}>
                    {/*Menu*/}
                    <DataGrid 
                        columns={menuColumns} 
                        rows={menus}
                        canPerformAction={handleMenuCanPerformAction}
                        onRowSelectionModelChange={handleMenuSelectionChange} 
                        rowSelectionModel={selectedMenuId}                        
                        permissionKey="content_roomservice" 
                        showAddButton={true} 
                        addButtonText={strings.roomServiceAddMenuButton}
                        onAddButtonClick={handleAddMenuButtonClick}     
                        loading={loading}
                    />

                    {/*Menu Dialog*/}
                    <DetailsDialog
                        permissionKey="content_roomservice"
                        open={menuDialogOpen}
                        adding={selectedMenuId === ""}
                        title={menuDialogTitle}
                        onValidateForm={handleValidateMenu}
                        onCancelClick={handleMenuCancelClick}
                        onSaveClick={handleMenuSaveClick}
                        contentSize={{ width: 879.5, height: 512.56 }}
                    >
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={menuTabValue}
                                onChange={handleMenuTabChange}
                                aria-label="basic tabs example"
                            >
                                <ValidateTab label={strings.details} errors={Boolean(menuErrors.name) || Boolean(menuErrors.timeRange)} />
                                <Tab label={strings.roomClasses} />                            
                            </Tabs>
                        </Box>
                        <TabPanelKeepMounted value={menuTabValue} index={0}>
                            <LanguageForm>
                                <LanguageSelect />
                                <Spacer />
                                <LanguageTextField
                                    id="menu-name"
                                    label={strings.name}
                                    values={selectedMenu.name ?? {}}
                                    width={410}
                                    onChange={handleMenuNameChange}
                                    error={Boolean(menuErrors.name)}
                                    helperText={menuErrors.name}
                                />
                                <Spacer />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateRange />  
                                </LocalizationProvider>
                            </LanguageForm>
                        </TabPanelKeepMounted> 
                        <TabPanelKeepMounted value={menuTabValue} index={1}>
                            <RoomClasses
                                assignedClasses={selectedMenu.roomClasses}
                                onChangeHandler={handleRoomClassesChange}                            
                                height={453.56}
                                error={Boolean(menuErrors.roomClasses)}
                                helperText={menuErrors.roomClasses}
                            />
                        </TabPanelKeepMounted> 
                    </DetailsDialog>                    
                </Grid>
                <Grid item xs={3} sx={{ height: "100%" }}>
                    {/*Section*/}                        
                    <DisplayOrderGrid
                        permissionKey="content_roomservice"
                        rows={getSectionRows()}                            
                        columns={sectionColumns}
                        canPerformAction={handleSectionCanPerformAction}
                        rowSelectionModel={selectedSectionId}
                        onRowSelectionModelChange={handleSectionSelectionChange}
                        addButtonDisabled={selectedMenuId === ""}
                        onAddButtonClick={handleAddSectionButtonClick}
                        addButtonText={strings.roomServiceAddSectionButton}
                        onSaveDisplayOrder={handleSectionSaveDisplayOrder}
                        ref={sectionGridRef}
                    />

                    {/*Section Dialog*/}
                    <DetailsDialog
                        permissionKey="content_roomservice"
                        open={sectionDialogOpen}
                        adding={selectedSectionId === ""}
                        title={sectionDialogTitle}
                        onValidateForm={handleValidateSection} 
                        onSaveClick={handleSectionSaveClick}
                        contentSize={{ width: 442, height: 189.81 }}
                        onCancelClick={handleSectionCancelClick}
                    >
                        <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                            <LanguageForm>
                                <LanguageSelect />
                                <Spacer />
                                <LanguageTextField
                                    id="section-name"
                                    values={selectedSection.name ?? {}}
                                    label={strings.name}
                                    width={410}
                                    onChange={handleSectionNameChange}
                                    error={Boolean(sectionErrors.name)}
                                    helperText={sectionErrors.name}
                                />                                                                    
                            </LanguageForm>
                        </Box>
                    </DetailsDialog>
                </Grid>
                <Grid item xs={5} sx={{ height: "100%" }}>
                    {/*Item Add Button Menu*/}
                    <Menu
                        id="addItemMenu"
                        MenuListProps={{
                            'aria-labelledby': 'itemAdd',
                        }}
                        anchorEl={addItemMenuAnchor}
                        open={addItemMenuOpen}
                        onClose={handleAddItemMenuClose}
                    >                
                        <MenuItem onClick={handleAddNewItemMenuClick}>{strings.roomServiceAddItemButton}</MenuItem>                        
                        {getFilteredItems().map((item, index) => (
                            <>
                                {index === 0 && <Divider />}
                                <MenuItem key={item.id} value={item.id} onClick={() => handleAddExistingItemMenuClick(item.id)}>
                                    <ListItemText>
                                        {item.name?.en ?? ""}
                                    </ListItemText>
                                </MenuItem>
                            </>
                        ))}    
                    </Menu>

                    {/*Item Delete Button Menu*/}
                    <Menu
                        id="deleteItemMenu"
                        MenuListProps={{
                            'aria-labelledby': 'buttonDeleteItem',
                        }}
                        anchorEl={deleteItemMenuAnchorEl}
                        open={deleteItemMenuOpen}
                        onClose={handleDeleteItemMenuClose}
                    >
                        <MenuItem onClick={handleRemoveItem}>{strings.removeButtonTitle}</MenuItem>
                        <MenuItem onClick={handleDeleteItem}>{strings.deleteButtonTitle}</MenuItem>
                    </Menu>

                    {/*Item*/}
                    <DisplayOrderGrid
                        permissionKey="content_roomservice"
                        rows={getItemRows()}                            
                        columns={itemColumns}
                        rowSelectionModel={selectedItemId}
                        onRowSelectionModelChange={handleItemSelectionChange}
                        addButtonDisabled={selectedSectionId === ""}
                        onAddButtonClick={handleAddItemButtonClick}
                        addButtonText={strings.roomServiceAddItemButton}
                        onSaveDisplayOrder={handleItemSaveDisplayOrder}
                        ref={itemGridRef}
                    />                  

                    {/*Item Dialog*/}
                    <Item
                        open={itemDialogOpen}
                        itemId={selectedItemId}                            
                        sectionId={selectedSectionId}
                        adding={selectedItemId === ""}
                        onCancelClick={handleItemCancelClick}
                        onSaved={handleItemSaved}
                        onNotification={handleItemNotification}
                    />                    
                </Grid>
            </Grid> 
                
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    )    
}

export default MenuList;