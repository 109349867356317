import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
    DataGrid, 
    DataGridProps, 
    getDefaultGridFilterModel, 
    gridClasses, 
    GridRowSelectionModel, 
    GridFilterModel, 
    GridFooter, 
    GridFooterContainer,
    GridLocaleText, 
    GridRowIdGetter, 
    GridSortModel, 
    GridCallbackDetails
} from "@mui/x-data-grid";
import { enUS, esES } from '@mui/x-data-grid/locales';
import React, { useEffect, useState } from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import Spacer from "../Spacer";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";


/*
  mui DataGrid style overrides:
  1. override the filter icon's visibility and width so it remains visble when
      a filter is applied (default behavior is to hide it when not hovering)

  2. remove outlines on column headers and cells when focused.
*/
const StyledDataGrid = styled(DataGrid)(({ theme, ...props }) => {
  return {
    [`& .${gridClasses.columnHeaderTitleContainer} .${gridClasses.iconButtonContainer}`]:
    {
      visibility: "visible",
      width: "auto",
    },
    [`&.MuiDataGrid-root .${gridClasses.columnHeader}:focus, 
      &.MuiDataGrid-root .${gridClasses.columnHeader}:focus-within, 
      &.MuiDataGrid-root .${gridClasses.cell}:focus, 
      &.MuiDataGrid-root .${gridClasses.cell}:focus-within `
    ]:
    {
      outline: "none"
    }
  };
});

// Exclude certain props that wrapper controls
export interface DataGridWrapperProps
    extends Omit<
    DataGridProps,   
    | "filterModel"
    | "onFilterModelChange"
    | "sortModel"
    | "onSortModelChange"
    | "localeText"
    > {
    permissionKey?: string;
    showAddButton?: boolean;
    showAddButtonEditOnly?: boolean;
    addButtonDisabled?: boolean;
    onAddButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;    
    addButtonText?: string;
    addButtonSize?: "small" | "medium" | "large"
    height?: number;
    getRowId?: GridRowIdGetter; 
    footerLeft?: JSX.Element | undefined;   
    canPerformAction?: () => boolean;
    onFilterQueryChange?: (query: string) => void;
    onSortQueryChange?: (query: string) => void;
}

function DataGridWrapper({
    permissionKey,
    showAddButton = true,
    showAddButtonEditOnly = false,
    addButtonDisabled = false,
    onAddButtonClick,    
    addButtonText,
    addButtonSize = "large",      
    height,
    rows,
    getRowId,
    footerLeft,   
    onRowSelectionModelChange,
    canPerformAction,
    onFilterQueryChange,
    onSortQueryChange,
    ...rest
}: DataGridWrapperProps) {

    const [filterQuery, setFilterQuery] = useState("");
    const [sortQuery, setSortQuery] = useState("");
    const [innerFilterModel, setInnerFilterModel] = useState<GridFilterModel>(getDefaultGridFilterModel());
    const [innerSortModel, setInnerSortModel] = useState<GridSortModel>([]);
    const [gridLocaleText, setGridLocaleText] = useState<Partial<GridLocaleText | undefined>>()    
    const strings = useLocalizedStrings();
    const currentLanguageKey = strings.getLanguage();
    const { checkAccess } = useUser();
    const alertDialog = useAlertDialog(); // TODO: React router v6 does not support blocking yet. Once they add it we will need to show an alert if they navigate while inDisplayOrderMode=true

    useEffect(() => {
        // Use mui's translations for the grid (imported above) based on the 
        // browser language. Override any missing or incorrect ones with ours.

        if (currentLanguageKey.trim() === "es") {
            setGridLocaleText({
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                booleanCellFalseLabel: strings.gridBooleanCellFalseLabel,
                booleanCellTrueLabel: strings.gridBooleanCellTrueLabel
            });
        } else {
            setGridLocaleText({
                ...enUS.components.MuiDataGrid.defaultProps.localeText,
                booleanCellFalseLabel: strings.gridBooleanCellFalseLabel,
                booleanCellTrueLabel: strings.gridBooleanCellTrueLabel
            });
        }

    }, [currentLanguageKey])

    useEffect(() => {
        // reset any filters and sorting when data changes
        setInnerFilterModel(getDefaultGridFilterModel());
        setInnerSortModel([]);
    }, [rows])

    function handleSelectionModelChange(model: GridRowSelectionModel, details: GridCallbackDetails<any>) {
        if (!canPerformAction || canPerformAction?.()) {
            onRowSelectionModelChange?.(model, details);
        }
    }

    function handleAddButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!canPerformAction || canPerformAction?.()) {            
            onAddButtonClick?.(event);
        }
    }

    const handleFilterModelChange = React.useCallback((newModel: GridFilterModel, details: GridCallbackDetails<'filter'>) => {                   
        if (onFilterQueryChange) {
            onFilterQueryChange(getFilterQuery(newModel));
        }
        else {
            setInnerFilterModel((currentModel: GridFilterModel) => ({
                ...currentModel,
                ...newModel
            }));  
        }
    }, []);

    const handleSortModelChange = React.useCallback((newModel: GridSortModel) => {           
        if (onSortQueryChange) {
            onSortQueryChange(getSortQuery(newModel));
        }
        else {
            setInnerSortModel(newModel); 
        }
    }, []);

    function getFilterQuery(filterModel: GridFilterModel) {
        var queryParams: string[] = [];
        filterModel.items.forEach(i => {
            if (!i.value) {
                return;
            }

            switch (i.operator) {
                case "contains":
                    queryParams.push(`${i.field}@=*${i.value}`);
                    break;
                case "doesNotContain":
                    queryParams.push(`${i.field}!@=*${i.value}`);
                    break;
                case "equals":                
                case "is":
                    queryParams.push(`${i.field}==*${i.value}`);
                    break;
                case "=":
                    queryParams.push(`${i.field}==${i.value}`);
                    break;
                case "doesNotEqual":                
                case "not":
                    queryParams.push(`${i.field}!=*${i.value}`);
                    break;
                case "!=":
                    queryParams.push(`${i.field}!=${i.value}`);
                    break;
                case ">":
                case "after":
                    queryParams.push(`${i.field}>${i.value}`);
                    break;
                case ">=":
                case "onOrAfter":
                    queryParams.push(`${i.field}>=${i.value}`);
                    break;
                case "<":
                case "before":
                    queryParams.push(`${i.field}<${i.value}`);
                    break;
                case "<=":
                case "onOrBefore":
                    queryParams.push(`${i.field}<=${i.value}`);
                    break;
                case "startsWith":
                    queryParams.push(`${i.field}_=*${i.value}`);
                    break;
                case "endsWith":
                    queryParams.push(`${i.field}_-=*${i.value}`);
                    break;
                //case "isEmpty":
                //    queryParams.push(`${i.field}[em]=${i.value}`);
                //    break;
                //case "isNotEmpty":
                //    queryParams.push(`${i.field}[nem]=${i.value}`);
                //    break;
                //case "isAnyOf":
                //    queryParams.push(`${i.field}[in]=${i.value}`);
                //    break;
            }
        });

        return ("filters=" + queryParams?.join(",")) ?? "";
    }

    //function getFilterQuery(filterModel: GridFilterModel) {
    //    var queryParams: string[] = [];
    //    filterModel.items.forEach(i => {
    //        if (!i.value) {
    //            return;
    //        }

    //        switch (i.operator) {
    //            case "contains":
    //                queryParams.push(`${i.field}[co]=${i.value}`);
    //                break;
    //            case "doesNotContain":
    //                queryParams.push(`${i.field}[nco]=${i.value}`);
    //                break;
    //            case "equals":
    //            case "=":
    //            case "is":
    //                queryParams.push(`${i.field}[eq]=${i.value}`);
    //                break;
    //            case "doesNotEqual":
    //            case "!=":
    //            case "not":
    //                queryParams.push(`${i.field}[neq]=${i.value}`);
    //                break;
    //            case ">":
    //            case "after":
    //                queryParams.push(`${i.field}[gt]=${i.value}`);
    //                break;
    //            case ">=":
    //            case "onOrAfter":
    //                queryParams.push(`${i.field}[gte]=${i.value}`);
    //                break;
    //            case "<":
    //            case "before":
    //                queryParams.push(`${i.field}[lt]=${i.value}`);
    //                break;
    //            case "<=":
    //            case "onOrBefore":
    //                queryParams.push(`${i.field}[lte]=${i.value}`);
    //                break;
    //            case "startsWith":
    //                queryParams.push(`${i.field}[sw]=${i.value}`);
    //                break;
    //            case "endsWith":
    //                queryParams.push(`${i.field}[ew]=${i.value}`);
    //                break;
    //            case "isEmpty":
    //                queryParams.push(`${i.field}[em]=${i.value}`);
    //                break;
    //            case "isNotEmpty":
    //                queryParams.push(`${i.field}[nem]=${i.value}`);
    //                break;
    //            case "isAnyOf":
    //                queryParams.push(`${i.field}[in]=${i.value}`);
    //                break;
    //        }
    //    });

    //    return queryParams?.join("&") ?? "";
    //}

    function getSortQuery(sortModel: GridSortModel) {
        var queryParams: string[] = [];
        for (var i = 0; i < sortModel.length; i++)
        {
            if (sortModel[i].sort === "asc") {
                queryParams.push(sortModel[i].field);
            }
            else {
                queryParams.push(`-${sortModel[i].field}`);
            }
        }

        return ("sorts=" + queryParams?.join(",")) ?? "";
    }

    //function getSortQuery(sortModel: GridSortModel) {
    //    var queryParams: string[] = [];
    //    for (var i = 0; i < sortModel.length; i++)
    //    {
    //        queryParams.push(`sort[${i}]=${sortModel[i].field}:${sortModel[i].sort}`);
    //    }

    //    return queryParams.join("&");
    //}

    const Footer = () => {
        if (footerLeft) {
            return (
                <GridFooterContainer>
                    {footerLeft}
                    <div></div>
                    <GridFooter sx={{ border: 'none' }} />
                </GridFooterContainer>
            )
        }
        else {
            return (
                <GridFooterContainer>
                    <div></div>
                    <GridFooter sx={{ border: 'none' }} />
                </GridFooterContainer>
            );
        }
    }

    function getGridSx() {
        if (height) {
            return {
                height: height
            };
        }
        else {
            return {};
        }
    }

    return (
        <>
            {((showAddButton === true) && (permissionKey && checkAccess(permissionKey, Access.Create)) || 
                (showAddButtonEditOnly === true) && (permissionKey && checkAccess(permissionKey, Access.Update))) && (
                <div style={{ textAlign: "right" }}>
                    <Tooltip title={strings.gridAddRowTooltipText}>
                        <Button variant="contained"
                            size={addButtonSize}
                            startIcon={<AddIcon />}
                            disabled={addButtonDisabled}
                            onClick={handleAddButtonClick}
                        >
                            {addButtonText ?? "Add"}
                        </Button>
                    </Tooltip>
                    <Spacer y={2} />
                </div>           
            )}     
            {rest.paginationMode === "server" ?
                <StyledDataGrid
                    localeText={gridLocaleText}                            
                    rows={rows}
                    autoPageSize={rest.autoPageSize ?? true}
                    sx={getGridSx()}
                    getRowId={getRowId}
                    onRowSelectionModelChange={handleSelectionModelChange}
                    filterMode="server"                                                       
                    onFilterModelChange={handleFilterModelChange}
                    sortingMode="server"                    
                    onSortModelChange={handleSortModelChange}
                    hideFooterSelectedRowCount={true}                                 
                    slots={{ 
                        footer: Footer, 
                        ...rest.slots }}                                                   
                    {...rest}
                /> :                                    
                <StyledDataGrid
                    localeText={gridLocaleText}                            
                    rows={rows}
                    autoPageSize={rest.autoPageSize ?? true}
                    sx={getGridSx()}
                    getRowId={getRowId}
                    onRowSelectionModelChange={handleSelectionModelChange}                    
                    filterModel={innerFilterModel}                                            
                    onFilterModelChange={handleFilterModelChange}                    
                    sortModel={innerSortModel}
                    onSortModelChange={handleSortModelChange}
                    hideFooterSelectedRowCount={true}                                 
                    slots={{ 
                        footer: Footer, 
                        ...rest.slots }}                                                   
                    {...rest}
                /> 
            }
        </>
    );
}

export default DataGridWrapper;