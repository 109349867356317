import { string } from "prop-types";
import { StringLanguageDict } from "../../common/LanguageModel";
import { GuestSelectorData } from "../../common/GuestSelectorData";
import { MessageType } from "./MessageType";
//import { RoomClass } from "../roommanager/RoomClass";

export interface Message {
    id: string;
    from?: StringLanguageDict | null;
    toData?: GuestSelectorData | null;
    sendAt: Date | null;
    subject: StringLanguageDict | null;
    isHighImportance: boolean;
    type: MessageType;
    text: StringLanguageDict | null;    
    recipients?: Recipient[] | null;
}

export interface Recipient {
    firstName: string;
    lastName: string;
    externalKey: string;
    isRead: boolean;
    readDate: Date;
}

// Use a function here or the sendAt date will get stuck at whatever it was when the page loaded
export function getInitialMessageState(type?: MessageType, timeZoneId?: string): Message {
    return {
        id: "",
        from: null,
        toData: null,
        sendAt: new Date(new Date().toLocaleString("en-US", { timeZone: timeZoneId ?? "UTC" })),
        subject: null,
        isHighImportance: false,
        type: type ?? "Text",
        text: null,
        recipients: null
    }
}

export interface SentMessagesResponse {
    messages: SentMessage[];
    pageNumber: number;    pageSize: number;
    totalRecords: number;
}

export interface RecipientsResponse {
    recipients: Recipient[];
    pageNumber: number;    pageSize: number;
    totalRecords: number;
}

export interface SentMessage {
    id: string;
    from?: StringLanguageDict | null;
    toData?: GuestSelectorData | null;
    sendAt: Date | null;
    type: MessageType;
    subject: StringLanguageDict | null;    
    readRate?: number;    
}

export const initialSentMessageState: SentMessage = {
    id: "",
    from: null,
    toData: null,
    type: "Text",
    sendAt: null,
    subject: null,
    readRate: 0    
}