import EditIcon from "@mui/icons-material/Edit";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { default as MuiIconButton } from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useEffect, useState } from "react";
import { getConfiguration, saveConfiguration } from "../../../api/dashboard/network/configurationApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Configuration as ConfigurationModel, ConfigurationArea as ConfigurationAreaModel, getInitialConfigurationState, getInitialNetworkConfigurationAreaState, getInitialPotsConfigurationAreaState, getInitialWiFiConfigurationAreaState } from "../../../models/dashboard/network/Configuration";
import { useUser } from "../../../providers/UserProvider";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import IconButton from "../../common/details/IconButton";
import { default as TextField } from '../../common/details/TextField';
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import { Access } from "../../../models/configuration/security/Permission";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectChangeEvent } from "@mui/material/Select";
import { default as DialogSelect } from "../../common/details/Select";

interface ValidationErrors {
    circuitDeviceId: string;
    gatewayDeviceId: string;
    bandwidthDeviceId: string;
    oui: string;
    serialNumber: string;
    zone: string;
}

const Configuration = () => {   
    const initialErrorState: ValidationErrors = {
        circuitDeviceId: "",
        gatewayDeviceId: "",
        bandwidthDeviceId: "",
        oui: "",
        serialNumber: "",
        zone: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    enum TabValues {
        NETWORK = 0,
        POTS = 1,
        WIFI = 2
    }

    const [gridRefresh, setGridRefresh] = useState(false);
    const [configuration, setConfiguration] = useState<ConfigurationModel>(getInitialConfigurationState()); 
    const [editConfiguration, setEditConfiguration] = useState<ConfigurationModel>(getInitialConfigurationState()); 
    const [isLoading, setIsLoading] = useState(true);       
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);        
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);    
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const open = Boolean(anchorEl);
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user, checkAccess, hasIncludedPropertyFeatures } = useUser();     
    const hasNetworkFeature = hasIncludedPropertyFeatures(["NETWORK", "NETWORK_CIRCUITONLY"]);
    const hasPotsFeature = hasIncludedPropertyFeatures(["POTS"]);
    const hasWiFiFeature = hasIncludedPropertyFeatures(["WIFI"]);
    const [tabValue, setTabValue] = useState<TabValues>(getInitialTab());

    useEffect(() => {
        async function load() {
            try {
                const payload = await getConfiguration(axiosInstance, user.currentProperty?.code ?? "");
                setConfiguration(payload);
                setEditConfiguration(JSON.parse(JSON.stringify(payload))); // HACK: https://stackoverflow.com/questions/42306712/react-one-state-variable-depends-on-multiple-other-states-variables                
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingConfiguration.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        load();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetrievingConfiguration]);

    // Turn JSON config into a human-readable strings. We may have to mess with this in the future if we have more complex configurations (like DataRemote below)
    function getReadable(json: string) {        
        var o = JSON.parse(json);
        if (Array.isArray(o)) {
            // It's possible for some configs to be arrays of objects, so we need to have an outer loop 1st, then loop through properties of the object.
            return o.map((o2: object) => Object.entries(o2).map(([key2, value2]) => (
                <tr>
                    <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.getString(key2)}:</Typography></td>
                    <td><Typography sx={{ whiteSpace: 'nowrap' }}>{value2 as string}</Typography></td>
                </tr>
            )));
        }
        else {
            // This is a single object, so no outer loop, just loop through properties of the object.
            return Object.entries(o).map(([key, value]) => (
                <tr>
                    <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.getString(key)}:</Typography></td>                
                    <td><Typography sx={{ whiteSpace: 'nowrap' }}>{value as string}</Typography></td>
                </tr>
            ));
        }       
    }   

    /*
     * Turn JSON config into text fields. We may have to mess with this in the future if we have more complex configurations (like DataRemote below).
     * NOTE: The signature of the onClick takes an array of indexes, this is to handle the case where the config is an array of objects, the 1st index (index) 
     * is the Area array, the 2nd (index2) is the inner array (POTS devices for example). The outer loop (Area) is in the caller of this function (in the tab).
     */ 
    function getTextFields(json: string, index: number, onChange: (property: string, indexes: number[], event: React.ChangeEvent<HTMLInputElement>) => any) {
        var o = JSON.parse(json);
        if (Array.isArray(o)) {
            // It's possible for some configs to be arrays of objects, so we need to have an outer loop 1st, then loop through properties of the object.
            return o.map((o2: object, index2: number) => Object.entries(o2).map(([key2, value2]) => (
                <>
                    <TextField
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(key2, [index, index2], event)}
                        label={strings.getString(key2)}
                        value={value2}                                
                        width={300}
                        helperWidth={200}
                        error={Boolean(errors.circuitDeviceId)}
                        helperText={strings.validationPromotionScheduleMonthlyDay}                                    
                    />
                    <Spacer />
                </>
            )));
        }
        else {
            // This is a single object, so no outer loop, just loop through properties of the object.
            return Object.entries(o).map(([key, value]) => (
                <>
                    <TextField
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(key, [index], event)}
                        label={strings.getString(key)}
                        value={value as string}
                        width={300}
                        helperWidth={200}
                        error={Boolean(errors.circuitDeviceId)}
                        helperText={strings.validationPromotionScheduleMonthlyDay}                            
                    />
                    <Spacer />
                </>
            ));
        }        
    }

    function handleEditClick(event: React.MouseEvent<HTMLButtonElement>) {        
        setDetailsDialogOpen(true);
    }

    function handleTabChange(event: React.SyntheticEvent, newValue: number) {        
        setTabValue(newValue);        
    }

    function handleNetworkAreaName(index: number, event: React.ChangeEvent<HTMLInputElement>) {
        if (editConfiguration?.network) {
            const areas = [...editConfiguration.network.areas];            
            areas[index].name = event.target.value;
            
            setEditConfiguration((prevState) => ({
                ...prevState,
                network: {
                    ...prevState.network,
                    areas: areas,
                },
            }));
        }
    }

    function handleNetworkConfigurationChange(property: string, indexes: number[], event: React.ChangeEvent<HTMLInputElement>) {
        if (editConfiguration?.network) {
            const areas = [...editConfiguration.network.areas];
            var o = JSON.parse(areas[indexes[0]].configuration);
            o[property] = event.target.value;
            areas[indexes[0]].configuration = JSON.stringify(o);
            
            setEditConfiguration((prevState) => ({
                ...prevState,
                network: {
                    ...prevState.network,
                    areas: areas,
                },
            }));
        }
    }

    function handlePotsAreaName(index: number, event: React.ChangeEvent<HTMLInputElement>) {
        if (editConfiguration?.pots) {
            const areas = [...editConfiguration.pots.areas];            
            areas[index].name = event.target.value;
            
            setEditConfiguration((prevState) => ({
                ...prevState,
                pots: {
                    ...prevState.pots,
                    areas: areas,
                },
            }));
        }
    }
    
    function handlePotsConfigurationChange(property: string, indexes: number[], event: React.ChangeEvent<HTMLInputElement>) {
        if (editConfiguration?.pots) {            
            const areas = [...editConfiguration.pots.areas];
            var o = JSON.parse(areas[indexes[0]].configuration);      

            // This is one that has 2 arrays (areas, and devices)            
            if (Array.isArray(o)) {
                o[indexes[1]][property] = event.target.value; // Set the property for the device we are changing (inner array)
                areas[indexes[0]].configuration = JSON.stringify(o);
            
                setEditConfiguration((prevState) => ({
                    ...prevState,
                    pots: {
                        ...prevState.pots,
                        areas: areas,
                    },
                }));
            }
            else {
                o[property] = event.target.value;
                areas[indexes[0]].configuration = JSON.stringify(o);
            
                setEditConfiguration((prevState) => ({
                    ...prevState,
                    network: {
                        ...prevState.network,
                        areas: areas,
                    },
                }));
            }
        }
    }

    function handleWifiAreaName(index: number, event: React.ChangeEvent<HTMLInputElement>) {
        if (editConfiguration?.wifi) {
            const areas = [...editConfiguration.wifi.areas];            
            areas[index].name = event.target.value;
            
            setEditConfiguration((prevState) => ({
                ...prevState,
                wifi: {
                    ...prevState.wifi,
                    areas: areas,
                },
            }));
        }
    }

    function handleWifiProviderChange(index: number, event: SelectChangeEvent) {
        const provider = event.target.value as string;
        const areas = [...editConfiguration.wifi.areas];
        areas[index].provider = provider;

        switch (provider) {
            case "Rukus":
                areas[index].configuration = JSON.stringify({ zone: "" });
                break;
            case "Aruba":
                areas[index].configuration = JSON.stringify({ site: "" });
                break;
            case "Nomadix":
                areas[index].configuration = JSON.stringify({ apGroup: "" });
                break;
        }

        setEditConfiguration((prevState) => ({
                ...prevState,
                wifi: {
                    ...prevState.wifi,
                    areas: areas,
                },
            }));
    }

    function handleWifiConfigurationChange(property: string, indexes: number[], event: React.ChangeEvent<HTMLInputElement>) {
        if (editConfiguration?.wifi) {
            const areas = [...editConfiguration.wifi.areas];
            var o = JSON.parse(areas[indexes[0]].configuration);
            o[property] = event.target.value;
            areas[indexes[0]].configuration = JSON.stringify(o);
            
            setEditConfiguration((prevState) => ({
                ...prevState,
                wifi: {
                    ...prevState.wifi,
                    areas: areas,
                },
            }));
        }
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);        
        setTabValue(getInitialTab());
        setEditConfiguration(JSON.parse(JSON.stringify(configuration))); // HACK: https://stackoverflow.com/questions/42306712/react-one-state-variable-depends-on-multiple-other-states-variables
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);

        var config = { ...editConfiguration };

        // If we had a feature at one point but no longer do, we need to clear the config.
        if (!hasNetworkFeature) {
            config.network.areas = [];
        }

        if (!hasPotsFeature) {
            config.pots.areas = [];
        }

        if (!hasWiFiFeature) {
            config.wifi.areas = [];
        }
           
        try {            
            await saveConfiguration(axiosInstance, user.currentProperty?.code ?? "", config);            
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorUpdatingConfiguration.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }    

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);        
        return true;
    }

    function handleValidate() {
        return true;
        // TODO: need to figure out a dynamic way to validate
        //var errors = validate<ActivityModel, ValidationErrors>([
        //    { property: "name", type: ValidationType.Required, message: strings.validationActivityName },
        //    { property: "description", type: ValidationType.Required, message: strings.validationActivityDescription }
        //], selectedActivity);
        //if (errors) {
        //    setErrors(errors);
        //    return false;
        //}
        //else {
        //    return true;
        //}
    }

    function shouldShowTabs() {       
        var count = 0;
        if (hasNetworkFeature) {
            count++;
        }
        if (hasPotsFeature) {
            count++;
        }
        if (hasWiFiFeature) {
            count++;
        }
        return count > 1;
    }

    function getInitialTab() {
        if (hasNetworkFeature) {
            return TabValues.NETWORK;
        }
        else if (hasPotsFeature) {
            return TabValues.POTS;
        }
        else if (hasWiFiFeature) {
            return TabValues.WIFI;
        }
        else {
            return TabValues.NETWORK;
        }
    }

    function handleAddNetworkArea() {        
        setEditConfiguration((prevState) => ({
            ...prevState,
            network: {
                ...prevState.network,
                areas: [...prevState.network.areas, getInitialNetworkConfigurationAreaState()]
            }
        }));
    }

    function handleDeleteNetworkArea(index: number) {
        const areas = [...editConfiguration.network.areas];
        areas.splice(index, 1);
         setEditConfiguration((prevState) => ({
            ...prevState,
            network: {
                ...prevState.network,
                areas: areas
            }
        }));
    }

    function handleAddPotsArea() {
        setEditConfiguration((prevState) => ({
            ...prevState,
            pots: {
                ...prevState.pots,
                areas: [...prevState.pots.areas, getInitialPotsConfigurationAreaState()]
            }
        }));
    }

    function handleDeletePotsArea(index: number) {
        const areas = [...editConfiguration.pots.areas];
        areas.splice(index, 1);
         setEditConfiguration((prevState) => ({
            ...prevState,
            pots: {
                ...prevState.pots,
                areas: areas
            }
        }));
    }

    function handleAddWiFiArea() {
        setEditConfiguration((prevState) => ({
            ...prevState,
            wifi: {
                ...prevState.wifi,
                areas: [...prevState.wifi.areas, getInitialWiFiConfigurationAreaState()]
            }
        }));
    }    

    function handleDeleteWiFiArea(index: number) {
        const areas = [...editConfiguration.wifi.areas];
        areas.splice(index, 1);
         setEditConfiguration((prevState) => ({
            ...prevState,
            wifi: {
                ...prevState.wifi,
                areas: areas
            }
        }));
    }

    if (isLoading) {
        return <Box><LinearProgress color={"primary"} variant={"query"} /></Box>;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>                
                {checkAccess("configuration_network", Access.Update) &&      
                    <>
                        <Grid item>
                            <Tooltip title={strings.edit}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    aria-label="audit"
                                    aria-haspopup="true"
                                    onClick={handleEditClick}                                
                                    startIcon={<EditIcon />}
                                >
                                    {strings.edit}
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Spacer y={2} />
                    </>
                }                
                <Grid container direction="row">
                    {configuration?.network?.areas && configuration?.network?.areas?.length > 0 && 
                        <>
                            <Grid item>
                                <Typography variant="h5">{strings.network}</Typography>
                                {configuration?.network?.areas.map((area: ConfigurationAreaModel) => (
                                    <>                                    
                                        <table cellSpacing={theme.spacing(1)} style={{ marginLeft: theme.spacing(-1) }}>
                                            {configuration?.network?.areas.length > 1 && 
                                                <tr>
                                                    <th colSpan={2} align="left"><Typography variant="h6">{area.name}</Typography></th>
                                                </tr>
                                            }
                                            {getReadable(area.configuration)}
                                        </table>   
                                        <Spacer y={1} />
                                    </>
                                ))}
                            </Grid>   
                            <Grid item>
                                <Spacer x={6} />
                            </Grid>
                        </>
                    }
                    {configuration?.pots?.areas && configuration?.pots?.areas?.length > 0 && 
                            <>
                            <Grid item>
                                <Typography variant="h5">{strings.pots}</Typography>
                                {configuration?.pots?.areas.map((area: ConfigurationAreaModel) => (
                                    <>                                    
                                        <table cellSpacing={theme.spacing(1)} style={{ marginLeft: theme.spacing(-1) }}>
                                            {configuration?.pots?.areas.length > 1 && 
                                                <tr>
                                                    <th colSpan={2} align="left"><Typography variant="h6">{area.name}</Typography></th>
                                                </tr>
                                            }
                                            {getReadable(area.configuration)}
                                        </table>      
                                        <Spacer y={1} />
                                    </>
                                ))}
                            </Grid>
                            <Grid item>
                                <Spacer x={6} />
                            </Grid>
                        </>
                    }
                    {configuration?.wifi?.areas && configuration?.wifi?.areas?.length > 0 && 
                        <Grid item>
                            <Typography variant="h5">{strings.accessPoints}</Typography>
                            {configuration?.wifi?.areas.map((area: ConfigurationAreaModel) => (
                                <>                                    
                                    <table cellSpacing={theme.spacing(1)} style={{ marginLeft: theme.spacing(-1) }}>
                                        {configuration?.wifi?.areas.length > 1 && 
                                            <tr>
                                                <th colSpan={2} align="left"><Typography variant="h6">{area.name}</Typography></th>
                                            </tr>
                                        }
                                        <tr>
                                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>Provider:</Typography></td>
                                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{area.provider}</Typography></td>
                                        </tr>
                                        {getReadable(area.configuration)}
                                    </table>      
                                    <Spacer y={1} />
                                </>
                            ))}
                        </Grid>
                    }
                    <Grid item>
                        <Spacer x={2} />
                   </Grid>                    
                </Grid>

                <DetailsDialog
                    permissionKey="configuration_network"
                    open={detailsDialogOpen}
                    adding={false}
                    title={strings.editConfigurationTitle}
                    onValidateForm={handleValidate}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 648, height: shouldShowTabs() ? 482.53 : 437.53 }}
                >
                    {shouldShowTabs() &&
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="basic tabs example"
                            >                                
                                {/*<Tab label={strings.network} sx={{ display: editConfiguration?.network === null || editConfiguration?.network?.areas.length === 0 ? "none" : "block" }} />                             */}
                                {/*<Tab label={strings.pots} sx={{ display: editConfiguration?.pots === null || editConfiguration?.pots?.areas.length === 0 ? "none" : "block" }} />                                */}
                                {/*<Tab label={strings.accessPoints} sx={{ display: editConfiguration?.wifi === null || editConfiguration?.wifi?.areas.length === 0 ? "none" : "block" }} />                                                                                      */}
                                {hasNetworkFeature &&
                                    <Tab label={strings.network} value={TabValues.NETWORK} />    
                                }
                                {hasPotsFeature &&
                                    <Tab label={strings.pots} value={TabValues.POTS} />                                
                                }
                                {hasWiFiFeature &&
                                    <Tab label={strings.accessPoints} value={TabValues.WIFI} /> 
                                }
                            </Tabs>
                        </Box>
                    }                    
                    <TabPanelKeepMounted value={tabValue} index={0}>                        
                        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddNetworkArea}>
                            {strings.addArea}
                        </Button>
                        <Spacer y={2} />
                        <Box sx={{ overflow: "hidden", overflowY: "auto", paddingTop: 1, height: 363.63 }}>
                            {editConfiguration?.network?.areas.map((area: ConfigurationAreaModel, index: number) => (
                                <>
                                    {editConfiguration?.network?.areas.length > 1 && 
                                        <>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <TextField
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNetworkAreaName(index, event)}                                            
                                                    label="Area"
                                                    value={area.name}
                                                    width={300}
                                                    helperWidth={200}
                                                    error={Boolean(errors.circuitDeviceId)}
                                                    helperText={strings.validationPromotionScheduleMonthlyDay}                                            
                                                />
                                                <Tooltip title={strings.deleteButtonTitle}>
                                                    <div style={{ marginBottom: theme.spacing(3) }}>
                                                        <IconButton
                                                            id="buttonDeleteNetworkArea"
                                                            aria-label="Delete"                                                            
                                                            color="error"
                                                            onClick={() => handleDeleteNetworkArea(index)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </Stack>
                                            <Spacer />                                                                                                            
                                        </>
                                    } 
                                    {getTextFields(area.configuration, index, handleNetworkConfigurationChange)}
                                    <Spacer y={1} />
                                </>
                            ))}
                        </Box>                        
                    </TabPanelKeepMounted>                                        
                    <TabPanelKeepMounted value={tabValue} index={1}>                           
                        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddPotsArea}>
                            {strings.addArea}
                        </Button>
                        <Spacer y={2} />
                        <Box sx={{ overflow: "hidden", overflowY: "auto", paddingTop: 1, height: 363.63 }}>
                            {editConfiguration?.pots?.areas.map((area: ConfigurationAreaModel, index: number) => (
                                <>
                                    {editConfiguration?.pots?.areas.length > 1 && 
                                        <>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <TextField
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handlePotsAreaName(index, event)} 
                                                    label="Area"
                                                    value={area.name}
                                                    width={300}
                                                    helperWidth={200}
                                                    error={Boolean(errors.circuitDeviceId)}
                                                    helperText={strings.validationPromotionScheduleMonthlyDay}                                            
                                                />
                                                <Tooltip title={strings.deleteButtonTitle}>
                                                    <div style={{ marginBottom: theme.spacing(3) }}>
                                                        <IconButton
                                                            id="buttonDeletePotsArea"
                                                            aria-label="Delete"                                                            
                                                            color="error"
                                                            onClick={() => handleDeletePotsArea(index)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </Stack>
                                            <Spacer />                                                                                                            
                                        </>
                                    }                                     
                                    {getTextFields(area.configuration, index, handlePotsConfigurationChange)}
                                    <Spacer y={1} />
                                </>
                            ))} 
                        </Box>
                    </TabPanelKeepMounted>                    
                    <TabPanelKeepMounted value={tabValue} index={2}>                           
                        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddWiFiArea}>
                            {strings.addArea}
                        </Button>
                        <Spacer y={2} />
                        <Box sx={{ overflow: "hidden", overflowY: "auto", paddingTop: 1, height: 363.63 }}>
                                {editConfiguration?.wifi?.areas.map((area: ConfigurationAreaModel, index: number) => (
                                    <>
                                        {editConfiguration?.wifi?.areas.length > 1 && 
                                            <>
                                                <Stack direction="row" spacing={2} alignItems="center">
                                                    <TextField
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleWifiAreaName(index, event)} 
                                                        label="Area"
                                                        value={area.name}
                                                        width={300}
                                                        helperWidth={200}
                                                        error={Boolean(errors.circuitDeviceId)}
                                                        helperText={strings.validationPromotionScheduleMonthlyDay}                                            
                                                    />
                                                    <Tooltip title={strings.deleteButtonTitle}>
                                                        <div style={{ marginBottom: theme.spacing(3) }}>
                                                            <IconButton
                                                                id="buttonDeleteWiFiArea"
                                                                aria-label="Delete"                                                            
                                                                color="error"
                                                                onClick={() => handleDeleteWiFiArea(index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>
                                                </Stack>        
                                                <Spacer />                                                                                                            
                                            </>
                                        } 
                                        <DialogSelect
                                            label={strings.provider}
                                            onChangeHandler={(e: SelectChangeEvent) => handleWifiProviderChange(index, e)}
                                            selectedValue={area.provider}
                                            keyValues={[{ key: "Rukus", value: "Rukus" }, { key: "Aruba", value: "Aruba" }, { key: "Nomadix", value: "Nomadix" }]}
                                        />
                                        {getTextFields(area.configuration, index, handleWifiConfigurationChange)}
                                        <Spacer y={1} />
                                    </>
                                ))}
                            </Box>                        
                    </TabPanelKeepMounted>                                                  
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>   
        )
    }
}

export default Configuration;