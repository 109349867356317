import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { createTemplate, deleteTemplate, getTemplates, updateTemplate } from "../../../api/messaging/templatesApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { initialTemplateState, Template as TemplateModel } from "../../../models/modules/messaging/Template";
import { MessageType } from "../../../models/modules/messaging/MessageType";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from '../../common/details/DetailsDialog';
import TextField from "../../common/details/TextField";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import RttIcon from '@mui/icons-material/Rtt';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VideoManager, { VideoManagerRefObject } from "../../common/video/VideoManager";
import { VideoModel } from "../../../models/common/VideoModel";
import { deleteVideos } from "../../../api/videoApi";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { deleteGraphics } from "../../../api/graphicsApi";

interface ValidationErrors {
    internalName: string,
    from: string;
    subject: string;    
}

const Templates = () => {
    const initialErrorState: ValidationErrors = {
        internalName: "",
        from: "",
        subject: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [gridRefresh, setGridRefresh] = useState(false);
    const [templates, setTemplates] = useState<TemplateModel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);    
    const [tabValue, setTabValue] = useState<number>(0);
    const [selectedTemplateId, setSelectedTemplateId] = useState("");
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateModel>(initialTemplateState("Text"));
    const [isLoading, setIsLoading] = useState(true);        
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);    
    const [newTemplateMenuAnchor, setNewTemplateMenuAnchor] = useState<null | HTMLElement>(null);
    const newTemplateMenuOpen = Boolean(newTemplateMenuAnchor);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const videoManagerRef = useRef<VideoManagerRefObject>(null);
    const { user } = useUser();  

    useEffect(() => {
        async function loadTemplates() {            
            try {
                const payload = await getTemplates(axiosInstance, user.currentProperty?.code ?? "");
                setTemplates(payload);
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingTemplates.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }
             
            setIsLoading(false);            
        }

        loadTemplates();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingTemplates]);

    function handleEditClick(id: string) {       
        var template = templates?.find(t => t.id === id);
        if (template) {
            setSelectedTemplateId(id);
            setSelectedTemplate(template);
            setDetailsDialogOpen(true);
        }
    }

    function handleNewTemplateClick(event: React.MouseEvent<HTMLButtonElement>) {    
        setNewTemplateMenuAnchor(event.currentTarget);        
    }

    function handleNewTemplateMenuClose() {
        setNewTemplateMenuAnchor(null);
    }

    function handleNewTemplateMenuClick(type: MessageType) {
        setSelectedTemplateId("");
        setSelectedTemplate(initialTemplateState(type));
        setNewTemplateMenuAnchor(null);
        setDetailsDialogOpen(true);
    }

    async function handleDeleteClick(id: string) {
        const internalName = templates.find(p => p.id === id)?.internalName ?? "";

        alertDialog({
            title: strings.deleteMessageTemplateTitle,
            message: strings.deleteMessageTemplateMessage.replace("{{name}}", internalName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedTemplate(id);
        });
    }

    async function deleteSelectedTemplate(id: string) {
        const images: GraphicModel[] = [
            { imageKey: "main", url: "", fileData: null },
            { imageKey: "banner", url: "", fileData: null },
        ];

        const videos: VideoModel[] = [
            { videoKey: "main", url: "", fileData: null },
            { videoKey: "banner", url: "", fileData: null },
        ];
        
        try {
            if (selectedTemplate.type === "Image") {
                await deleteGraphics(axiosInstance, "message", id, images, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
            }
            else if (selectedTemplate.type === "Video") {
                await deleteVideos(axiosInstance, "message", id, videos, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
            }

            await deleteTemplate(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorDeletingMessageTemplate.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.messageTemplateDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedTemplateId("");
        setSelectedTemplate(initialTemplateState("Text"));
    }

    function buildNameField(row: TemplateModel) {
        return (
            <>
                <span style={{ marginRight: theme.spacing(1) }}>{getIcon(row.type)}</span>
                <span>{row.internalName}</span>
            </>
        );
    }

    const gridColumns: GridColDef[] = [        
        { field: "internalName", headerName: strings.name, renderCell: (params) => buildNameField(params.row), flex: 1 },
        { field: "from", headerName: strings.from, valueGetter: (value, row) => row.from.en, flex: 1 },        
        { field: "subject", headerName: strings.subject, valueGetter: (value, row) => row.subject.en, flex: 3 },        
        { field: "enabled", headerName: strings.enabled, type: "boolean", flex: 1 },    
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_messaging" 
                    rowId={params.id.toString()} 
                    clickHandler={handleEditClick} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_messaging"
                    rowId={params.id.toString()}
                    clickHandler={handleDeleteClick}
                />
            ],
        },
    ];

    const dialogTitle = selectedTemplateId === "" ? strings.addMessageTemplateTitle : strings.editMessageTemplateTitle.replace("{{name}}", selectedTemplate?.internalName);  

    function handleInternalNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedTemplate((prevState) => ({
            ...prevState,
            internalName: event.target.value,
        }));
    }
    
    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedTemplate((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
     }

    function handleFromChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedTemplate((prevState) => ({
            ...prevState,
            from: {
                ...prevState.from,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleSubjectChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedTemplate((prevState) => ({
            ...prevState,
            subject: {
                ...prevState.subject,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleIsHighImportanceChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedTemplate((prevState) => ({
            ...prevState,
            isHighImportance: event.target.checked,
        }));
     }

    function handleTabChange(event: React.SyntheticEvent, newValue: number) {        
        setTabValue(newValue);
    } 

    function handleTextChange(content: string, language: string) {
        setSelectedTemplate((prevState) => ({
            ...prevState,
            text: {
                ...prevState.text,
                [language]: content,
            },
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);        
        setTabValue(0);
    }

    function handleValidate() {
        var valid = true;

        var errors = validate<TemplateModel, ValidationErrors>([
            { property: "internalName", type: ValidationType.Required, message: strings.validationMessageTemplateName },
            { property: "from.en", type: ValidationType.Required, message: strings.validationMessageFrom },            
            { property: "subject.en", type: ValidationType.Required, message: strings.validationMessageSubject }
        ], selectedTemplate);

        if (errors) {
            setErrors(errors);
            valid = false;
        }

        if (selectedTemplate.type === "Image") {
            const imageIsValid = graphicManagerRef.current?.validate() ?? false;
            valid = valid && imageIsValid;
        }
        else if (selectedTemplate.type === "Video") {
            const videoIsValid = videoManagerRef.current?.validate() ?? false;
            valid = valid && videoIsValid;
        }

        return valid;    
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);

        var id = "";
        const isUpdate = selectedTemplateId !== "";
        try {
            if (isUpdate) {
                id = selectedTemplate.id;
                await updateTemplate(axiosInstance, user.currentProperty?.code ?? "", selectedTemplate);
            } else {
                id = await createTemplate(axiosInstance, user.currentProperty?.code ?? "", selectedTemplate);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorCreatingMessageTemplate
                    : strings.errorUpdatingMessageTemplate
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }

        if (selectedTemplate.type === "Image") {
            try {
                if (graphicManagerRef.current) {
                    await graphicManagerRef.current.saveGraphics(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
                }
            } 
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorSavingGraphic.replace("{{error}}", (error as Error).message), // TODO: get error message
                    msgType: "error",
                });

                return false;
            }
        }
        else if (selectedTemplate.type === "Video") {
            try {
                if (videoManagerRef.current) {
                    await videoManagerRef.current.saveVideos(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
                }
            }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.errorSavingVideo.replace("{{error}}", (error as Error).message), // TODO: get error message
                    msgType: "error",
                });

                return false;
            }
        }

        setDetailsDialogOpen(false);
        setTabValue(0);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.messageTemplateSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function getIcon(type: string) {
        switch (type) {            
            case "Image":
                return (<ImageIcon fontSize="small" />);
            case "Video":
                return (<VideocamIcon fontSize="small" />);
            case "Text":
            default:
                return (<RttIcon fontSize="small" />);
        }
    }

    function getTypeComponents(type: string) {
        switch (type) {            
            case "Image":
                return (
                    <GraphicManager
                        propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                        itemType="message"
                        itemKey={selectedTemplate.id}
                        imageKeys={["main"]}                            
                        ref={graphicManagerRef}
                    />);
            case "Video":
                return (
                    <VideoManager
                        propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                        itemType="message"
                        itemKey={selectedTemplate.id}
                        videoKeys={["main"]}                            
                        ref={videoManagerRef}
                    />);
            case "Text":
            default:
                return (
                    <LanguageRichTextField
                        values={selectedTemplate.text ?? {}}                                   
                        id="item-description"
                        onChange={handleTextChange}                                
                        width="100%"
                        height={300}
                        customButtons={["insertGuestName", "insertRoomNumber"]}
                    />);
        }
    }

    function getPopupHeight(type: string) {
        switch (type) {            
            case "Image":
                return 601.41;
            case "Video":
                return 601.41;
            case "Text":
            default:
                return 720.38;
        }
    }

    function getPopupTitle(type: string) {
        if (selectedTemplateId === "") {            
            switch (type) {            
                case "Image":
                    return strings.newImageTemplate;
                case "Video":
                    return strings.newVideoTemplate;
                case "Text":
                default:
                    return strings.newTextTemplate;
            }
        }
        else {
            return strings.editMessageTemplateTitle.replace("{{name}}", selectedTemplate?.internalName);
        }
    }

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}>        
            <DataGrid
                permissionKey="content_messaging"
                rows={templates}
                columns={gridColumns}
                onAddButtonClick={handleNewTemplateClick}
                addButtonText={strings.newMessageTemplate}
                loading={isLoading}
            />               

            <Menu
                id="newTemplateMenu"
                MenuListProps={{
                    'aria-labelledby': 'newMessage',
                }}
                anchorEl={newTemplateMenuAnchor}
                open={newTemplateMenuOpen}
                onClose={handleNewTemplateMenuClose}
            >
                <MenuItem key="newText" onClick={() => handleNewTemplateMenuClick("Text")}>
                    <ListItemIcon>
                        {getIcon("Text")}
                    </ListItemIcon>
                    <ListItemText>
                        {strings.newTextTemplate}
                    </ListItemText>
                </MenuItem>
                <MenuItem key="newImage" onClick={() => handleNewTemplateMenuClick("Image")}>
                    <ListItemIcon>
                        {getIcon("Image")}
                    </ListItemIcon>
                    <ListItemText>
                        {strings.newImageTemplate}
                    </ListItemText>
                </MenuItem>
                <MenuItem key="newVideo" onClick={() => handleNewTemplateMenuClick("Video")}>
                    <ListItemIcon>
                        {getIcon("Video")}
                    </ListItemIcon>
                    <ListItemText>
                        {strings.newVideoTemplate}
                    </ListItemText>
                </MenuItem>                               
            </Menu>

            <DetailsDialog
                permissionKey="content_messaging"
                adding={true}
                open={detailsDialogOpen}
                title={getPopupTitle(selectedTemplate.type)}
                onValidateForm={handleValidate}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                contentSize={{ width: 1200, height: getPopupHeight(selectedTemplate.type) }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <LanguageForm>
                        <Grid container direction="column">
                            <Grid item sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(2) }}>                                                            
                                <Grid container direction="row" alignContent="top">
                                    <TextField
                                        id="internal-name"
                                        value={selectedTemplate.internalName}
                                        label={strings.name}
                                        width={250}                            
                                        onChange={handleInternalNameChange}                            
                                        error={Boolean(errors.internalName)}
                                        helperText={errors.internalName}
                                    />
                                    <Spacer x={2} />                                                                        
                                    <Checkbox checked={selectedTemplate.enabled} onChange={handleEnabledChange} label={strings.enabled} height={56} />
                                </Grid>         
                                <Spacer />
                                <LanguageSelect />
                                <Spacer />                            
                                <LanguageTextField
                                    id="message-from"
                                    label={strings.from}
                                    values={selectedTemplate.from ?? {}}
                                    width={800}
                                    onChange={handleFromChange}
                                    error={Boolean(errors.from)}
                                    helperText={errors.from}
                                />                                           
                                <Spacer />                            
                                <Grid container direction="row" alignContent="top">
                                    <LanguageTextField
                                        id="message-subject"
                                        label={strings.subject}
                                        values={selectedTemplate.subject ?? {}}
                                        width={800}
                                        onChange={handleSubjectChange}
                                        error={Boolean(errors.subject)}
                                        helperText={errors.subject}
                                    />
                                    <Spacer x={2} />                                                                
                                    <Checkbox checked={selectedTemplate.isHighImportance} onChange={handleIsHighImportanceChange} label={strings.highImportance} height={56} />
                                </Grid>        
                            </Grid>
                            <Grid item sx={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: theme.spacing(1) }}>
                                {getTypeComponents(selectedTemplate.type)}
                            </Grid>
                        </Grid>
                    </LanguageForm>
                </LocalizationProvider>
            </DetailsDialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>           
    )    
}

export default Templates;