import { AxiosInstance } from "axios";
import { Message, Recipient, SentMessagesResponse, SentMessage, RecipientsResponse } from "../../models/modules/messaging/Message";
import { GuestSelectorData } from "../../models/common/GuestSelectorData";
import { parseLanguageStringsFromJson, dateToLocalISOString } from "../../utilities/JsonHelper";
import { parseToData } from "./helper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/messaging/";

export async function getSentMessages(axiosInstance: AxiosInstance, propertyCode: string, pageNumber: number, pageSize: number, query?: string): Promise<SentMessagesResponse> {
    var request = `${url}${propertyCode}/messages/sent?page=${pageNumber + 1}&pageSize=${pageSize}`;    
    if (query) {
        request += ("&" + query);        
    }

    return await axiosInstance
        .get(request)
        .then((response) => (({
                messages: parseLanguageStringsFromJson(parseToData(response.data.messages)) as SentMessage[],
                pageNumber: parseInt(response.headers["x-page"]),
                pageSize: parseInt(response.headers["x-page-size"]),
                totalRecords: parseInt(response.headers["x-total-count"]),
            }) as SentMessagesResponse)
        );
}

export async function getInReviewMessages(axiosInstance: AxiosInstance, propertyCode: string): Promise<Message[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/messages/inreview`)
        .then((response) => parseLanguageStringsFromJson(parseToData(response.data.messages)) as Message[]);
}

export async function getMessage(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<Message> {
    return await axiosInstance
        .get(`${url}${propertyCode}/messages/${id}`)
        .then((response) => parseLanguageStringsFromJson(parseToData(response.data.message)) as Message);
}

export async function getRecipients(axiosInstance: AxiosInstance, propertyCode: string, messageId: string) {//, pageNumber: number, pageSize: number, query?: string): Promise<RecipientsResponse> {
    var request = `${url}${propertyCode}/messages/${messageId}/recipients`;//?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`;    
    //if (query) {
    //    request += ("&" + query);        
    //}

    return await axiosInstance
        .get(request)
        .then((response) => (({
                recipients: parseLanguageStringsFromJson(parseToData(response.data.recipients)) as Recipient[],
                pageNumber: parseInt(response.headers["x-page"]),
                pageSize: parseInt(response.headers["x-page-size"]),
                totalRecords: parseInt(response.headers["x-total-count"]),
            }) as RecipientsResponse)
        );
}

export async function createMessage(axiosInstance: AxiosInstance, propertyCode: string, message: Message) {
    const id = uuid();

    const messageToSave = {
        ...message,  
        id: id,
        propertyId: propertyCode,
        from: JSON.stringify({ ...message.from }),    
        toData: JSON.stringify(message.toData),
        subject: JSON.stringify({ ...message.subject }),
        text: JSON.stringify({ ...message.text }),
        sendAt: message.sendAt ? dateToLocalISOString(message.sendAt) : null

    };

    return await axiosInstance.post(`${url}${propertyCode}/messages`, messageToSave).then((response) => {
        return id;
    });
}

export async function approveMessage(axiosInstance: AxiosInstance, propertyCode: string, message: Message) {
    const messageToSave = {
        ...message,       
        propertyId: propertyCode,
        from: JSON.stringify({ ...message.from }),
        toData: JSON.stringify(message.toData),
        subject: JSON.stringify({ ...message.subject }),
        text: JSON.stringify({ ...message.text }),
        sendAt: message.sendAt ? dateToLocalISOString(message.sendAt) : null
    };

    return await axiosInstance.put(`${url}${propertyCode}/messages/approved/${message.id}`, messageToSave).then((response) => {
        return response.data;
    });
}

export async function deleteMessage(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/messages/${id}`).then((response) => {
        return response.data;
    });
}