import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { default as MaterialTextField } from "@mui/material/TextField";
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { getRoom, updateRoom, searchRoom, addRoom, deleteRoom } from "../../../api/roomApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Room, RoomDetail, Tv, initialRoomState, initialTvState } from "../../../models/common/Room";
import { useUser } from "../../../providers/UserProvider";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import DataGrid from "../../common/datatable/DataGridWrapper";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import RoomClasses from "../../common/details/RoomClasses";
import TextField from "../../common/details/TextField";
import ValidateTab from "../../common/details/ValidateTab";
import RoomTvGrid from "./RoomTvGrid";
import Grid from '@mui/material/Grid';

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

interface RoomValidationErrors {
    number: string;
    classes: string;
}
interface RoomTvValidationErrors {
    name: string;
}
const initialErrorState: RoomValidationErrors = { 
    number: "",
    classes: ""
};

const initialTvErrorState: RoomTvValidationErrors = {
    name: ""
}

const RoomList = () => {    
    const [gridRefresh, setGridRefresh] = React.useState(false);
    const strings = useLocalizedStrings();
    const [roomRows, setRoomRows] = React.useState<Room[]>([]);
    const [errors, setErrors] = React.useState<RoomValidationErrors>(initialErrorState);
    const [tvErrors, setTvErrors] = React.useState<RoomTvValidationErrors>(initialTvErrorState)
    const [searchValue, setSearchValue] =  React.useState("");
    const [selectedRoom, setSelectedRoom] = React.useState<RoomDetail>(initialRoomState);
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
    const [tabValue, setTabValue] = React.useState<number>(0);
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const [isSearching, setIsSearching] = React.useState(false);
    const [tvDialogOpen, setTvDialogOpen] = React.useState(false);
    const [selectedTv, setSelectedTv] = React.useState<Tv>(initialTvState); 
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const alertDialog = useAlertDialog();
    const theme = useTheme();

    async function handleSearchClick() {        
        if (searchValue.trim().length === 0) {
            setRoomRows([]);
            return;
        }

        try {
            setIsSearching(true);
            const response = await searchRoom(axiosInstance, user.currentProperty?.code ?? "", searchValue);
            setRoomRows(response);
        } catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingRooms,
                msgType: "error",
            });
        }
        finally {
            setIsSearching(false);
        }
    };

    React.useEffect(() => {
        handleSearchClick();
    }, [gridRefresh]);

    function handleSearchRoomChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value as string);
    }

    function handleAddClick() {
        setSelectedRoom(initialRoomState);
        setDetailsDialogOpen(true)
    }

    async function handleEditRowClick(id: string) {
        try {
            const roomItem = await getRoom(axiosInstance, user.currentProperty?.code ?? "", id);
            setSelectedRoom(roomItem);
            setDetailsDialogOpen(true)
        } catch (error) {
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingSelectedRoom,
                msgType: "error"
            })
        }
    }

    function handleTabChange(event: React.SyntheticEvent, newValue: number) {
        setTabValue(newValue);
    };

    function handleRoomNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRoom((prevState) => ({
            ...prevState,
            number: event.target.value
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRoom((prevState) => ({
            ...prevState,
            enabled: event.target.checked
        }));
    }
    
    function handleRoomClassesChange(values: { id: string; name: string }[]) {
        setSelectedRoom((prevState) => ({
            ...prevState,
            classes: values
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
        setTabValue(0);
        setSelectedTv(initialTvState)
    } 

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        const roomToSave: any = {
            ...selectedRoom,
            classes: selectedRoom.classes.map(({ id }) => ({ id }))
        }

        const isUpdate = roomToSave.id !== "";
        try {
            if (isUpdate) {
                await updateRoom(axiosInstance, user.currentProperty?.code ?? "", roomToSave);
            }
            else {
                roomToSave.id = uuid();
                await addRoom(axiosInstance, user.currentProperty?.code ?? "", roomToSave);                
            }
            const updatedRoomRow = await searchRoom(axiosInstance, user.currentProperty?.code ?? "", roomToSave.number);
            setRoomRows(updatedRoomRow);
        } catch (error: unknown) {            
            setNotify({
                isOpen: true,
                message: (!isUpdate ? strings.errorAddingRoom : strings.errorUpdatingRoom).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }
        
        setSelectedTv(initialTvState);
        setTvErrors(initialTvErrorState); 
        setDetailsDialogOpen(false);
        setTabValue(0);
        setNotify({
            isOpen: true,
            message: strings.roomSavedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);

        return true;
    }

    function handleValidateRoom() {
        var fieldsToValidate : ValidationField[] = [{
            property: "number",
            type: ValidationType.Required,
            message: strings.validationErrorRoomNumberRequired
        },
        { 
            property: "classes", 
            type: ValidationType.Required, 
            message: strings.validationRoomClasses }
        ]

        var errors = validate<RoomDetail, RoomValidationErrors>(fieldsToValidate, selectedRoom);
        if (errors) {
            setErrors(errors);
            return false; 
        } 
        
        return true; 
    }

    function joinRoomClassNames(row: any) {
        return row.classes
            .map((element: { name: string }) => element.name)
            .join(", ");
    }

    function handleTvEditRowClick(id: string) {
        setTvDialogOpen(true);
       
        const tvToEdit = selectedRoom.televisions.find(tv => tv.id === id);
        if (tvToEdit) {
            setSelectedTv(tvToEdit);
        }
    }

    const handleTvDeleteRowClick = React.useCallback((id: string) => {
        // Timeout for row ID error, via MUI: https://github.com/mui/mui-x/issues/2714
        setTimeout(() => {
            setSelectedRoom((current: RoomDetail) => ({
                ...current,
                televisions: [
                    ...current.televisions.filter(tv => tv.id !== id),
                ]
            }))

            setSelectedTv(initialTvState);
        })
    }, [])

    function confirmTvDelete(id: string) {
        const displayName = selectedRoom.televisions.find(television => television.id === id)?.name ?? "";

        alertDialog({
            title: strings.deleteTvAlertTitle,
            message: strings.deleteTvAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            handleTvDeleteRowClick(id);
        });
    }
        
    async function handleTvSave() {
        setTvDialogOpen(false);
        
        // edit existing name or add new tv
        const tvFound = selectedRoom.televisions.find(tv => tv.id === selectedTv.id); 
        if (tvFound) {
            setSelectedRoom((current: RoomDetail) => ({
                ...current,
                televisions: [
                    ...current.televisions.map(tv => {
                        return tv.id === selectedTv.id 
                        ? { id: tv.id, name: selectedTv.name, showPreviewOnly: selectedTv.showPreviewOnly } 
                        : tv
                })
            ]
            }))
        }
        else {
            selectedTv.id = uuid();
            setSelectedRoom((current: RoomDetail) => ({
                ...current,
                televisions: [
                    ...current.televisions, 
                    selectedTv
                ]
            }))
        }

        return true;
    }

    function handleTvValidate() {
        var fieldsToValidate : ValidationField[] = [{
            property: "name",
            type: ValidationType.Required,
            message: strings.validationErrorTvNameRequired
        }]

        var tvErrors = validate<{name: string}, RoomTvValidationErrors>(fieldsToValidate, selectedTv);
        if (tvErrors) {
            setTvErrors(tvErrors);
            return false; 
        } 
        
        return true; 
    } 

    function handleTvCancelClick() {
        setTvErrors(initialTvErrorState);
        setSelectedTv(initialTvState);
        setTvDialogOpen(false);
    }

    function handleTVNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedTv((current: Tv) => ({
            ...current,
            name: event.currentTarget.value  
        }))
    }  

    function handleTvShowPreviewChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedTv((prevState) => ({
            ...prevState,
            showPreviewOnly: event.target.checked
        }));
    }

    function handleAddTvClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setTvDialogOpen(true);
        setSelectedTv(initialTvState);
    }

    function handleDeleteClick(id: string) {        
        const number = roomRows.find(p => p.id === id)?.number ?? "";

        alertDialog({
            title: strings.deleteRoomAlertTitle,
            message: strings.deleteRoomAlertMessage.replace("{{number}}", number),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSlectedRoom(id);
        });
    }

    async function deleteSlectedRoom(id: string) {
        try {
            await deleteRoom(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorDeletingRoom.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.roomDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedRoom(initialRoomState);        
    }

    const roomColumns: GridColDef[] = [
        { field: "number", headerName: strings.roomNumber, sortable: false, flex: 1 },        
        { field: "classes", headerName: strings.roomClasses, width: 300, valueGetter: (value, row) => joinRoomClassNames(row), sortable: false, flex: 3 },
        { field: "enabled", headerName: strings.enabled, type: "boolean",  sortable: false,  flex: 1 },        
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => {
                return [
                    <DataGridEditButton 
                        permissionKey="configuration_property" 
                        rowId={params.id.toString()} 
                        clickHandler={() => handleEditRowClick(params.id.toString())} 
                    />,
                    <DataGridDeleteButton
                        permissionKey="content_promotion"
                        rowId={params.id.toString()}
                        clickHandler={() => handleDeleteClick(params.id.toString())}
                    />,
                ];
            }
        }
    ];

    const tvColumns: GridColDef[] = [
        { field: "name",  headerName: strings.name, sortable: false, flex: 2 },
        { field: "showPreviewOnly", headerName: strings.previewOnly, type: "boolean",  sortable: false,  flex: 1 },
        { 
            field: "actions", 
            headerName: strings.gridActions, 
            type: "actions", 
            flex:1,  
            getActions: (params: GridRowParams) => {
                return [
                    <DataGridEditButton
                        permissionKey="configuration_property"
                        rowId={params.id.toString()}
                        clickHandler={() => handleTvEditRowClick(params.id.toString())}
                    />,
                    <DataGridDeleteButton
                        permissionKey="configuration_property"
                        rowId={params.id.toString()}
                        clickHandler={() => confirmTvDelete(params.id.toString())}
                    />
                ];
            }
        }
    ]
    
    const dialogTitle = selectedRoom.id === "" ? strings.roomDialogTitleAdd : strings.roomDialogTitleEdit.replace("{{number}}", selectedRoom?.number ?? "");
    const tvDialogTitle = selectedTv.name === "" ? strings.tvDialogTitleAdd : strings.tvDialogTitleEdit;

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 185px)" }}>
            <Grid container>      
                <Grid container item xs={6}>
                    <Stack direction="row">                
                        <MaterialTextField                    
                            onChange={handleSearchRoomChange}
                            label={strings.roomNumber}
                            variant="outlined"
                            value={searchValue}     
                            onKeyDown={(e) => (e.keyCode === 13 ? handleSearchClick() : null)}
                        />
                        <Spacer x={2} />           
                        <Button                    
                            variant="contained"                                            
                            onClick={handleSearchClick}
                            startIcon={<SearchIcon />}
                            aria-label="Search">
                            {strings.search}
                        </Button>
                    </Stack>
                </Grid>
                <Grid container item xs={6} justifyContent="flex-end">
                    <Box>
                        <Button     
                            sx={{ height: 56 }}
                            variant="contained"                                            
                            onClick={handleAddClick}
                            startIcon={<AddIcon />}
                            aria-label="Add">
                            {strings.newRoomButton}
                        </Button>
                    </Box>
                </Grid>                            
            </Grid>
            <Spacer y={2} />  
            <DataGrid
                rows={roomRows}
                columns={roomColumns}
                disableColumnMenu={true}                
                showAddButton={false}
                loading={isSearching}                
            />
            <DetailsDialog
                permissionKey="configuration_property"
                open={detailsDialogOpen}
                title={dialogTitle}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                onValidateForm={handleValidateRoom}
                contentSize={{ width: 636, height: 481.25 }}
            >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <ValidateTab label={strings.details} errors={Boolean(errors.number)} />
                        <ValidateTab label={strings.roomClasses} errors={Boolean(errors.classes)} />
                        <Tab label={strings.roomTvTab} />
                    </Tabs>
                </Box>
                <TabPanelKeepMounted value={tabValue} index={0}>
                    <TextField 
                        id="room-number"
                        label={strings.roomNumber} 
                        value={selectedRoom?.number ?? ""} 
                        width={200} 
                        onChange={handleRoomNumberChange}  
                        error={Boolean(errors.number)}
                        helperText={errors.number} 
                    />
                    <Spacer />
                    <Checkbox label={strings.enabled} checked={selectedRoom?.enabled} onChange={handleEnabledChange} />                    
                </TabPanelKeepMounted>
                <TabPanelKeepMounted value={tabValue} index={1}>
                    <RoomClasses
                        id={selectedRoom.id}
                        assignedClasses={selectedRoom.classes}
                        onChangeHandler={handleRoomClassesChange}
                        height={400}
                        error={Boolean(errors.classes)}
                        helperText={errors.classes}
                    /> 
                </TabPanelKeepMounted>
                <TabPanelKeepMounted value={tabValue} index={2}>
                    <RoomTvGrid 
                        columns={tvColumns}
                        rows={selectedRoom.televisions}
                        onAddButtonClick={handleAddTvClick}                        
                        disableColumnMenu={true}
                        addButtonText={strings.addTvButtonText}
                        addButtonSize={"large"}
                        height={350}                        
                        permissionKey="configuration_property"
                    />
                </TabPanelKeepMounted>
            </DetailsDialog>
            <DetailsDialog
                permissionKey="configuration_property"
                adding={selectedTv.id.length === 0}
                title={tvDialogTitle}
                open={tvDialogOpen}
                onCancelClick={handleTvCancelClick}
                onSaveClick={handleTvSave}
                onValidateForm={handleTvValidate}
                saveButtonLabel={strings.okButtonTitle}
                contentSize={{ width: 300, height: 160.91 }}
            >
                <Box sx={{ width: "100%", p: 2 }}>
                    <TextField
                        id="tv-id"
                        label={strings.name}
                        value= {selectedTv.name}
                        onChange={handleTVNameChange}
                        error={Boolean(tvErrors.name)}
                        helperText={tvErrors.name}
                        width={200}
                    />
                    <Spacer />
                    <Checkbox label={strings.preview} checked={selectedTv.showPreviewOnly} onChange={handleTvShowPreviewChange} />
                </Box>
            </DetailsDialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );
};

export default RoomList;
