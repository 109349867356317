import { useState, useEffect } from "react";
import Checkbox from "../../common/details/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { listPreferences } from "../../../api/restaurants/preferencesApi";
import { Preference } from "../../../models/modules/restaurants/Preference";
import { useUser } from "../../../providers/UserProvider";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { RestaurantPreference } from "../../../models/modules/restaurants/Restaurant";

interface PreferencesProps {
    preferences: RestaurantPreference[];
    onChange: (updatedPreferences: RestaurantPreference[]) => void;
    permissionKey: string;
}

function Preferences({preferences, onChange, permissionKey}: PreferencesProps) {
    const [preferenceList, setPreferenceList] = useState<Preference[]>([]);
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();
    const strings = useLocalizedStrings();
    
    useEffect(() => {
        const getList = async () => {
            const payload = await listPreferences(axiosInstance, user?.currentProperty?.code ?? "", true);
            setPreferenceList(payload);
        }

        getList();

    }, [ user?.currentProperty?.code])

    function handleCheckboxClick(id: string) {
        const currentIndex = preferences.findIndex(item => item.id === id);

        if (currentIndex === -1) {
            preferences.push({ id });
        }
        else {
            preferences.splice(currentIndex, 1);
        }

        onChange(preferences);
    }

    return (
        <Grid container>
            <Typography sx={{ textDecoration: "underline" }} variant="inherit">{strings.preferences}</Typography>
            <Grid container sx={{ display: "flex", height: 200, overflowX: "hidden" }}>
                <Grid item>
                    <List>
                        {preferenceList.map((preference) => (
                            <ListItem
                                disablePadding
                                key={preference.id}
                            >
                                <Checkbox
                                    checked={preferences.findIndex(item => item.id === preference.id) !== -1}
                                    onChange={() => handleCheckboxClick(preference.id)}
                                    id={preference.id}
                                    label=""
                                    permissionKey={permissionKey}
                                />
                                <ListItemText id={preference.id} primary={preference.name?.en ?? ""} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Preferences;