import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import * as React from "react";
import { useLocalizedStrings } from "../../../../localization/LocalizedStringsProvider";
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ContactsSent from "./ContactsSent";
import MenuItemViews from "./MenuItemViews";
import ItvSessions from "./ItvSessions";

const ReportingHome = () => {
    const strings = useLocalizedStrings();
    const location = useLocation();
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} aria-label="basic tabs example">
                    <Tab value="/reporting/itv/sessions" label={strings.reportingItvSessionsTabLabel} component={Link} to="sessions" />
                    <Tab value="/reporting/itv/menuitems" label={strings.reportingMenuItemViewsTabLabel} component={Link} to="menuitems" />
                    <Tab value="/reporting/itv/contacts" label={strings.reportingContactsSentTabLabel} component={Link} to="contacts" />
                </Tabs>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, padding: 0 }}>
                <Routes>
                    <Route path="sessions" element={<ItvSessions />} />
                    <Route path="menuitems" element={<MenuItemViews />} />
                    <Route path="contacts" element={<ContactsSent />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default ReportingHome;