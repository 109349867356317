import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import React from "react";
import { v4 as uuid } from "uuid";
import { deleteGraphics } from "../../../api/graphicsApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import {
    Book,
    initialBookState, initialItemState, initialSectionState, initialTopicState, Item, Section, Topic
} from "../../../models/modules/Information/Information";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import DisplayOrderGrid, { DisplayOrderGridRefObject } from "../../common/datatable/DisplayOrderGrid";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import Checkbox from "../../common/details/Checkbox";
import {
    createBook, createItem, createSection, createTopic, deleteBook, deleteOrRemoveItem, deleteOrRemoveSection, deleteOrRemoveTopic, getBookList,
    getBookWithChildren, getItemList, getSectionList, getTopicList, saveBookDisplayOrder, saveItemDisplayOrder, saveSectionDisplayOrder, saveTopicDisplayOrder, updateBook, updateItem, updateSection, updateTopic
} from "../../../api/informationApi";
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import axios from "axios";
import { deleteVideos } from "../../../api/videoApi";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { VideoModel } from "../../../models/common/VideoModel";
import { useUser } from "../../../providers/UserProvider";
import ContactOffer, { ContactOfferRefObject } from "../../common/contact/ContactOffer";
import VideoManager, { VideoManagerRefObject } from "../../common/video/VideoManager";

// Validation Errors
interface BookValidationErrors {
    name: string;
}

interface TopicValidationErrors {
    name: string;
}

interface SectionValidationErrors {
    name: string;
}

interface ItemValidationErrors {
    name: string;
    description: string;
}

const initialBookErrorState: BookValidationErrors = {
    name: ""
}

const initialTopicErrorState: TopicValidationErrors = {
    name: ""
}

const initialSectionErrorState: SectionValidationErrors = {
    name: ""
}

const initialItemErrorState: ItemValidationErrors = {
    name: "",
    description: ""
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function InformationList() {
    const theme = useTheme(); 
    const strings = useLocalizedStrings(); 

    const[loading, setLoading] = React.useState(true);

    const [bookRows, setBookRows] = React.useState<Book[]>([]);
    const [selectedBook, setSelectedBook] = React.useState<Book>(initialBookState);
    const [selectedTopic, setSelectedTopic] = React.useState<Topic>(initialTopicState);
    const [selectedSection, setSelectedSection] = React.useState<Section>(initialSectionState);
    const [selectedItem, setSelectedItem] = React.useState<Item>(initialItemState);

    // Existing collections
    const [existingTopics, setExistingTopics] = React.useState<Topic[]>([]);
    const [existingSections, setExistingSections] = React.useState<Section[]>([]);
    const [existingItems, setExistingItems] = React.useState<Item[]>([]);
    
    const [selectedBookId, setSelectedBookId] = React.useState("");
    const [selectedTopicId, setSelectedTopicId] = React.useState("");
    const [selectedSectionId, setSelectedSectionId] = React.useState("");
    const [selectedItemId, setSelectedItemId] = React.useState("");
    
    // Normal Dialog State
    const [bookDialogOpen, setBookDialogOpen] = React.useState(false);
    const [topicDialogOpen, setTopicDialogOpen] = React.useState(false);
    const [sectionDialogOpen, setSectionDialogOpen] = React.useState(false);
    const [itemDialogOpen, setItemDialogOpen] = React.useState(false);

    // new for contact: 
    const [bookTabValue, setBookTabValue] = React.useState<number>(0);
    const [topicItemTabValue, setTopicItemTabValue] = React.useState<number>(0);
    const [itemTabValue, setItemTabValue] = React.useState(0);
    const graphicManagerItemRef = React.useRef<GraphicManagerRefObject>(null);
    const graphicManagerTopicRef = React.useRef<GraphicManagerRefObject>(null);
    const videoManagerItemRef = React.useRef<VideoManagerRefObject>(null);

    // new for contact: 
    const bookContactRef = React.useRef<ContactOfferRefObject>(null);
    const topicContactRef = React.useRef<ContactOfferRefObject>(null);
    const itemContactRef = React.useRef<ContactOfferRefObject>(null);

    // Validation State
    const [bookErrors, setBookErrors] = React.useState<BookValidationErrors>(initialBookErrorState);
    const [topicErrors, setTopicErrors] = React.useState<TopicValidationErrors>(initialTopicErrorState);
    const [sectionErrors, setSectionErrors] = React.useState<SectionValidationErrors>(initialSectionErrorState);
    const [itemErrors, setItemErrors] = React.useState<ItemValidationErrors>(initialItemErrorState);

    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);

    // Menu Anchors
    const [addTopicMenuAnchor, setAddTopicMenuAnchor] = React.useState<null | HTMLElement>(null);
    const addTopicMenuOpen = Boolean(addTopicMenuAnchor);
    const [deleteTopicMenuAnchorEl, setDeleteTopicMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const deleteTopicMenuOpen = Boolean(deleteTopicMenuAnchorEl);

    const [addSectionMenuAnchor, setAddSectionMenuAnchor] = React.useState<null | HTMLElement>(null);
    const addSectionMenuOpen = Boolean(addSectionMenuAnchor);
    const [deleteSectionMenuAnchorEl, setDeleteSectionMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const deleteSectionMenuOpen = Boolean(deleteSectionMenuAnchorEl);

    const [addItemMenuAnchor, setAddItemMenuAnchor] = React.useState<null | HTMLElement>(null);
    const addItemMenuOpen = Boolean(addItemMenuAnchor);
    const [deleteItemMenuAnchorEl, setDeleteItemMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const deleteItemMenuOpen = Boolean(deleteItemMenuAnchorEl);

    const [bookRefresh, setBookRefresh] = React.useState(false);
    const [bookLoading, setBookLoading] = React.useState(false);

    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const {user} = useUser();

    const topicGridRef = React.useRef<DisplayOrderGridRefObject>(null);
    const sectionGridRef = React.useRef<DisplayOrderGridRefObject>(null);
    const itemGridRef = React.useRef<DisplayOrderGridRefObject>(null);

    type RowType = 'Book' | 'Topic' | 'Section' | 'Item'

    React.useEffect(() => {
        const getInitialData = async() => {
            
            clearRows('Book');

            try {
                const response = await getBookList(axiosInstance, user?.currentProperty?.code ?? "");
                const booksWithTopics = response.map(b => {
                    return {
                        ...b,
                        topics: []
                    }
                })
                setBookRows(booksWithTopics);

                const topicResponse = await getTopicList(axiosInstance, user?.currentProperty?.code ?? "");
                setExistingTopics(topicResponse);
                
                const sectionResponse = await getSectionList(axiosInstance, user?.currentProperty?.code ?? ""); 
                setExistingSections(sectionResponse);
               
                const itemResponse = await getItemList(axiosInstance, user?.currentProperty?.code ?? "");
                setExistingItems(itemResponse);

                // This should trigger an update of the selected topic/section/item
                if (selectedBookId !== "") {
                    setSelectedBookId(selectedBookId);
                }
            }
            catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorRetreivingBooks.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

                return;
            }
            finally {
                setLoading(false);
            }
        }

        getInitialData();
    }, [user.currentProperty?.code])

    // clear children at each level when parent changes
    React.useEffect(() => {
        clearRows('Topic');

        if (selectedBookId.length > 0) {
            getBook();
        }
        
    }, [selectedBookId])

    React.useEffect(() => {
        if (selectedBookId.length > 0) {
            getBook();
        } 
        
    }, [bookRefresh])

    React.useEffect(() => {
        clearRows('Section');
    }, [selectedTopicId])

    React.useEffect(() => {
        clearRows('Item')
    }, [selectedSectionId])

    const getBook = React.useCallback( async () => {
        if (selectedBookId.length === 0) return; 

        try {
            setBookLoading(true);
            const data = await getBookWithChildren(axiosInstance, user?.currentProperty?.code ?? "", selectedBookId);
            var topics = data.topics;

            // Sort topics, sections, and items 1st by display order, then by name (en for now)
            topics.forEach(t => {
                t.sections.forEach(s => {
                    s.items.sort((a, b) => a.displayOrder - b.displayOrder || (a.name && b.name ? a.name.en.localeCompare(b.name.en) : 0));
                });
                t.sections.sort((a, b) => a.displayOrder - b.displayOrder || (a.name && b.name ? a.name.en.localeCompare(b.name.en) : 0));
            });
            topics.sort((a, b) => a.displayOrder - b.displayOrder || (a.name && b.name ? a.name.en.localeCompare(b.name.en) : 0));

            setSelectedBookId(data.id);
            setSelectedBook(data);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.generalInfoErrorRetreivingBooks.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return;
        }
        finally {
            setBookLoading(false);
        }
    
    }, [selectedBookId, user?.currentProperty?.code])
   
    function handleBookCanPerformAction() {
        return !topicGridRef.current?.isDirty() && !sectionGridRef.current?.isDirty() && !itemGridRef.current?.isDirty();
    }

    function handleTopicCanPerformAction() {
        return !sectionGridRef.current?.isDirty() && !itemGridRef.current?.isDirty()
    }

    function handleSectionCanPerformAction() {
        return !itemGridRef.current?.isDirty()
    }

    function handleBookSelectionChange(bookSelectionModel: GridRowSelectionModel) {
        if (bookSelectionModel.length > 0) {
            selectRow(bookSelectionModel[0].toString(), 'Book');
        }
    }    

    function handleTopicSelectionChange (topicSelectionModel: GridRowSelectionModel) {
        if (topicSelectionModel.length > 0 && !sectionGridRef.current?.isDirty() && !itemGridRef.current?.isDirty()) {
            selectRow(topicSelectionModel[0].toString(), 'Topic');
        }
    };    

    function handleSectionSelectionChange (sectionSelectionModel: GridRowSelectionModel) {
        if (sectionSelectionModel.length > 0) {
            selectRow(sectionSelectionModel[0].toString(), 'Section');
        }
    };

    function handleItemSelectionChange(itemSelectionModel: GridRowSelectionModel) {
        if (itemSelectionModel.length > 0) {
            selectRow(itemSelectionModel[0].toString(), 'Item');
        }
    }

    function selectRow(id: string, rowType: RowType, openDialogOnSelect: boolean= false) { 
        switch (rowType) {
            case 'Book': {
                setSelectedBookId(id);
                setBookDialogOpen(openDialogOnSelect);

                break;
            }
            case 'Topic': {
                setSelectedTopicId(id);

                const topic = selectedBook?.topics.find(t => t.id === id);
                if (topic) {
                    setSelectedTopic(topic);
                    setTopicDialogOpen(openDialogOnSelect);
                }

                break;
            }
            case 'Section': {
                setSelectedSectionId(id); 

                const section = selectedBook?.topics?.find(t => t.id === selectedTopicId)?.sections.find(sec => sec.id === id);
                if (section) {
                    setSelectedSection(section);
                    setSectionDialogOpen(openDialogOnSelect);
                }

                break;
            }
            case 'Item': {
                setSelectedItemId(id);
               
                const item = selectedBook
                    ?.topics?.find(t => t.id === selectedTopicId)
                    ?.sections?.find(sec => sec.id === selectedSectionId)
                    ?.items.find(i => i.id === id);

                if (item) {
                    setSelectedItem(item);
                    setItemDialogOpen(openDialogOnSelect);
                }

                break;
            }
            default: {

            }
        }
    }

    function clearRows(type: RowType) {
        switch (type) {
            case 'Book': {
                setSelectedBookId("");
                setSelectedBook(initialBookState)

                setSelectedTopicId("");
                setSelectedTopic(initialTopicState);

                setSelectedSectionId("");
                setSelectedSection(initialSectionState);

                setSelectedItemId("");
                setSelectedItem(initialItemState);
                break;
            }
            case 'Topic': {
                setSelectedTopicId("");
                setSelectedTopic(initialTopicState);

                setSelectedSectionId("");
                setSelectedSection(initialSectionState);

                setSelectedItemId("");
                setSelectedItem(initialItemState);

                break;
            }
            case 'Section': {
                setSelectedSectionId("");
                setSelectedSection(initialSectionState);

                setSelectedItemId("");
                setSelectedItem(initialItemState);

                break;
            }
            case 'Item': {
                setSelectedItem(initialItemState);
                setSelectedItemId("")

                break;
            }
            default: {

            }
        }
    }
    
    // BOOK
    function handleBookTabChange(event: React.SyntheticEvent, newValue: number) {
        setBookTabValue(newValue);
    }
    function handleBookNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedBook((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }

    function handleAddBookClick() {        
        clearRows('Book');
        setBookDialogOpen(true);        
    }

    function handleEditBookClick(id: string) {
        if (!handleBookCanPerformAction()) {
            return;
        }

        selectRow(id, 'Book', true);
    }

    async function deleteSelectedBook(bookId: string) {        
        try {            
            await deleteBook(axiosInstance, user?.currentProperty?.code ?? "", bookId);
                
            setBookRows(prevState => ([
                ...prevState.filter(book => book.id !== bookId)
            ]))
    
            // clear selections and all child grids just like add new
            clearRows('Book');
        }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorDeletingBook.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.generalInfoBookDeletedSuccessfully,
            msgType: "success",
        });
    }

    function handleDeleteBookClick(id: string) {
        if (!handleBookCanPerformAction()) {
            return;
        }

        selectRow(id, 'Book');
        
        const name = bookRows.find(i => i.id === id)?.name?.en ?? ""; 
        alertDialog({
            title: strings.generalInfoDeleteBookAlertTitle, 
            message: strings.generalInfoDeleteBookAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedBook(id);
        });
    }    

    function handleBookCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setBookErrors (initialBookErrorState);
        setBookTabValue(0);
        setBookDialogOpen(false);        
        setBookRefresh(!bookRefresh); // works but totally not ideal
    }

    async function handleBookSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setBookErrors(initialBookErrorState);
        let newId = "";
        let newBook: Book; 

        const isUpdate = selectedBookId !== "";
        try {
            if (isUpdate) {
                const result = await updateBook(axiosInstance, user?.currentProperty?.code ?? "", selectedBook); 
            
                if (result.status === 200) {
                    setBookRows(prevState => ([
                        ...prevState.map(book => {
                            return book.id === selectedBookId
                            ? {...book, name: selectedBook.name}
                            : book
                        })
                    ]))
                }
            }
            else {
                newId = uuid();
                newBook = { ...selectedBook, id: newId }

                const result = await createBook(axiosInstance, user?.currentProperty?.code ?? "", newBook); 
        
                if (result.status === 200) {
                    setBookRows(prevState => {
                        return [
                         ...prevState,
                         newBook
                        ]
                     })
                }
            }
            
            // save contact offer data for book
            bookContactRef?.current?.saveOffer({ key: isUpdate ? selectedBookId : newId })
        } 
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorUpdatingBook.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            return false;
        }

        

        //setSelectedBook(initialBookState) 
        setBookTabValue(0);
        setBookDialogOpen(false);
        setNotify({
            isOpen: true,
            message: strings.generalInfoBookSavedSuccessfully,
            msgType: "success", 
         });

        return true;
    }

    function handleValidateBook() {
        let errors = validate<Book, BookValidationErrors>([{
            property: "name.en", type: ValidationType.Required, message: strings.generalInfoValidationBookName
        }
        ], selectedBook);

        if (errors) {
            setBookErrors(errors);
            return false;
        }

        if (!bookContactRef?.current?.validateOffer()) {
            setBookTabValue(1);
            return false;       
        }

        return true;
    }
    
    function updateBookOrder(books: Book[]) {
        const idList = books.map(book => book.id);
        saveBookDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", idList)
            .then(() => setBookRows(books))
            .catch((error: unknown) => {
                
                    
                    
                setNotify({
                    isOpen: true,
                    message: strings.errorUpdatingDisplayOder.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            })
    }

    // TOPIC
    function handleAddTopicButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (getFilteredTopics().length > 0) {
            setAddTopicMenuAnchor(event.currentTarget);
        }
        else {
            handleAddNewTopicMenuClick();
        }
    }

    function handleAddTopicMenuClose() {
        setAddTopicMenuAnchor(null);
    }
    
    function handleAddNewTopicMenuClick() {
        clearRows('Topic');
        setAddTopicMenuAnchor(null);
        setTopicDialogOpen(true);
    }

    function handleAddExistingTopicClick(id: string) {
        const topic = existingTopics.find(t => t.id === id);
        if (topic) {
             setSelectedTopic(topic); 
             saveExistingTopic(topic);
        }    
        setAddTopicMenuAnchor(null);
    }

    function handleDeleteTopicMenuClose() {
        setDeleteTopicMenuAnchorEl(null);
    }
    
    function handleRemoveTopic() {
        setDeleteTopicMenuAnchorEl(null);

        const name = selectedBook?.topics?.find(i => i.id === selectedTopicId)?.name?.en ?? ""; 
        alertDialog({
            title: strings.generalInfoRemoveTopicAlertTitle,
            message: strings.generalInfoRemoveTopicAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.removeButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedTopic(selectedTopicId, selectedBookId)
        });
    }

    async function deleteSelectedTopic(topicId: string, bookId?:string) {
        const images: GraphicModel[] = [{ imageKey: "main", url: "", fileData: null }];

        const isDelete = !bookId; 
        try {
            if (!isDelete) {
                await deleteOrRemoveTopic(axiosInstance, user?.currentProperty?.code ?? "", topicId, bookId); // REMOVE
            }
            else {
                await deleteGraphics(axiosInstance, "informationTopic", selectedTopicId, images, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
                await deleteOrRemoveTopic(axiosInstance, user?.currentProperty?.code ?? "", topicId) // DELETE
                setExistingTopics(old => {
                    return [
                        ...old.filter(topic => topic.id !== topicId)
                    ]
                })
            }

            setBookRefresh(!bookRefresh);
            clearRows('Topic');
        }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorDeletingTopic.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            return;
        }

        setNotify({
            isOpen: true,
            message: isDelete ? strings.generalInfoTopicDeletedSuccessfully : strings.generalInfoTopicRemovedSuccessfully,
            msgType: "success",
        });
    }

    function handleDeleteTopic() {        
        setDeleteTopicMenuAnchorEl(null);

        const name = selectedBook?.topics?.find(i => i.id === selectedTopicId)?.name?.en ?? ""; 
        alertDialog({
            title: strings.generalInfoDeleteTopicAlertTitle,
            message: strings.generalInfoDeleteTopicAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedTopic(selectedTopicId); 
        });
    }

    function handleTopicItemTabChange(event: React.SyntheticEvent, newValue: number) {
        setTopicItemTabValue(newValue);
    }
    
    function handleTopicNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedTopic((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }

    function handleEditTopicClick(id: string) {
        if (topicGridRef.current?.isDirty() || !handleTopicCanPerformAction()) {
            return;
        }

        selectRow(id, 'Topic', true);
    }

    function handleTopicDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (topicGridRef.current?.isDirty() || !handleTopicCanPerformAction()) {
            return;
        }

        selectRow(id, 'Topic');
        setDeleteTopicMenuAnchorEl(event.currentTarget);
    }

    function handleTopicCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setTopicErrors(initialTopicErrorState);
        setTopicItemTabValue(0);
        setTopicDialogOpen(false);
    }

    async function handleTopicSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setTopicErrors(initialTopicErrorState);
        let newId = ""; 
        let newTopic : Topic; 

        const isUpdate = selectedTopicId.length > 0; 
        try {
            if (isUpdate) {
                await updateTopic(axiosInstance, user?.currentProperty?.code ?? "", selectedTopic);
            }
            else {
                newId = uuid();
                newTopic = {...selectedTopic, id: newId};
                
                await createTopic(axiosInstance, user?.currentProperty?.code ?? "", selectedBookId, newTopic);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.generalInfoErrorAddingTopic
                    : strings.generalInfoErrorUpdatingTopic
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }

        // graphics:
        try {
            if (graphicManagerTopicRef.current) {
                const id = isUpdate ? selectedTopicId : newId
                await graphicManagerTopicRef.current.saveGraphics(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorSavingGraphic.replace("{{error}}", (error as Error).message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }
        
        // save contact for topic level
        topicContactRef?.current?.saveOffer({ key: selectedBookId, parentId: isUpdate ? selectedTopicId : newId });

        setExistingTopics(old => {
            return !isUpdate 
                ? [...old, newTopic]
                : [
                    ...old.map(topic => {
                        return topic.id === selectedTopicId 
                            ? {...topic, name: selectedTopic.name}
                            : topic 
                    })
                ]
        });

        setBookRefresh(!bookRefresh);
        setTopicItemTabValue(0);
        setTopicDialogOpen(false);
        setNotify({
            isOpen: true,
            message: strings.generalInfoTopicSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateTopic() {
        let errors = validate<Topic, TopicValidationErrors>([{
            property: "name.en", type: ValidationType.Required, message: strings.generalInfoValidationTopicName
        }
        ], selectedTopic);

        if (errors) {
            setTopicErrors(errors);
            return false;
        }

        if (!topicContactRef?.current?.validateOffer()) {
            setTopicItemTabValue(2);
            return false;       
        }

        return true;
    }

    async function saveExistingTopic(topic: Topic) {
        try {
            await createTopic(axiosInstance, user?.currentProperty?.code ?? "", selectedBookId, topic);
        }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorUpdatingTopic.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            setSelectedTopic(initialTopicState);
            return false;
        }
        
        setBookRefresh(!bookRefresh);
        setNotify({
            isOpen: true,
            message: strings.generalInfoTopicSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    /*function handleTopicSaveDisplayOrder(ids: any[], alphabetical?: boolean) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const topics = [...selectedBook.topics];
        topics.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateTopicOrder(topics, alphabetical);        
    }

    function updateTopicOrder(topicList: Topic[], alphabetical?: boolean) {*/
    function handleTopicSaveDisplayOrder(idList: any[], alphabetical?: boolean) {
        //const idList = topicList.map(topic => topic.id);
        saveTopicDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", idList, selectedBookId, alphabetical)
            .then(() => setBookRefresh(!bookRefresh))
            .catch((error: unknown) => {
                
                    
                    
                setNotify({
                    isOpen: true,
                    message: strings.errorUpdatingDisplayOder.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            })
    }
    /*
    function handleTopicMoveUpClick(id: string) {        
        //const index = bookRows.findIndex(b => b.id === selectedBookId);
        //if (index !== -1) {
        //    var newRows = [...bookRows];
        //    newRows[index].topics = moveUp(selectedBook?.topics, id, 1);
        //    setBookRows(newRows);
        //}    
        setTopicDisplayOrderIsDirty(true);
        setSelectedBook((prevState) => ({
            ...prevState,
            topics: moveUp(selectedBook?.topics, id, 1)
        }));
    }

    function handleTopicMoveDownClick(id: string) {
        //const index = bookRows.findIndex(b => b.id === selectedBookId);
        //if (index !== -1) {
        //    var newRows = [...bookRows];
        //    newRows[index].topics = moveDown(selectedBook?.topics, id, 1);
        //    setBookRows(newRows);
        //}   
        setTopicDisplayOrderIsDirty(true);
        setSelectedBook((prevState) => ({
            ...prevState,
            topics: moveDown(selectedBook?.topics, id, 1)
        }));
    }

    function handleTopicMoveTopClick(id: string) {
        //const index = bookRows.findIndex(b => b.id === selectedBookId);
        //if (index !== -1) {
        //    var newRows = [...bookRows];
        //    newRows[index].topics = moveTop(selectedBook?.topics, id);
        //    setBookRows(newRows);
        //}
        setTopicDisplayOrderIsDirty(true);
        setSelectedBook((prevState) => ({
            ...prevState,
            topics: moveTop(selectedBook?.topics, id)
        }));
    }

    function handleTopicMoveBottomClick(id: string) {
        //const index = bookRows.findIndex(b => b.id === selectedBookId);
        //if (index !== -1) {
        //    var newRows = [...bookRows];
        //    newRows[index].topics = moveBottom(selectedBook?.topics, id);
        //    setBookRows(newRows);
        //}
        setTopicDisplayOrderIsDirty(true);
        setSelectedBook((prevState) => ({
            ...prevState,
            topics: moveBottom(selectedBook?.topics, id)
        }));
    }

    function getDisplayOrderForTopic(id: string) {
        let index = selectedBook?.topics.findIndex(topic => topic.id === id);
        return index;
    }
    */

    // SECTION
    function handleAddSectionButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (getFilteredSections().length > 0) {
            setAddSectionMenuAnchor(event.currentTarget);
        }
        else {
            handleAddNewSectionMenuClick();
        }
    }

    function handleAddSectionMenuClose() {        
        setAddSectionMenuAnchor(null);
    }

    function handleAddNewSectionMenuClick() {
        clearRows('Section');
        setAddSectionMenuAnchor(null);
        setSectionDialogOpen(true);
    }    

    function handleAddExistingSectionClick(id: string) {
        const section = existingSections.find(t => t.id === id);
        if (section) {
             setSelectedSection(section); 
             saveExistingSection(section);
        }   
        setAddSectionMenuAnchor(null);
    }

    function handleSectionNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedSection((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }

    function handleSectionCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSectionErrors (initialSectionErrorState)
        setSectionDialogOpen(false);
    }

    function handleEditSectionClick(id: string) {
        if (sectionGridRef.current?.isDirty() || !handleSectionCanPerformAction()) {
            return;
        }

        selectRow(id, 'Section', true);
    }

    function handleValidateSection() {
        let errors = validate<Section, SectionValidationErrors>([{
            property: "name.en", type: ValidationType.Required, message: strings.generalInfoValidationSectionName
        }
        ], selectedSection);

        if (errors) {
            setSectionErrors(errors);
            return false;
        }

        return true;
    }
    
    /*function handleSectionSaveDisplayOrder(ids: any[], alphabetical?: boolean) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const sections = [...selectedTopic.sections];
        sections.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateSectionOrder(sections, alphabetical);        
    }

    function updateSectionOrder(sectionList: Section[], alphabetical?: boolean) {*/
    function handleSectionSaveDisplayOrder(idList: any[], alphabetical?: boolean) {
        //const idList = sectionList.map(section => section.id);
        saveSectionDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", idList, selectedTopicId, alphabetical)
            .then(() => setBookRefresh(!bookRefresh))
            .catch((error: unknown) => {
                
                    
                    
                setNotify({
                    isOpen: true,
                    message: strings.errorUpdatingDisplayOder.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            })
    }

    function handleDeleteSectionMenuClose() {
        setDeleteSectionMenuAnchorEl(null);
    }

    function handleSectionDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (sectionGridRef.current?.isDirty() || !handleSectionCanPerformAction()) {
            return;
        }

        selectRow(id, 'Section');
        setDeleteSectionMenuAnchorEl(event.currentTarget);
    }

    function handleRemoveSection() {
        setDeleteSectionMenuAnchorEl(null);
        
        const name = selectedBook?.topics?.find(t => t.id === selectedTopicId)?.sections?.find(s => s.id === selectedSectionId)?.name?.en ?? "";
        alertDialog({
            title: strings.generalInfoRemoveSectionAlertTitle,
            message: strings.generalInfoRemoveSectionAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.removeButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedSection(selectedSectionId, selectedTopicId); 
        });
    }

    async function deleteSelectedSection(sectionId: string, topicId?:string) {
        const isDelete = !topicId;

        try {
            if (isDelete) {
                await deleteOrRemoveSection(axiosInstance, user?.currentProperty?.code ?? "", sectionId); // DELETE
                setExistingSections(old => {
                        return [
                            ...old.filter(section => section.id !== sectionId) 
                        ]
                    })
            }
            else {
                await deleteOrRemoveSection(axiosInstance, user?.currentProperty?.code ?? "", sectionId, topicId); // REMOVE
            }

            setBookRefresh(!bookRefresh);
            clearRows('Section');
        }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorDeletingSection.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            return;
        }

        setNotify({
            isOpen: true,
            message: isDelete ? strings.generalInfoSectionDeletedSuccessfully : strings.generalInfoSectionRemovedSuccessfully,
            msgType: "success",
        });
    }

    function handleDeleteSection() {        
        setDeleteSectionMenuAnchorEl(null);
        
        const name = selectedBook?.topics?.find(t => t.id === selectedTopicId)?.sections.find(s => s.id === selectedSectionId)?.name?.en ?? "";
        alertDialog({
            title: strings.generalInfoDeleteSectionAlertTitle,
            message: strings.generalInfoDeleteSectionAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedSection(selectedSectionId); 
        });
    }

    async function handleSectionSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSectionErrors(initialSectionErrorState);
        let newId = ""; 
        let newSection : Section; 

        const isUpdate = selectedSectionId.length > 0; 
        try {
            if (isUpdate) {
                await updateSection(axiosInstance, user?.currentProperty?.code ?? "", selectedSection);
            }
            else {
                newId = uuid();
                newSection = {...selectedSection, id: newId};
               
                await createSection(axiosInstance, user?.currentProperty?.code ?? "", selectedTopicId, newSection);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.generalInfoErrorAddingSection
                    : strings.generalInfoErrorUpdatingSection
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }

        setExistingSections(old => {
            return !isUpdate 
                ? [...old, newSection]
                : [...old.map(section => {
                        return section.id === selectedSectionId 
                            ? {...section, name: selectedSection.name}
                            : section 
                    })
                ]
        });

        setBookRefresh(!bookRefresh);
        setSectionDialogOpen(false);
        setNotify({
            isOpen: true,
            message: strings.generalInfoSectionSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    async function saveExistingSection(section: Section) {
        try {
            await createSection(axiosInstance, user?.currentProperty?.code ?? "", selectedTopicId, section);
        }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorUpdatingSection.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            setSelectedSection(initialSectionState);
            return false;
        }
        
        setBookRefresh(!bookRefresh);
        setNotify({
            isOpen: true,
            message: strings.generalInfoSectionSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    // ITEM
    function handleAddItemButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (getFilteredItems().length > 0) {
            setAddItemMenuAnchor(event.currentTarget);
        }
        else {
            handleAddNewItemMenuClick()
        }
    }

    function handleAddItemMenuClose() {
        setAddItemMenuAnchor(null);
    }

    function handleAddNewItemMenuClick() {
        clearRows('Item');
        setAddItemMenuAnchor(null);
        setItemDialogOpen(true);
    }    

    function handleAddExistingItemClick(id: string) {
        const item = existingItems.find(t => t.id === id);
        if (item) {
             setSelectedItem(item); 
             saveExistingItem(item);
        }
        setAddItemMenuAnchor(null);
    }

    function handleItemTabChange(event: React.SyntheticEvent, newValue: number) {
        setItemTabValue(newValue);
    }

    function handleItemNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedItem((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }

    function handleItemCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setItemErrors (initialItemErrorState);
        setItemTabValue(0);
        setItemDialogOpen(false);
    }

    function handleEditItemClick(id: string) {
        if (itemGridRef.current?.isDirty()) {
            return;
        }

        selectRow(id, 'Item', true);
    }
    
    function handleItemDescriptionChange(content: string, language: string) {
        setSelectedItem((prevState) => ({
            ...prevState,
            description: {
                ...prevState.description,
                [language]: content,
            },
        }));
    }

    function handleItemPreviewOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedItem((prevState) => ({
            ...prevState,
            previewOnly: event.target.checked,
        }));
    }

    async function handleItemSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setItemErrors(initialItemErrorState);
        let newId = ""; 
        let newItem : Item; 

        const isUpdate = selectedItemId.length > 0; 
        try {
            if (isUpdate) {
                await updateItem(axiosInstance, user?.currentProperty?.code ?? "", selectedItem);
            }
            else {
                newId = uuid();
                newItem = {...selectedItem, id: newId};
                await createItem(axiosInstance, user?.currentProperty?.code ?? "", selectedSectionId, newItem);
                // setSelectedItemId(newId) // possibly want to select it in the grid
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.generalInfoErrorAddingItem
                    : strings.generalInfoErrorUpdatingItem
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }

        // graphics:
        try {
            if (graphicManagerItemRef.current) {
                const id = isUpdate ? selectedItemId : newId
                await graphicManagerItemRef.current.saveGraphics(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorSavingGraphic.replace("{{error}}", (error as Error).message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        try {
            if (videoManagerItemRef.current) {
                const id = isUpdate ? selectedItemId : newId
                await videoManagerItemRef.current.saveVideos(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorSavingVideo.replace("{{error}}", (error as Error).message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        // save contact info at item level: 
        itemContactRef?.current?.saveOffer({ key: selectedBookId, parentId: selectedTopicId, itemId: isUpdate ? selectedItemId : newId }); 

        setExistingItems(old => {
            return !isUpdate 
                ? [...old, newItem]
                : [
                    ...old.map(item => {
                        return item.id === selectedItemId 
                            ? {...item, name: selectedItem.name, description: selectedItem.description}
                            : item 
                    })
                ]
        });

        setBookRefresh(!bookRefresh);
        setItemTabValue(0);
        setItemDialogOpen(false);
        setNotify({
            isOpen: true,
            message: strings.generalInfoItemSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateItem() {
        let errors = validate<Item, ItemValidationErrors>([{
            property: "name.en", type: ValidationType.Required, message: strings.generalInfoValidationItemName,
        },
        {
            property: "description.en", type: ValidationType.Required, message: strings.generalInfoValidationItemDescription,
        }
        ], selectedItem);

        if (errors) {
            setItemErrors(errors);
            return false;
        }

        if (!itemContactRef?.current?.validateOffer()) {
            setItemTabValue(2);
            return false;       
        }

        return true;
    }

    /*function handleItemSaveDisplayOrder(ids: any[], alphabetical?: boolean) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const items = [...selectedSection.items];
        items.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateItemOrder(items, alphabetical);        
    }

    function updateItemOrder(itemList: Item[], alphabetical?: boolean) {*/
    function handleItemSaveDisplayOrder(idList: any[], alphabetical?: boolean) {
        //const idList = itemList.map(item => item.id);
        saveItemDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", idList, selectedSectionId, alphabetical)
            .then(() => setBookRefresh(!bookRefresh))
            .catch((error: unknown) => {
                
                    
                    
                setNotify({
                    isOpen: true,
                    message: strings.errorUpdatingDisplayOder.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            })
    }

    function handleDeleteItemMenuClose() {
        setDeleteItemMenuAnchorEl(null);
    }

    function handleItemDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (itemGridRef.current?.isDirty()) {
            return;
        }

        selectRow(id, 'Item');
        setDeleteItemMenuAnchorEl(event.currentTarget);
    }

    async function deleteSelectedItem(itemId: string, sectionId?:string) {
        const images: GraphicModel[] = [{ imageKey: "main", url: "", fileData: null }];
        const videos: VideoModel[] = [
            { videoKey: "main", url: "", fileData: null },
            { videoKey: "banner", url: "", fileData: null },
        ];

        const isDelete = !sectionId;
        try {
            if (!isDelete) {
                await deleteOrRemoveItem(axiosInstance, user?.currentProperty?.code ?? "", itemId, sectionId); // REMOVE
            }
            else {
                await deleteGraphics(axiosInstance, "informationItem", selectedItemId, images, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
                await deleteVideos(axiosInstance, "informationItem", selectedItemId, videos, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
                await deleteOrRemoveItem(axiosInstance, user?.currentProperty?.code ?? "", itemId); // DELETE

                setExistingItems(old => {
                    return [
                        ...old.filter(item => item.id !== itemId)
                    ]
                })
            }

            setBookRefresh(!bookRefresh);
            clearRows('Item');
        }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorDeletingItem.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            return;
        }

        setNotify({
            isOpen: true,
            message: isDelete ? strings.generalInfoItemDeletedSuccessfully : strings.generalInfoItemRemovedSuccessfully,
            msgType: "success",
        });
    }

    function handleRemoveItem() {
        setDeleteItemMenuAnchorEl(null);

        const name = selectedBook.topics?.
            find(t => t.id === selectedTopicId)?.sections.
            find(s => s.id === selectedSectionId)?.items.
            find(i => i.id === selectedItemId)?.name?.en ?? "";
            
        alertDialog({
            title: strings.generalInfoRemoveItemAlertTitle,
            message: strings.generalInfoRemoveItemAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.removeButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedItem(selectedItemId, selectedSectionId); 
        });
    }

    function handleDeleteItem() {        
        setDeleteItemMenuAnchorEl(null);

        const name = selectedBook.topics?.
            find(t => t.id === selectedTopicId)?.sections.
            find(s => s.id === selectedSectionId)?.items.
            find(i => i.id === selectedItemId)?.name?.en ?? "";
            
        alertDialog({
            title: strings.generalInfoDeleteItemAlertTitle,
            message: strings.generalInfoDeleteItemAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedItem(selectedItemId); 
        });
    }    

    async function saveExistingItem(item: Item) {
        try {
            await createItem(axiosInstance, user?.currentProperty?.code ?? "", selectedSectionId, item);
        }
        catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.generalInfoErrorUpdatingItem.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

            setSelectedItem(initialItemState);
            return false;
        }
        
        setBookRefresh(!bookRefresh);
        setNotify({
            isOpen: true,
            message: strings.generalInfoItemSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }
    
    const bookDialogTitle = selectedBookId === "" ? strings.generalInfoAddBookTitle : strings.generalInfoEditBookTitle.replace("{{name}}", selectedBook?.name?.en ?? ""); // TODO: default language
    const topicDialogTitle = selectedTopicId === "" ? strings.generalInfoAddTopicTitle : strings.generalInfoEditTopicTitle.replace("{{name}}", selectedTopic?.name?.en ?? ""); // TODO: default language
    const sectionDialogTitle = selectedSectionId === "" ? strings.generalInfoAddSectionTitle : strings.generalInfoEditSectionTitle.replace("{{name}}", selectedSection?.name?.en ?? ""); // TODO: default language
    const itemDialogTitle = selectedItemId === "" ? strings.generalInfoAddItemTitle : strings.generalInfoEditItemTitle.replace("{{name}}", selectedItem?.name?.en ?? ""); // TODO: default language
    
    // Grid Columns:
    const bookColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, flex: 2, valueGetter: (value, row) => row.name.en },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_information"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditBookClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_information"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteBookClick(params.id.toString())}
                />                
            ],
        }
    ]

    const topicColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, flex: 2, valueGetter: (value, row) => row.name.en },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_information"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditTopicClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_information"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleTopicDeleteClick(params.id.toString(), event)}
                />
            ],
        }
    ]

    const sectionColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, valueGetter: (value, row) => row.name.en, flex: 2 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_information"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditSectionClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_information"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleSectionDeleteClick(params.id.toString(), event)}
                />,
            ],
        }
    ]

    const itemColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, valueGetter: (value, row) => row.name.en, flex: 2 },
        { field: "previewOnly", headerName: strings.previewOnly, type: "boolean", valueGetter: (value, row) => row.previewOnly, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_information"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditItemClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_information"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleItemDeleteClick(params.id.toString(), event)}
                />,
            ],
        }
    ]

    function getTopicRows() {
        return selectedBook?.topics ?? [];
    }

    function getSectionRows() {
        //return selectedBook?.topics?.find(t => t.id === selectedTopicId)?.sections ?? [];
        return getTopicRows().find(t => t.id === selectedTopicId)?.sections ?? [];
    }

    function getItemRows() {
        //return selectedBook?.topics?.find(t => t.id === selectedTopicId)?.sections?.find(sec => sec.id === selectedSectionId)?.items ?? [];
        return getSectionRows().find(sec => sec.id === selectedSectionId)?.items ?? [];
    }

    function getFilteredTopics() {
        const selectedIds = getTopicRows().map(t => t.id);
        return  existingTopics.filter(t => !selectedIds.includes(t.id));
    }

    function getFilteredSections() {
        const selectedIds = getSectionRows().map(s => s.id);
        return  existingSections.filter(s => !selectedIds.includes(s.id));
    }

    function getFilteredItems() {
        const selectedIds = getItemRows().map(i => i.id);
        return  existingItems.filter(i => !selectedIds.includes(i.id));
    }

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 107px)" }}>
            {/* Topic Menus */}
            <Menu
                id="addTopicMenu"
                MenuListProps={{
                    'aria-labelledby': 'topicAdd',
                }}
                anchorEl={addTopicMenuAnchor}
                open={addTopicMenuOpen}
                onClose={handleAddTopicMenuClose}
            >                
                <MenuItem onClick={handleAddNewTopicMenuClick}>{strings.generalInfoAddTopicButton}</MenuItem>                    
                {getFilteredTopics().map((topic, index) => (
                    <>
                        {index === 0 && <Divider />}
                        <MenuItem key={topic.id} value={topic.id} onClick={() => handleAddExistingTopicClick(topic.id)}>                            
                            <ListItemText>
                                {topic.name?.en ?? ""}
                            </ListItemText>
                        </MenuItem>
                    </>                        
                ))}   
            </Menu>
            <Menu
                id="deleteTopicMenu"
                MenuListProps={{
                    'aria-labelledby': 'buttonDeleteTopic',
                }}
                anchorEl={deleteTopicMenuAnchorEl}
                open={deleteTopicMenuOpen}
                onClose={handleDeleteTopicMenuClose}
            >
                <MenuItem onClick={handleRemoveTopic}>{strings.removeButtonTitle}</MenuItem>
                <MenuItem onClick={handleDeleteTopic}>{strings.deleteButtonTitle}</MenuItem>
            </Menu>

            {/* Section Menus */}
            <Menu
                id="addSectionMenu"
                MenuListProps={{
                    'aria-labelledby': 'sectionAdd',
                }}
                anchorEl={addSectionMenuAnchor}
                open={addSectionMenuOpen}
                onClose={handleAddSectionMenuClose}
            >                
                <MenuItem onClick={handleAddNewSectionMenuClick}>{strings.generalInfoAddSectionButton}</MenuItem>                    
                {getFilteredSections().map((section, index) => (
                    <>
                        {index === 0 && <Divider />}
                        <MenuItem key={section.id} value={section.id} onClick={() => handleAddExistingSectionClick(section.id)}>                            
                            <ListItemText>
                                {section.name?.en ?? ""}
                            </ListItemText>
                        </MenuItem>
                    </>
                ))}                     
            </Menu>
            <Menu
                id="deleteSectionMenu"
                MenuListProps={{
                    'aria-labelledby': 'buttonDeleteSection',
                }}
                anchorEl={deleteSectionMenuAnchorEl}
                open={deleteSectionMenuOpen}
                onClose={handleDeleteSectionMenuClose}
            >
                <MenuItem onClick={handleRemoveSection}>{strings.removeButtonTitle}</MenuItem>
                <MenuItem onClick={handleDeleteSection}>{strings.deleteButtonTitle}</MenuItem>
            </Menu>
             
            {/* Item */}
            <Menu
                id="addItem"
                MenuListProps={{
                    'aria-labelledby': 'itemAdd',
                }}
                anchorEl={addItemMenuAnchor}
                open={addItemMenuOpen}
                onClose={handleAddItemMenuClose}
            >                
                <MenuItem onClick={handleAddNewItemMenuClick}>{strings.generalInfoAddItemButton}</MenuItem>                    
                {getFilteredItems().map((item, index) => (
                    <>
                        {index === 0 && <Divider />}
                        <MenuItem key={item.id} value={item.id} onClick={() => handleAddExistingItemClick(item.id)}>                            
                            <ListItemText>
                                {item.name?.en ?? ""}
                            </ListItemText>
                        </MenuItem>
                    </>                        
                ))}    
            </Menu>
            <Menu
                id="deleteItemMenu"
                MenuListProps={{
                    'aria-labelledby': 'buttonDeleteItem',
                }}
                anchorEl={deleteItemMenuAnchorEl}
                open={deleteItemMenuOpen}
                onClose={handleDeleteItemMenuClose}
            >
                <MenuItem onClick={handleRemoveItem}>{strings.removeButtonTitle}</MenuItem>
                <MenuItem onClick={handleDeleteItem}>{strings.deleteButtonTitle}</MenuItem>
            </Menu>

            <Grid container spacing={2} sx={{ height: "100%" }}>
                <Grid item xs={3} sx={{ height: "100%" }}>
                    <DataGrid
                        columns={bookColumns}
                        rows={bookRows}
                        canPerformAction={handleBookCanPerformAction}
                        rowSelectionModel={selectedBookId} // not sure but trying to keep sel row and sel id in sync
                        onRowSelectionModelChange={handleBookSelectionChange}                        
                        onAddButtonClick={handleAddBookClick}
                        permissionKey="content_information"
                        addButtonText={strings.generalInfoAddBookButton}
                        isRowSelectable={(params: GridRowParams) => {
                            return !bookLoading
                        }}
                        loading={loading}
                    />
                    <DetailsDialog
                        permissionKey="content_information"
                        open={bookDialogOpen}
                        adding={selectedBookId === ""}
                        title={bookDialogTitle}
                        onValidateForm={handleValidateBook}
                        onCancelClick={handleBookCancelClick}
                        onSaveClick={handleBookSaveClick}
                        contentSize={{ width: 442, height: 436.36 }}
                    >
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={bookTabValue}
                                onChange={handleBookTabChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label={strings.details} />
                                <Tab label="Contact Offer" />
                            </Tabs>
                        </Box>
                        <LanguageForm>
                            <TabPanelKeepMounted value={bookTabValue} index={0}>
                                <LanguageSelect />
                                <Spacer />
                                <LanguageTextField
                                    id="book-name"
                                    values={selectedBook.name ?? {}}
                                    label={strings.name}
                                    width={250}
                                    onChange={handleBookNameChange}
                                    error={Boolean(bookErrors.name)}
                                    helperText={bookErrors.name}
                                />                                
                            </TabPanelKeepMounted>
                            <TabPanelKeepMounted value={bookTabValue} index={1}>
                                <ContactOffer
                                    keyInfo={"information:" + selectedBookId}
                                    ref={bookContactRef}
                                    isEdit={selectedBookId.length !== 0}
                                    itemType="information"
                                />
                            </TabPanelKeepMounted>
                        </LanguageForm>
                    </DetailsDialog>
                </Grid>
                <Grid item xs={3} sx={{ height: "100%" }}>
                    <DisplayOrderGrid 
                        columns={topicColumns} 
                        rows={getTopicRows()}
                        canPerformAction={handleTopicCanPerformAction}
                        onRowSelectionModelChange={handleTopicSelectionChange} 
                        rowSelectionModel={selectedTopicId}                        
                        permissionKey="content_information" 
                        showAddButton={true} 
                        addButtonText={strings.generalInfoAddTopicButton}
                        onAddButtonClick={handleAddTopicButtonClick}
                        addButtonDisabled={selectedBookId.length === 0}
                        onSaveDisplayOrder={handleTopicSaveDisplayOrder}  
                        sortAlphabeticalField="name.en" // This is for grid display only (we will sort by string language when we get the data)
                        ref={topicGridRef}
                        loading={bookLoading}
                    />
                    <DetailsDialog
                        permissionKey="content_information"
                        open={topicDialogOpen}
                        adding={selectedTopicId === ""}
                        title={topicDialogTitle}
                        onValidateForm={handleValidateTopic}
                        onCancelClick={handleTopicCancelClick}
                        onSaveClick={handleTopicSaveClick}
                        contentSize={{ width: 879.5, height: 436.63 }}
                    >
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={topicItemTabValue}
                                onChange={handleTopicItemTabChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label={strings.details} />
                                <Tab label={strings.graphics} />
                                <Tab label="Contact Offer" />
                            </Tabs>
                        </Box>
                        <LanguageForm>
                            <TabPanelKeepMounted value={topicItemTabValue} index={0}>
                                
                                    <LanguageSelect />
                                    <Spacer />
                                    <LanguageTextField
                                        id="topic-name"
                                        label={strings.name}
                                        values={selectedTopic.name ?? {}}
                                        width={410}
                                        onChange={handleTopicNameChange}
                                        error={Boolean(topicErrors.name)}
                                        helperText={topicErrors.name}
                                    />
                                
                            </TabPanelKeepMounted>
                            <TabPanelKeepMounted value={topicItemTabValue} index={1}>
                                <GraphicManager
                                    propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                                    itemType="informationTopic" // TODO: evaluate image type name for each
                                    itemKey={selectedTopic.id}
                                    imageKeys={["main"]}                            
                                    ref={graphicManagerTopicRef}
                                />
                            </TabPanelKeepMounted>
                            <TabPanelKeepMounted value = {topicItemTabValue} index={2}>
                                <ContactOffer
                                    parentKey={selectedTopicId} // *** note: might need to be selectedTopicId ***
                                    keyInfo={"information:" + selectedBookId}
                                    ref={topicContactRef}
                                    isEdit={selectedTopicId.length !== 0}
                                    itemType="information"
                                />
                            </TabPanelKeepMounted>
                        </LanguageForm>
                    </DetailsDialog>
                </Grid>
                <Grid item xs={3} sx={{ height: "100%" }}>
                    <DisplayOrderGrid
                        columns={sectionColumns}
                        rows={getSectionRows()}
                        canPerformAction={handleSectionCanPerformAction}
                        onRowSelectionModelChange={handleSectionSelectionChange}
                        rowSelectionModel={selectedSectionId}                        
                        permissionKey="content_information"
                        addButtonText={strings.generalInfoAddSectionButton}
                        onAddButtonClick={handleAddSectionButtonClick}
                        addButtonDisabled={selectedTopicId.length === 0}
                        onSaveDisplayOrder={handleSectionSaveDisplayOrder}  
                        sortAlphabeticalField="name.en" // This is for grid display only (we will sort by string language when we get the data)
                        ref={sectionGridRef}
                    />
                    <DetailsDialog
                        permissionKey="content_information"
                        open={sectionDialogOpen}
                        adding={selectedSectionId === ""}
                        title={sectionDialogTitle}
                        onValidateForm={handleValidateSection} 
                        onSaveClick={handleSectionSaveClick}
                        contentSize={{ width: 282, height: 189.81 }}
                        onCancelClick={handleSectionCancelClick}
                    >
                        <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                            <LanguageForm>
                                <LanguageSelect />
                                <Spacer />
                                <LanguageTextField
                                    id="section-name"
                                    values={selectedSection.name ?? {}}
                                    label={strings.name}
                                    width={250}
                                    onChange={handleSectionNameChange}
                                    error={Boolean(sectionErrors.name)}
                                    helperText={sectionErrors.name}
                                />
                            </LanguageForm>
                        </Box>
                    </DetailsDialog>
                </Grid>
                <Grid item xs={3} sx={{ height: "100%" }}>
                    <DisplayOrderGrid
                        columns={itemColumns}
                        rows={getItemRows()}                            
                        onRowSelectionModelChange={handleItemSelectionChange}
                        rowSelectionModel={selectedItemId}
                        showAddButton={true}                        
                        permissionKey="content_information"
                        addButtonText={strings.generalInfoAddItemButton}
                        onAddButtonClick={handleAddItemButtonClick}
                        addButtonDisabled={selectedSectionId.length === 0} 
                        onSaveDisplayOrder={handleItemSaveDisplayOrder}  
                        sortAlphabeticalField="name.en" // This is for grid display only (we will sort by string language when we get the data)
                        ref={itemGridRef}
                    />
                    <DetailsDialog
                        permissionKey="content_information"
                        open={itemDialogOpen}
                        adding={selectedItemId === ""}
                        title={itemDialogTitle}
                        onValidateForm={handleValidateItem}
                        onCancelClick={handleItemCancelClick}
                        onSaveClick={handleItemSaveClick}
                        contentSize={{ width: 879.5, height: 560.56 }}
                    >
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={itemTabValue}
                                onChange={handleItemTabChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label={strings.details} />
                                <Tab label={strings.graphics} />
                                <Tab label={strings.video} />
                                <Tab label={strings.contactOffer} />
                            </Tabs>
                        </Box>
                        <LanguageForm>
                            <TabPanelKeepMounted value={itemTabValue} index={0}>
                                <LanguageSelect />
                                <Spacer />
                                <LanguageTextField
                                    id="item-name"
                                    label={strings.name}
                                    values={selectedItem?.name ?? {}}
                                    width={410}
                                    onChange={handleItemNameChange}
                                    error={Boolean(itemErrors.name)}
                                    helperText={itemErrors.name}
                                />
                                <Spacer />
                                <LanguageRichTextField
                                    values={selectedItem.description ?? {}}
                                    label={strings.description}
                                    id="item-description"
                                    onChange={handleItemDescriptionChange}
                                    width="100%"
                                    error={Boolean(itemErrors.description)}
                                    helperText={itemErrors.description}
                                />
                                <Spacer />
                                <Checkbox  
                                    label={strings.previewOnly}
                                    checked={selectedItem.previewOnly ?? false}
                                    onChange={handleItemPreviewOnlyChange}                                                                
                                />
                            </TabPanelKeepMounted>
                            <TabPanelKeepMounted value={itemTabValue} index={1}>
                                <GraphicManager
                                    propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                                    itemType="informationItem" // TODO: evaluate image type name for each
                                    itemKey={selectedItem.id}
                                    imageKeys={["main"]}                            
                                    ref={graphicManagerItemRef}
                                />
                            </TabPanelKeepMounted>
                            <TabPanelKeepMounted value={itemTabValue} index={2}>
                                <VideoManager
                                    propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                                    itemType="informationItem"
                                    itemKey={selectedItem.id}
                                    videoKeys={["main"]}                            
                                    ref={videoManagerItemRef}
                                />
                            </TabPanelKeepMounted>
                            <TabPanelKeepMounted value={itemTabValue} index={3}>
                                <ContactOffer
                                    itemKey={selectedItemId}
                                    parentKey={selectedTopicId}
                                    keyInfo={"information:" + selectedBookId}
                                    ref={itemContactRef}
                                    isEdit={selectedItemId.length !== 0}
                                    itemType="information"
                                />
                            </TabPanelKeepMounted>
                        </LanguageForm>
                    </DetailsDialog>
                </Grid>
            </Grid>
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    )    
}

export default InformationList;