import { AxiosInstance } from "axios";
import { MenuItem, MenuItemViewsResponse } from "../../../models/reporting/itv/MenuItemView";
import { ItvSessionsResponse } from "../../../models/reporting/itv/ItvSessions";
import { ContactsSentResponse, GeneralInfoItem, GeneralInfoMenuItem } from "../../../models/reporting/itv/ContactsSent";

const url = process.env.REACT_APP_MANAGER_API_URL + `/reporting/`;

export async function getItvSessions(axiosInstance: AxiosInstance, propertyCode: string, startDate: Date, endDate: Date) : Promise<ItvSessionsResponse> {
    var itvSessionsResponse: ItvSessionsResponse = {
        count: 200,
        avgSessionDuration: 30,
        longestSessionDuration: 130
    }
    
    return itvSessionsResponse;
    //return axiosInstance.get<ItvSessionsResponse>(`${url}${propertyCode}/itv?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
    //    .then(response => response.data)
}

export async function getMenuItemViews(axiosInstance: AxiosInstance, propertyCode: string, startDate: Date, endDate: Date) : Promise<MenuItem[]> {
    var menuItems: MenuItem[] = [];
    var menuItem1: MenuItem = {
        id: "1",
        name: "Spa",
        viewCount: 50
    }
    menuItems.push(menuItem1);

    var menuItem2: MenuItem = {
        id: "2",
        name: "Netflix",
        viewCount: 200
    }
    menuItems.push(menuItem2);

    var menuItem3: MenuItem = {
        id: "3",
        name: "You Tube",
        viewCount: 53
    }
    menuItems.push(menuItem3);

    var menuItem4: MenuItem = {
        id: "4",
        name: "Property Information",
        viewCount: 36
    }
    menuItems.push(menuItem4);

    return menuItems;
    
    //return axiosInstance.get<MenuItemViewsResponse>(`${url}${propertyCode}/itv/menu?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
    //    .then(response => response.data.menuItems)
}

export async function getContactsSent(axiosInstance: AxiosInstance, propertyCode: string, startDate: Date, endDate: Date) : Promise<GeneralInfoMenuItem[]> {
    var menuItems: GeneralInfoMenuItem[] = [];
    var items: GeneralInfoItem[] = []
    var item: GeneralInfoItem = {
        id: "1",
        name: "Swedish Massage",
        sentCount: 10
    }
    items.push(item)

    item = {
        id: "2",
        name: "Hot Stone Massage",
        sentCount: 78
    }
    items.push(item)

    item = {
        id: "3",
        name: "Couples Massage",
        sentCount: 22
    }
    items.push(item)
    
    var menuItem: GeneralInfoMenuItem = {
        id: "1",
        name: "Spa",
        items: items
    }
    menuItems.push(menuItem)

    items = []
    var item: GeneralInfoItem = {
        id: "1",
        name: "Rooms and Suites",
        sentCount: 234
    }
    items.push(item)

    item = {
        id: "2",
        name: "Amenities",
        sentCount: 57
    }
    items.push(item)
    
    menuItem = {
        id: "2",
        name: "Property Information",
        items: items
    }
    menuItems.push(menuItem)

    return menuItems

    //return axiosInstance.get<ContactsSentResponse>(`${url}${propertyCode}/itv/contact?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
    //    .then(response => response.data.menuItems)
}