import { AxiosInstance } from "axios";
import { Room, RoomDetail } from "../models/common/Room";
import { RoomsUploadResponse } from "../models/administration/RoomsUploadResult";

const URL = process.env.REACT_APP_MANAGER_API_URL + `/property-management/`;

export async function searchRoom(axiosInstance: AxiosInstance, propertyCode: string, searchText: string) {
  const response = await axiosInstance.get(`${URL}${propertyCode}/rooms?number=${searchText}`);
  return response.data.rooms as Room[];
}

export async function getRoom(axiosInstance: AxiosInstance, propertyCode: string, roomNumber: string) {
  const response = await axiosInstance.get(`${URL}${propertyCode}/rooms/${roomNumber}`);
  return response.data as RoomDetail;
}

export async function addRoom(axiosInstance: AxiosInstance, propertyCode: string, room: RoomDetail) {
  const response = await axiosInstance.post(`${URL}${propertyCode}/rooms`, room);
  return response.data; 
}

export async function updateRoom(axiosInstance: AxiosInstance, propertyCode: string, room: RoomDetail) {
  const response = await axiosInstance.put(`${URL}${propertyCode}/rooms/${room.id}`, room);
  return response.data; 
}

export async function deleteRoom(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
  const response = await axiosInstance.delete(`${URL}${propertyCode}/rooms/${id}`);
  return response.data; 
}

export async function getRoomsForTransferList(axiosInstance: AxiosInstance, propertyCode: string): Promise<{ id: string; number: string; }[]> {
  const response = await axiosInstance.get(`${URL}${propertyCode}/rooms`);
  return response.data.rooms.map(({ id, number }: { id: string, number: string }) => ({ id, number }));
}

export async function uploadRooms(axiosInstance: AxiosInstance, propertyCode: string, file: File) {
  const formData = new FormData();
  formData.append('dataFile', file);
  const response = await axiosInstance.post(`${URL}${propertyCode}/rooms/upload`, formData);
  return response.data as RoomsUploadResponse; 
}
