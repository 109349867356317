import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import { Tooltip } from '@mui/material';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiIconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from "@mui/material/MenuItem";
import Popover from '@mui/material/Popover';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import * as React from "react";
import { getSummary, searchGuest } from "../../../api/guestApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Guest, Summary, guestInitialState } from "../../../models/common/Guest";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import DataGrid from "../../common/datatable/DataGridWrapper";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme: Theme) => ({   
    root: {
       // padding: themePrimary.spacing(2),
    },   
    summaryCard: {
        height: '100%',
        display: 'flex',
        padding: themePrimary.spacing(2),
        backgroundColor: themePrimary.palette.primary.main + " !important",
    },
    summaryCardTextContainer: {
        margin: 'auto',
    },
    text: {
        color: themePrimary.palette.primary.contrastText,
        textAlign: 'center',
    }
}));

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

const GuestList = () => {
    const [isLoading, setIsLoading] = React.useState(true);    
    const strings = useLocalizedStrings();
    const [summary, setSummary] = React.useState<Summary>();
    const [guestRows, setGuestRows] = React.useState<Guest[]>([]);
    const [selectedGuest, setSelectedGuest] = React.useState<Guest>(guestInitialState);   
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const [isSearching, setIsSearching] = React.useState(false);
    const [searchField, setSearchField] = React.useState("name");
    const [searchValue, setSearchValue] = React.useState("");
    const [checkedIn, setCheckedIn] = React.useState(true);
    const [currentLanguage, setCurrentLanguage] = React.useState("en");
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();
    const classes = useStyles(useTheme());      
    const theme = useTheme();
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        async function load() {            
            await loadSummary();
            setIsLoading(false);
        }

        load();

        // get language the browser is set to because
        // I don't want to call getLanguage on each row below.
        setCurrentLanguage(strings.getLanguage())
    }, [user.currentProperty?.code])
    
    async function loadSummary() {
        try {
            const payload = await getSummary(axiosInstance, user.currentProperty?.code ?? "");
            setSummary(payload);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorRetreivingGuestSummary.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
        }
    }

    async function handleSearchClick() {
        var search = `${searchField}=${searchValue}`;        

        if (checkedIn) {
            search += "&checkedIn=true";
        }

        try {
            setIsSearching(true);

            const result = await searchGuest(axiosInstance, user.currentProperty?.code ?? "", search);
            //if (result && result.length > 0) {
            setGuestRows(result);
            //}
            //else {
            //setGuestRows([]);
            //}
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorSearchingGuests.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });           
        }

        await loadSummary();        
        setIsSearching(false);
    };
   
    function getLoyaltyLevelValue(row: any) {
        if (!row.loyaltyLevel) return "";

        try {
            const stringParsed = JSON.parse(row.loyaltyLevel);
            if (stringParsed[currentLanguage]) {
                return stringParsed[currentLanguage];
            }
            else if (stringParsed[0]) {
                return stringParsed[0];
            }
            else {
                return "";
            }
        }
        catch (error) {
            console.log('cannot parse language string: ', error);
            return "";
        }
    }

    function getDate(inputDate: Date) {
        const date = new Date(inputDate)
        const year = date.getUTCFullYear();

        let month = (date.getUTCMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getUTCDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    function handleFieldChange(event: SelectChangeEvent) {
        setSearchField(event.target.value as string);
        setSearchValue("");
    }

    function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value as string);
    }
    function handleCheckedInChange(event: React.ChangeEvent<HTMLInputElement>) {
        const checkbox = event.target as HTMLInputElement;
        setCheckedIn(checkbox.checked);
    }

    const gridColumns: GridColDef[] = [
        { field: "fullName", headerName: strings.name, valueGetter: (value, row) => `${row.lastName}, ${row.firstName}`, flex: 2 },
        { field: "roomNumber", headerName: strings.roomNumber, valueGetter: (value, row) => row.roomNumbers?.join(", ") ?? "", flex: 1 },
        { field: "checkedIn", headerName: strings.checkedIn, type: "boolean", flex: 0.5 },
        { field: "checkInDate", headerName: strings.checkInDate, valueGetter: (value, row) => row.checkInDate, valueFormatter: ({ value }) => value ? getDate(value) : "", type: "date", flex: 1 },
        { field: "checkOutDate", headerName: strings.checkOutDate, valueGetter: (value, row) => row.checkOutDate, valueFormatter: ({ value }) => value ? getDate(value) : "", type: "date", flex: 1 },
        { field: "loyaltyLevel", headerName: strings.loyaltyLevel, valueGetter: (value, row) => getLoyaltyLevelValue(row), flex: 1 },        
        { field: "externalKey", headerName: strings.externalId, flex: 1 },        
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [    
                <Tooltip title={strings.more}>
                    <MuiIconButton sx={{ color: "primary.dark" }} aria-label="more" component="label" onClick={(event: React.MouseEvent<HTMLElement>) => handleMoreButtonClicked(params.row.id, event)}>                    
                        <MoreHorizIcon />
                    </MuiIconButton>
                </Tooltip>
            ],
        }
    ]

    function handleMoreButtonClicked(id: string, event: React.MouseEvent<HTMLElement>) {
        var guest = guestRows?.find(g => g.id === id);
        if (guest) {
            setSelectedGuest(guest);
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 260px)" }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card variant="outlined" className={classes.summaryCard}>
                        <div className={classes.summaryCardTextContainer}>
                            <Typography variant="h5" component="div" className={classes.text}>
                                {strings.guestListSummaryGuestCount}
                            </Typography>
                            <Typography variant="h4" component="div" className={classes.text}>
                                {summary?.guestCount ?? 0}
                            </Typography>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card variant="outlined" className={classes.summaryCard}>
                        <div className={classes.summaryCardTextContainer}>
                            <Typography variant="h5" component="div" className={classes.text}>
                                {strings.guestListSummaryRoomCount}
                            </Typography>
                            <Typography variant="h4" component="div" className={classes.text}>
                                {summary?.roomCount ?? 0}
                            </Typography>
                        </div>
                    </Card>
                </Grid>
            </Grid>
            <Spacer y={2} />
            <Stack direction="row">
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-label">
                        {strings.field}
                    </InputLabel>
                    <Select
                        id="selectField"
                        value={searchField}
                        label={strings.field}
                        onChange={handleFieldChange}
                        MenuProps={{
                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                            transformOrigin: { vertical: "top", horizontal: "left" }
                        }}
                    >                        
                        <MenuItem key="name" value="name">{strings.name}</MenuItem>
                        <MenuItem key="roomNumber" value="roomNumber">{strings.roomNumber}</MenuItem>
                        <MenuItem key="externalKey" value="externalKey">{strings.externalId}</MenuItem>
                        <MenuItem key="bookingId" value="bookingId">{strings.bookingId}</MenuItem>
                    </Select>
                </FormControl>
                <Spacer x={2} />
                <TextField                    
                    onChange={handleValueChange}
                    label={strings.value}
                    variant="outlined"
                    value={searchValue}   
                    onKeyDown={(e) => (e.keyCode === 13 ? handleSearchClick() : null)}
                />
                <Spacer x={2} />
                <FormControlLabel control={<Checkbox id="sun" checked={checkedIn} onChange={handleCheckedInChange} />} label={strings.checkedIn} />                
                <Spacer x={2} />
                <Button                    
                    variant="contained"                                            
                    onClick={handleSearchClick}
                    startIcon={<SearchIcon />}
                    aria-label="Search">
                    {strings.search}
                </Button>
            </Stack>
            <Spacer y={2} />
            <DataGrid
                getRowId={(row) => row.id + row.roomNumber}
                rows={guestRows}
                columns={gridColumns}                
                showAddButton={false}
                loading={isSearching}                    
            />

            <Popover                
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <table cellSpacing={theme.spacing(1)} style={{ padding: theme.spacing(1) }}>   
                    <tbody>                            
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.firstNameLabel}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedGuest.firstName}</Typography></td>
                        </tr>      
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.lastNameLabel}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedGuest.lastName}</Typography></td>
                        </tr>                                  
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.roomNumber}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedGuest.roomNumber}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.checkedIn}:</Typography></td>
                            <td>{selectedGuest.checkedIn ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small" />}</td>
                        </tr> 
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.checkInDate}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedGuest.checkInDate.toLocaleString()}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.checkOutDate}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedGuest.checkOutDate.toLocaleString()}</Typography></td>
                        </tr>
                        {(selectedGuest.loyaltyLevel && selectedGuest.loyaltyLevel !== "") &&
                            <tr>
                                <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.loyaltyLevel}:</Typography></td>
                                <td><Typography sx={{ whiteSpace: 'nowrap' }}>{getLoyaltyLevelValue(selectedGuest)}</Typography></td>
                            </tr>
                        } 
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.externalId}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedGuest.externalKey}</Typography></td>
                        </tr>
                        {(selectedGuest.bookingId && selectedGuest.bookingId !== "") &&
                            <tr>
                                <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.bookingId}:</Typography></td>
                                <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedGuest.bookingId}</Typography></td>
                            </tr>
                        }  
                        {(selectedGuest.groupId && selectedGuest.groupId !== "") &&
                            <tr>
                                <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.groupId}:</Typography></td>
                                <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedGuest.groupId}</Typography></td>
                            </tr>
                        }                               
                    </tbody>  
                </table>                
            </Popover>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );    
};

export default GuestList;
