import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useAuth } from "oidc-react";
import { ServiceTicketNote } from '../../../models/dashboard/support/ServiceTicketNote';
import themePrimary from '../../../styles/themePrimary';

export interface TicketNoteProps {
    note: ServiceTicketNote;
}

const TicketNote = (props: TicketNoteProps) => {
    const { note } = props;    
    //const inputEl = React.useRef(null);
    const auth = useAuth();

    //React.useEffect(() => {
    //    if (inputEl !== null) {
    //        // @ts-ignore
    //        note.text = note.text.replaceAll("\n", "<br/ >");

    //        let imageIndex: number = 0;

    //        do {
    //            imageIndex = note.text.indexOf("[image=");
    //            if (imageIndex !== -1) {
    //                let indexStart = note.text.indexOf("=", imageIndex);
    //                let indexEnd = note.text.indexOf("]", indexStart + 1);
    //                let imageId = note.text.substring(indexStart + 1, indexEnd);                    
                    
    //                if (indexEnd === note.text.length - 1) {
    //                    note.text = `<div>${note.text.substring(0, imageIndex)}</div><img src='${process.env.REACT_APP_MANAGER_API_URL}/service-ticket/notes/${imageId.toString()}/image&access_token=${auth.userData?.access_token}' style='max-width:1100px;' />`;
    //                }
    //                else {
    //                    note.text = `<div>${note.text.substring(0, imageIndex)}</div><img src='${process.env.REACT_APP_MANAGER_API_URL}/service-ticket/notes/${imageId.toString()}/image&access_token=${auth.userData?.access_token}' style='max-width:1100px;' />${note.text.substring(indexEnd + 1, note.text.length - 1)}`;
    //                }

    //                imageIndex = note.text.indexOf("[image", imageIndex + 1);
    //            }
    //        } while (imageIndex !== -1);

    //        (inputEl.current as unknown as HTMLDivElement).innerHTML= note.text;
    //    }
        
    //});

    function markdownToHtml(text: string) {
        text = text.replaceAll("\n", "<br/ >");

        // Fix images [image](<url>) -> <img src='<url>'>
        if (text.includes("[http")) {
            // Connectwise returns the full image url here
            text = text.replace(/!\[[^\]]*\]\((?<filename>.*?)(?=\"|\))(?<optionalpart>\".*\")?\)/g, "<img src='$1' style='max-width:1100px;' />");
        }
        else {
            // For Saleforce we need to build the url
            text = text.replace(/\[([\w \.]+)\]\(([\w\.:\/ ]*)\)/, `<img src='${process.env.REACT_APP_MANAGER_API_URL}/service-ticket/images/$2?access_token=${auth.userData?.access_token}' style='max-width:1100px;' />`);
        }
        return text;
    }

    var now = new Date();

    return (
        <Grid container direction="column" sx={{ paddingTop: themePrimary.spacing(2), paddingBottom: themePrimary.spacing(2), borderBottom: '1px solid ' + themePrimary.palette.primary.main }}>            
            <Grid item>
                <Grid container spacing={1} direction="row">
                    <Grid item xs={6}>
                        <Box sx={{ fontWeight: 'bold' }}>{note.createdBy}</Box>
                    </Grid>
                    <Grid item xs={6} justifyContent="flex-end">
                        <Box textAlign="right" sx={{ fontWeight: 'bold' }}>{new Date(note.dateCreated ?? now).toLocaleDateString() + " " + new Date(note.dateCreated ?? now).toLocaleTimeString()}</Box>
                    </Grid>
                </Grid>
            </Grid>            
            <Grid item>
                <div dangerouslySetInnerHTML={{ __html: markdownToHtml(note.text) }} />
            </Grid>
        </Grid>        
    );
}

export default TicketNote;