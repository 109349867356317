import * as React from "react";
import { useLocalizedStrings } from "../../../../localization/LocalizedStringsProvider";
import NotificationMessage, { NotificationOptions } from "../../../common/NotificationMessage";
import DataGrid from "../../../common/datatable/DataGridWrapper";
import { GridColDef } from "@mui/x-data-grid";
import { useCreateAxios } from "../../../../hooks/useCreateAxios";
import { Button, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import themePrimary from "../../../../styles/themePrimary";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DateSearchField, { getDefaultEndDate } from "../../../common/search/DateSearchField"
import SearchIcon from '@mui/icons-material/Search';
import { getContactsSent } from "../../../../api/reporting/itv/reportingApi";
import { GeneralInfoMenuItem, GeneralInfoItem } from "../../../../models/reporting/itv/ContactsSent";
import { useUser } from "../../../../providers/UserProvider";
import axios from "axios";
import DataGridExportToolBar from "./DataGridExportToolBar";
import Spacer from "../../../common/Spacer";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    }
}));

const getDefaultSearchStartDate = (): Date => {
    var date = new Date();
    date.setMonth(0, 1);

    return date;
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function ContactsSent() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState<Date>(getDefaultSearchStartDate());
    const [endDate, setEndDate] = React.useState<Date>(getDefaultEndDate); 
    const [viewData, setViewData] = React.useState<GeneralInfoMenuItem[] | undefined>(undefined); 
    const [items, setItems] = React.useState<GeneralInfoItem[] | undefined>(undefined); 
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const [selectedGiId, setSelectedGiId] = React.useState("");
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const classes = useStyles(); 
    const theme = useTheme();

    React.useEffect(() => {
        async function apiGetContactViews() {
            try {
                const payload = await getContactsSent(axiosInstance, user.currentProperty?.code ?? "", startDate, endDate);
                setViewData(payload);
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingContactsSent.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        if (isLoading) {
            apiGetContactViews();
        }
        
    }, [user.currentProperty?.code, isLoading, startDate, endDate])

    function onSearchClick() {
        setIsLoading(true);
    }

    function onDateSearchFieldChangeHandler (startDate: Date, endDate: Date) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    function handlePropertySelectionChange(e: SelectChangeEvent) {
        setSelectedGiId(e.target.value as string);

        if (viewData !== undefined) {
            var menuItem = viewData.find(i => i.id === e.target.value);
            if (menuItem !== null) {
                setItems(menuItem!!.items);
            }
        }
    }


    function getMenuItems() {
        let kvps: { key: string; value: string }[] = [];
        if (viewData) {
            viewData.forEach(p => {
                kvps.push({ key: p.id, value: p.name });
            });
        }              
        return kvps;
    } 

    const gridColumns: GridColDef[] = [        
        { field: 'name', headerName: strings.name, flex: 1 },
        { field: 'sentCount', headerName: strings.reportingSentCount, flex: 1 }
    ];

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 257px)" }}> 
            <Box className={classes.searchBox}>
                <DateSearchField 
                    startDate={getDefaultSearchStartDate()}
                    endDate={null}
                    format={null}
                    onChange={onDateSearchFieldChangeHandler} 
                />

                <Button
                    sx={{ marginLeft: themePrimary.spacing(2) }}
                    variant="contained"
                    size="large"
                    disabled={false}
                    onClick={onSearchClick}
                    startIcon={<SearchIcon />}
                    aria-label="Search">
                    {strings.search}
                </Button>
            </Box>

            {viewData !== undefined && !isLoading &&
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-label">
                        {strings.menuItem}
                    </InputLabel>
                    <Select
                        id="selectFieldGiMenuItemList"
                        value={selectedGiId}
                        label={strings.menuItem}
                        onChange={handlePropertySelectionChange}
                        MenuProps={{
                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                            transformOrigin: { vertical: "top", horizontal: "left" }
                        }}
                    >                         
                        {getMenuItems().map((kvp, index) => (
                            <MenuItem key={index} value={kvp.key}>{kvp.value}</MenuItem>
                        ))}                             
                    </Select>
                </FormControl>
            }

            {viewData !== undefined && !isLoading &&
                <Spacer y={2}/> 
            }
            
            {viewData !== undefined && items!== undefined && !isLoading &&
                <DataGrid 
                    permissionKey="reporting_itv"
                    showAddButton={false}
                    columns={gridColumns} 
                    getRowId={(row) => `${row.id}`} 
                    rows={items}                 
                    loading={isLoading} 
                    slots={
                        { toolbar: DataGridExportToolBar }
                    } 
                    slotProps={
                        { toolbar: {fileName: strings.reportingContactsSentTabLabel.replaceAll(" ", "")} }
                    } 
                />
            }


            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );
};

export default ContactsSent;