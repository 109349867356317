import * as React from "react";
import { GridCsvExportOptions, GridPrintExportOptions, GridToolbarContainer, GridToolbarExport, GridToolbar, useGridApiRef, GridToolbarContainerProps  } from '@mui/x-data-grid';

function DataGridExportToolBar(props: GridToolbarContainerProps) {
    return (
        <GridToolbarContainer>
            <GridToolbarExport 
                csvOptions={{ ...props }} 
                printOptions={{ hideToolbar: true, hideFooter: true }} 
            />
        </GridToolbarContainer>
    );
}

export default DataGridExportToolBar;