import { useEffect, useState} from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DataGrid from "../../common/datatable/DataGridWrapper";
import { GridColDef, GridRowParams} from "@mui/x-data-grid";
import axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { getSkinOption, updateSkinOption } from "../../../api/administration/skin/skinOptionApi";
import { useUser } from "../../../providers/UserProvider";
import { SkinOption } from "../../../models/administration/SkinOption";
import Box from '@mui/material/Box';
import { Theme, useTheme } from "@mui/material/styles";

const SkinOptions = () => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [isLoading, setIsLoading] = useState(true);
    const [useBrandSkin, setUseBrandSkin] = useState<boolean>(false);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const theme = useTheme();

    useEffect(() => {
        async function apiGetSkinOption() {
            try {
                const response = await getSkinOption(axiosInstance, user.currentProperty?.id ?? "");
                setUseBrandSkin(response.useBrandSkin);
            } 
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingSkinOptions.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }
        
        apiGetSkinOption();
    }, [user.currentProperty?.id, useBrandSkin, strings.errorRetrievingSkins]);

    async function handleUseBrandSkinChange(e: SelectChangeEvent) {
        var val = false;

        if (e.target.value === "1") {
            val = true
        }

        try {
            var option: SkinOption = {
                brandId: user.currentBrand?.id!!,
                propertyId: user.currentProperty?.id!!,
                useBrandSkin: val
            }
            await updateSkinOption(axiosInstance, user.currentProperty?.id!!, option)
            setUseBrandSkin(val)
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorSavingSkinOptions.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            setUseBrandSkin(!val)
        }
    }
   
    return (
        <Box sx={{ padding: theme.spacing(2) }}>
            {!isLoading &&
                <FormControl variant="outlined" sx={{ minWidth: 260 }}>
                    <InputLabel>{strings.ticketStatusFilterSelectLabel}</InputLabel>
                    <Select
                        id="selectUseBrandSkin"
                        value={useBrandSkin === true ? "1" : "0"}
                        label={strings.ticketStatusFilterSelectLabel}
                        onChange={handleUseBrandSkinChange}>
                        <MenuItem key="0" value="0">Use Property Skin</MenuItem>
                        <MenuItem key="1" value="1">Use Brand Skin</MenuItem>
                    </Select>
                </FormControl>
            }
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );
};

export default SkinOptions;