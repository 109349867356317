import AddIcon from '@mui/icons-material/Add';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MuiIconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/material/styles';
import axios from "axios";
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Calendar, NavigateAction, SlotInfo, ToolbarProps, View, momentLocalizer, stringOrDate } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { v4 as uuid } from "uuid";
import { getLocations } from "../../../api/promotions/locationsApi";
import { getPromotions } from "../../../api/promotions/promotionsApi";
import { createSchedule, deleteSchedule, getSchedule, updateSchedule } from "../../../api/promotions/scheduleApi";
import { deleteScheduleInstance, getSchedules } from "../../../api/promotions/scheduledPromotionsApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { DateRange } from '../../../models/common/DateRange';
import { Access } from "../../../models/configuration/security/Permission";
import { Location, mapLocationsToKeyValues } from "../../../models/modules/promotions/Location";
import { Promotion, mapPromotionsToKeyValues } from "../../../models/modules/promotions/Promotion";
import { Schedule as ScheduleModel, ScheduledPromotionLocation, initialScheduleState } from "../../../models/modules/promotions/Schedule";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import { default as Scheduler, SchedulerRefObject } from "../../common/Scheduler";
import Spacer from "../../common/Spacer";
import DetailsDialog from "../../common/details/DetailsDialog";
import { default as DialogSelect } from "../../common/details/Select";

interface ValidationErrors {
    locationCode: string;
    promotionId: string;
}

const Schedule = () => {
    const initialErrorState: ValidationErrors = {
        locationCode: "",
        promotionId: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [refreshSchedules, setRefreshSchedules] = React.useState(false);
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
    const [locations, setLocations] = React.useState<Location[]>([]);
    const [currentDate, setCurrentDate] = React.useState(new Date());
    const [dataRange, setDataRange] = React.useState<DateRange | null>(null);
    const [scheduledLocations, setScheduledLocations] = React.useState<ScheduledPromotionLocation[]>([]);
    const [promotions, setPromotions] = React.useState<Promotion[]>([]);
    const [selectedSlot, setSelectedSlot] = React.useState<SlotInfo | null>(null);
    const [selectedScheduleId, setSelectedScheduleId] = React.useState<string>("");
    const [selectedSchedule, setSelectedSchedule] = React.useState<ScheduleModel>(initialScheduleState);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [selectedScheduleInstanceId, setSelectedScheduleInstanceId] = React.useState<string>("");
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const contextMenuOpen = Boolean(contextMenuAnchorEl);
    const [isLoading, setIsLoading] = useState(true);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const theme = useTheme();
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const schedulerRef = useRef<SchedulerRefObject>(null);
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();        

    function getInitialDateRange(): DateRange {  
        // Since the initial calendar view is by month, get this month's data, but we want EOD of the day.
        var start = dayjs(currentDate).startOf("month");    
        var end = start.add(1, "month").endOf("day");
        return new DateRange(start.toDate(), end.toDate());
    }

    React.useEffect(() => {
        async function getInitialData() {
            setIsLoading(true);
            
            await getScheduleData(getInitialDateRange(), true);

            try {
                const payload = await getPromotions(axiosInstance, user.currentProperty?.code ?? "");
                setPromotions(payload);
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingPromotions.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }

            try {
                const payload = await getLocations(axiosInstance, user.currentProperty?.code ?? "");
                setLocations(payload);
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingLocations.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        getInitialData();
    }, [refreshSchedules, user.currentProperty?.code, strings.errorRetreivingPromotionSchedule, strings.errorRetreivingPromotions, strings.errorRetrievingLocations]);
    
    async function getSchedulePage(range: DateRange) {
        setIsPageLoading(true);
        await getScheduleData(range)
        setIsPageLoading(false);
    }

    async function getScheduleData(range: DateRange, clearData?: boolean) {
        try {
            // Check if we already have data for this range
            if (!clearData && dataRange?.contains(range)) {
                return;
            }

            const payload = await getSchedules(axiosInstance, user.currentProperty?.code ?? "", range.start, range.end);

            var oldArray = [...scheduledLocations];

            if (clearData || oldArray.length === 0) {
                // If this is the initial load we can just add everything
                setScheduledLocations(payload);
            }
            else {
                // Add the data returned to the existing data
                payload.forEach(l => {
                    // Check if we already have this location
                    var found = oldArray.find(l2 => l2.code === l.code);
                    if (found) {
                        // If we do, we can add the new promotions
                        l.scheduledPromotions.forEach(sp => {
                            // Make sure we don't duplicate the promotions
                            if (!found?.scheduledPromotions.find(sp2 => sp2.id === sp.id)) {
                                found?.scheduledPromotions.push(sp);
                            }
                        });
                    }
                    else {
                        // If not add it with all its promotions
                        oldArray.push(l);
                    }
                });

                setScheduledLocations(oldArray);
            }
            
            // Update the current range of the data
            var temp = dataRange;
            if (temp) {
                temp?.extend(range);
                setDataRange(temp);
            }
            else {
                setDataRange(range);
            }     
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorRetreivingPromotionSchedule.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
        }      
    } 

    async function loadSchedule(id: string) {
        try {
            setSelectedScheduleId(id);
            const payload = await getSchedule(axiosInstance, user.currentProperty?.code ?? "", id);
            setSelectedSchedule(payload);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorRetreivingPromotionSchedule.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
        }
    }

    async function deleteSingleSchedule(id: string) {
        try {            
            await deleteScheduleInstance(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPromotionSchedule.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return;
        }
        
        setContextMenuAnchorEl(null);
        setRefreshSchedules(!refreshSchedules);
        setNotify({
            isOpen: true,
            message: strings.promotionScheduleDeletedSuccessfully,
            msgType: "success",
        });       
    }
    async function deleteAllSchedules(id: string) {
        try {
            await deleteSchedule(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPromotionSchedule.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return;
        }
        
        setContextMenuAnchorEl(null);
        setRefreshSchedules(!refreshSchedules);
        setNotify({
            isOpen: true,
            message: strings.promotionScheduleDeletedSuccessfully,
            msgType: "success",
        });        
    }

    function handleLocationChange(event: SelectChangeEvent) {       
        setSelectedSchedule((prevState) => ({
            ...prevState,
            locationCode: event.target.value as string
        }));
    }

    function handlePromotionChange(event: SelectChangeEvent) {
        setSelectedSchedule((prevState) => ({
            ...prevState,
            promotionId: event.target.value as string
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        var json = schedulerRef?.current?.getJson();

        if (!json || json === "") {
            return false;
        }
         
        try {
            if (selectedScheduleId === "") {
                var newSchedule: ScheduleModel = {
                    ...selectedSchedule,
                    id: uuid(),
                    data: json
                };
                    
                await createSchedule(axiosInstance, user.currentProperty?.code ?? "", newSchedule)
            }
            else {
                var newSchedule: ScheduleModel = {
                    ...selectedSchedule,                        
                    data: json
                };

                await updateSchedule(axiosInstance, user.currentProperty?.code ?? "", newSchedule);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (selectedScheduleId === "" ? strings.errorAddingPromotionSchedule : strings.errorUpdatingPromotionSchedule).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }

        setSelectedScheduleId("");
        setRefreshSchedules(!refreshSchedules);
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
        setNotify({
            isOpen: true,
            message: strings.promotionScheduleSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateForm() {
        var scheduleValid = schedulerRef?.current?.validate() ?? false;
        var valid = true;

        var errors = validate<ScheduleModel, ValidationErrors>([
            { property: "locationCode", type: ValidationType.Required, message: strings.validationPromotionItemName },
            { property: "promotionId", type: ValidationType.Required, message: strings.validationPromotionItemName }
        ], selectedSchedule);
        if (errors) {
            setErrors(errors);
            valid = false;
        }        

        return scheduleValid && valid;
    }

    // This seems a little hackish (There's no en or en-US, I guess it's just the default)
    var locale = window.navigator.language.toLowerCase();
    if (!locale.startsWith("en")) {
        try {
            require(`moment/locale/${locale}.js`)
        }
        catch {
            console.log(`${locale} not supported.`)
        }
    }
    
    const localizer = momentLocalizer(moment);

    function getEvents() {
        var array = new Array();
        for (var i = 0; i < scheduledLocations.length; i++) {
            var location = scheduledLocations[i];
            array.push(...location.scheduledPromotions.map(sp => ({ scheduleId: sp.scheduleId, scheduleInstanceId: sp.id, title: sp.promotionInternalName, allDay: false, start: sp.start, end: sp.end, hexColor: getCellColor(location.code) })));
        }
        return array;
    }

    function getCellColor(locationCode: string) {
        switch (locationCode) {
            case "ITVHOME":
                return "#00c274";
            case "MOBILEHOME":
                return "#006ec2";
            default:
                return "#ff0000";
        }
    }

    const eventStyleGetter = (event: any) => {        
        var style = {
            backgroundColor: event.hexColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };

        return {
            style: style
        };
    };

    function handleAddScheduleClick(event: React.MouseEvent<HTMLElement>) {
        setSelectedScheduleId("");
        setSelectedSchedule(initialScheduleState);
        setDetailsDialogOpen(true);
    }


    const handleSelectSlot = (slotInfo: SlotInfo) => {
        setSelectedSlot(slotInfo);
    }

    const handleScheduleRightClick = (event: any, e: React.SyntheticEvent<HTMLElement, Event>) => {        
        setContextMenuAnchorEl(e.currentTarget.parentElement);        
        setSelectedScheduleId(event.scheduleId);
        setSelectedScheduleInstanceId(event.scheduleInstanceId);
    }
     
    const handleContextMenuClose = () => {
        setContextMenuAnchorEl(null);
    };

    const handleEditSelectedSchedule = async () => {
        await loadSchedule(selectedScheduleId);
        setContextMenuAnchorEl(null);
        setDetailsDialogOpen(true);
    }

    const handleDeleteSelectedSchedule = async () => {
        var internalName = "";
        for (var i = 0; i < scheduledLocations.length; i++) {
            const sl = scheduledLocations[i];
            const found = sl.scheduledPromotions.find(sp => sp.id === selectedScheduleInstanceId);
            if (found) {
                internalName = found.promotionInternalName;
                break;
            }
        }

        alertDialog({
            title: strings.deletePromotionScheduleInstanceAlertTitle,
            message: strings.deletePromotionScheduleInstanceAlertMessage.replace("{{name}}", internalName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSingleSchedule(selectedScheduleInstanceId);
        });
    }

    const handleDeleteAllSelectedSchedule = async () => {
        var internalName = "";
        for (var i = 0; i < scheduledLocations.length; i++) {
            const sl = scheduledLocations[i];
            const found = sl.scheduledPromotions.find(sp => sp.scheduleId === selectedScheduleId);
            if (found) {
                internalName = found.promotionInternalName;
                break;
            }
        }

        alertDialog({
            title: strings.deletePromotionScheduleAlertTitle,
            message: strings.deletePromotionScheduleAlertMessage.replace("{{name}}", internalName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteAllSchedules(selectedScheduleId);
        });        
    }

    const handleScheduleDoubleClick = async (event: any, e: React.SyntheticEvent<HTMLElement, Event>) => {
        // Don't retrigger a new get of schedule
        //if (selectedScheduleId === event.scheduleId) {
        //    setDetailsDialogOpen(true);
        //}
        //else {
        //    setSelectedScheduleId(event.scheduleId);
        //}
        await loadSchedule(event.scheduleId);
        setDetailsDialogOpen(true);
    }

    function handleNavigate(newDate: Date, view: View, action: NavigateAction) {
        setCurrentDate(newDate);
    }

    // Pull more data based on the view
    async function handleRangeChange(range: Date[] | { start: stringOrDate; end: stringOrDate }, view: View | undefined)  {    
        if (Array.isArray(range)) {
            var tempArray = range as Date[];
            if (tempArray.length > 1) {
                // If the view is week we get an array of 7 dates, but we want EOD of the last day
                const weekRange = new DateRange(tempArray[0], dayjs(tempArray[6]).endOf("day").toDate());
                await getSchedulePage(weekRange);
            }
            else {
                // If the view is day we get an array with 1 day, but we want EOD of the day
                const day = tempArray[0];
                const dayRange = new DateRange(day, dayjs(day).endOf("day").toDate());        
                await getSchedulePage(dayRange);
            }
        }
        else {
             // If the view is month we get a start/end date, but we want EOD of the end date
            const tempRange = range as { start: stringOrDate; end: stringOrDate };                
            const monthRange = new DateRange(tempRange.start as Date, dayjs(tempRange.end as Date).endOf("day").toDate());
            await getSchedulePage(monthRange);
        }                 
    }

    const CustomToolbar = (props: ToolbarProps) => {        
        function onNavigateButtonClick(navigate: NavigateAction) {
            props.onNavigate(navigate);
        }

        function onViewButtonClick(event: React.MouseEvent<HTMLElement>, newViewButton: string) {
            props.onView(newViewButton as View);
        }

        return (            
            <Grid container direction="row" alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                <Grid item xs={3}>
                    <Stack direction="row">
                        <MuiIconButton
                            size="large"
                            aria-label="audit"
                            aria-haspopup="true"
                            color="primary"
                            onClick={() => onNavigateButtonClick("TODAY")}
                        >
                            <FirstPageIcon />
                        </MuiIconButton>
                        <MuiIconButton
                            size="large"                            
                            aria-label="audit"
                            aria-haspopup="true"
                            color="primary"
                            onClick={() => onNavigateButtonClick("PREV")}
                        >
                            <KeyboardArrowLeftIcon />
                        </MuiIconButton>
                        <MuiIconButton
                            size="large"
                            aria-label="audit"
                            aria-haspopup="true"
                            color="primary"
                            onClick={() => onNavigateButtonClick("NEXT")}
                        >
                            <KeyboardArrowRightIcon />
                        </MuiIconButton>
                        <Spacer/>
                        <Typography
                            alignSelf="center"
                            variant="button"
                            color="primary"
                        >
                            {props.label}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container item xs={6} justifyContent="center">
                    {/*<Stack direction="row">*/}
                    {/*    {scheduledLocations.map((location, index) => (*/}
                    {/*        <React.Fragment key={index}>*/}
                    {/*            <Box component="div" style={{ backgroundColor: getCellColor(location.code), width: 20, height: 20, display: "inline-block" }} />                              */}
                    {/*            <Typography variant="subtitle2" style={{ marginLeft: theme.spacing(1), display: "inline-block" }}>{location.internalName}</Typography>                                */}
                    {/*            <Spacer />                                */}
                    {/*        </React.Fragment>*/}
                    {/*    ))}*/}
                    {/*</Stack>*/}
                </Grid>
                <Grid container item xs={3} justifyContent="flex-end">
                    <Stack direction="row">
                        {checkAccess("content_promotion", Access.Create) &&
                            <>
                                <Button variant="contained" startIcon={<AddIcon />} sx={{ whiteSpace: 'nowrap' }} onClick={handleAddScheduleClick}>
                                    {strings.newSchedule}
                                </Button>
                            <Spacer x={2} />
                            </>
                        }
                        <ToggleButtonGroup
                            value={props.view}
                            exclusive
                            size="small"
                            onChange={onViewButtonClick}
                        >
                            <ToggleButton value="month">{strings.month}</ToggleButton>
                            <ToggleButton value="week">{strings.week}</ToggleButton>
                            <ToggleButton value="day">{strings.day}</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Grid>
            </Grid>            
        );
    }
    
    const dialogTitle = selectedScheduleId === "" ? strings.newScheduleTitle : strings.editScheduleTitle.replace("{{name}}", getLocationName(selectedSchedule?.locationCode) ?? ""); // TODO: default language

    function getLocationName(code: string) {
        return locations.find(l => l.code === code)?.internalName ?? "";
    }

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box>
                <Box style={{ height: theme.spacing(2) }}>    
                    { isPageLoading &&
                        <LinearProgress color={"primary"} variant={"query"} />
                    }
                </Box>
                <Box sx={{ height: 900, paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>                
                    <Calendar
                        localizer={localizer}
                        events={getEvents()}
                        date={currentDate}
                        startAccessor="start"
                        endAccessor="end"
                        components={
                            {
                                toolbar: CustomToolbar,
                                eventWrapper: ({ event, children }) => (
                                    <div
                                        onContextMenu={
                                            e => {
                                                handleScheduleRightClick(event, e);
                                                e.preventDefault();
                                            }
                                        }
                                    >
                                        {children}
                                    </div>
                                )
                            }
                        }
                        eventPropGetter={eventStyleGetter}
                        selectable={true}
                        onSelectSlot={handleSelectSlot}
                        onDoubleClickEvent={handleScheduleDoubleClick}
                        onNavigate={handleNavigate}
                        onRangeChange={handleRangeChange}
                    />
                    <Menu
                        id="contextMenu"
                        MenuListProps={{
                            'aria-labelledby': 'buttonAddItem',
                        }}
                        anchorEl={contextMenuAnchorEl}
                        open={contextMenuOpen}
                        onClose={handleContextMenuClose}
                    >
                        <MenuItem onClick={handleEditSelectedSchedule}>{checkAccess("content_promotion", Access.Update) ? strings.edit : strings.view}</MenuItem>
                        {checkAccess("content_promotion", Access.Delete) &&
                            <>
                                <MenuItem onClick={handleDeleteSelectedSchedule}>{strings.delete}</MenuItem>
                                <MenuItem onClick={handleDeleteAllSelectedSchedule}>{strings.deleteAll}</MenuItem>
                            </>
                        }
                    </Menu>
                </Box>

                <DetailsDialog
                    permissionKey="content_promotion"
                    adding={selectedScheduleId === ""}
                    open={detailsDialogOpen}
                    title={dialogTitle}
                    onCancelClick={handleCancelClick}
                    onValidateForm={handleValidateForm}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 590, height: 819.53 }}
                >
                    <Grid container direction="column">
                        <Grid item sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(2) }}>
                            <DialogSelect
                                label={strings.location}
                                keyValues={mapLocationsToKeyValues(locations)}
                                selectedValue={selectedSchedule?.locationCode ?? ""}
                                onChangeHandler={handleLocationChange}
                                sx={{ minWidth: 200 }}                                
                                error={Boolean(errors.locationCode)}
                                helperText={strings.validationPromotionScheduleLocation}
                            />
                            <Spacer />
                            <DialogSelect
                                label={strings.promotion}
                                keyValues={mapPromotionsToKeyValues(promotions)}
                                selectedValue={selectedSchedule?.promotionId ?? ""}
                                onChangeHandler={handlePromotionChange}
                                sx={{ minWidth: 200 }}                                
                                error={Boolean(errors.promotionId)}
                                helperText={strings.validationPromotionSchedulePromotion}
                            />
                            <Spacer />
                        </Grid>
                        <Grid item>
                            <Scheduler
                                json={selectedSchedule?.data ?? ""}
                                start={selectedScheduleId === "" ? (selectedSlot?.start as Date ?? undefined) : undefined}
                                end={selectedScheduleId === "" ? (selectedSlot?.end as Date ?? undefined) : undefined}                                
                                ref={schedulerRef}                                
                            />
                        </Grid>
                    </Grid>
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>
        );
    }
};

export default Schedule;
