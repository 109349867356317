import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { listProperties } from "../../../api/accounts/propertyApi";
import { listRoles } from "../../../api/security/roleApi";
import { createUser, deleteUser, getUser, listUsers, updateUser } from "../../../api/security/userApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Role } from "../../../models/configuration/security/Role";
import { User, UserProperty, UserRole, initialUserState } from "../../../models/configuration/security/User";
import { PropertyItemDetailModel } from "../../../models/propertyModels";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import DataGrid from "../../common/datatable/DataGridWrapper";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import TransferList from "../../common/details/TransferList";

interface ValidationErrors {
    username: string;
    firstName: string;
    lastName: string;
    displayName: string;
    jobTitle: string;
}

const UserList = () => {
    const initialErrorState: ValidationErrors = {
        username: "",
        firstName: "",
        lastName: "",
        displayName: "",
        jobTitle: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>(initialUserState);
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [tabValue, setTabValue] = useState<Number>(0);
    const [properties, setProperties] = useState<PropertyItemDetailModel[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();

    async function apiListUsers() {
        try {
            const response = await listUsers(axiosInstance);
            setUsers(response);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingUsers.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        async function apiListProperties() {
            try {
                const response = await listProperties(axiosInstance, true);
                setProperties(response);
                apiListRoles();
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingProperties.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        async function apiListRoles() {
            try {
                const response = await listRoles(axiosInstance);
                setRoles(response);
                apiListUsers();
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingRoles.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        if (properties.length === 0) {
            apiListProperties();
        }
    }, [strings.errorRetreivingProperties, strings.errorRetrievingRoles]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function handleAddClick() {
        setTabValue(0);
        setSelectedUserId(null);
        setSelectedUser(initialUserState);
        setDetailsDialogOpen(true);
    }

    const handleEditRowClick = async (id: string) => {
        setTabValue(0);
        setSelectedUserId(id);

        try {
            const response = (await getUser(axiosInstance, id) as User);
            setSelectedUser(response);
            setDetailsDialogOpen(true);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingUser.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleDeleteClick = (id: string) => {
        const username = users.find(u => u.id === id)?.username ?? "";

        alertDialog({
            title: strings.deleteUserAlertTitle,
            message: strings.deleteUserAlertMessage.replace("{{name}}", username),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedUser(id);
        })
    }

    const deleteSelectedUser = async (id: string) => {
        try {
            await deleteUser(axiosInstance, id);

            apiListUsers();

            setNotify({
                isOpen: true,
                message: strings.userDeletedSuccessfully,
                msgType: "success",
            });
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorDeletingUser.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });
        }
    };

    const dialogTitle = selectedUserId === null ? strings.securityManagerUserTitleAdd : strings.securityManagerUserTitleEdit.replace("{{name}}", selectedUser?.username ?? "");

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);

        try {
            setSelectedUser((prevState) => ({
                ...prevState,
                username: selectedUser.username.trim(),
                emailAddress: selectedUser.username.trim(),
                firstName: selectedUser.firstName.trim(),
                lastName: selectedUser.lastName.trim(),
                displayName: selectedUser.displayName !== undefined ? selectedUser.displayName.trim() : selectedUser.firstName.trim() + " " + selectedUser.lastName.trim(),
                jobTitle: selectedUser.jobTitle.trim(),
            }));

            if (selectedUser.id === undefined) {
                await createUser(axiosInstance, selectedUser);
            }
            else {
                await updateUser(axiosInstance, selectedUser);
            }

            apiListUsers();
            setDetailsDialogOpen(false);
            setTabValue(0);

            setNotify({
                isOpen: true,
                message: strings.userSavedSuccessfully,
                msgType: "success",
            });
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (selectedUser.id === null
                    ? strings.errorAddingUser
                    : strings.errorUpdatingUser
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }

        return true;
    }

    function handleValidate() {
        var errors = validate<User, ValidationErrors>([
            { property: "username", type: ValidationType.Email, message: strings.validationErrorUserNameEmailNotValid },
            { property: "firstName", type: ValidationType.Required, message: strings.validationErrorFirstNameRequired },
            { property: "lastName", type: ValidationType.Required, message: strings.validationErrorLastNameRequired },
            { property: "jobTitle", type: ValidationType.Required, message: strings.validationErrorJobTitleRequired },
            { property: "displayName", type: ValidationType.Required, message: strings.validationErrorDisplayNameRequired }
        ], selectedUser);
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

    const formatRolesColumn = (row: any): string => {
        let rolesString: string = ""

        row.roles.forEach((r: { id: string, name: string; }) => rolesString += r.name + ", ")
        rolesString = rolesString.substring(0, rolesString.length - 2)

        return rolesString
    }

    function handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedUser((prevState) => ({
            ...prevState,
            username: event.target.value,
            emailAddress: event.target.value,
        }));
    }

    function handleFirstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedUser((prevState) => ({
            ...prevState,
            firstName: event.target.value,
        }));
    }

    function handleLastNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedUser((prevState) => ({
            ...prevState,
            lastName: event.target.value,
        }));
    }

    
    function handleDisplayNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedUser((prevState) => ({
            ...prevState,
            displayName: event.target.value,
        }));
    }

    function handleJobTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedUser((prevState) => ({
            ...prevState,
            jobTitle: event.target.value,
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedUser((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    const convertRolesToKeyValue = (roles: Role[]): { key: string; value: string }[] => {
        let kvps: { key: string; value: string }[] = []

        roles.forEach(r => kvps.push({ key: r.id!!, value: r.name }))

        return kvps
    }

    const convertPropertiesToKeyValue = (properties: PropertyItemDetailModel[]): { key: string; value: string }[] => {
        let kvps: { key: string; value: string }[] = []
        
        properties.forEach(p => kvps.push({key: p.id, value: p.name["en"]}))
    
        return kvps
    }

    const convertUserRolesToKeyValue = (roles: UserRole[]): { key: string; value: string }[] => {
        let kvps: { key: string; value: string }[] = []

        roles.forEach(r => kvps.push({ key: r.id, value: r.name!! }))

        return kvps
    }

    const convertUserPropertiesToKeyValue = (properties: UserProperty[]): { key: string; value: string }[] => {
        let kvps: { key: string; value: string }[] = []

        properties.forEach(p => kvps.push({key: p.code, value: p.name!!["en"]!!}))

        return kvps
    }

    function handleRolesChange(values: { key: string; value: string }[]) {
        const roles: UserRole[] = []

        values.forEach(v => {
            const role: UserRole = {
                id: v.key,
                name: v.value
            }

            roles.push(role);
        });

        setSelectedUser((prevState) => ({
            ...prevState,
            roles: roles,
        }));
    }

    function handlePropertiesChange(values: { key: string; value: string }[]) {
        const properties: UserProperty[] = []

        values.forEach(v => {
            const property: UserProperty = {
                code: v.key,
                name: { "en": v.value }
            }

            properties.push(property);
        });

        setSelectedUser((prevState) => ({
            ...prevState,
            properties: properties,
        }));
    }

    const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };

    function renderDateTimeCell(lastSignedIn: any): string {
        if (!lastSignedIn) return "";

        return new Date(lastSignedIn).toLocaleDateString(strings.getLanguage(), dateOptions);
    }
    
    const gridColumns: GridColDef[] = [        
        { field: "username", headerName: strings.userNameColumn, flex: 1 },
        { field: "displayName", headerName: strings.displayNameColumn, flex: 1 },
        { field: "jobTitle", headerName: strings.jobTitleColumn, flex: 1},
        { field: "roles", headerName: strings.rolesColumn, valueGetter: (value, row) => formatRolesColumn(row), flex: 1 },
        { field: "enabled", headerName: strings.enabled, type: "boolean", flex: 1 },
        { field: "lastSignedIn", headerName: strings.lastSignedIn, renderCell: (params) => renderDateTimeCell(params.row.lastSignedIn), sortingOrder: ["asc", "desc"], flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex:1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="security_user" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
                  />,
                <DataGridDeleteButton
                    permissionKey="security_user"
                    requiresSuperAdmin={true}
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
            ],
        },
    ]; 

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}> 
            <DataGrid
                permissionKey="security_user"
                getRowId={(row) => row.id} 
                rows={users}
                columns={gridColumns}                    
                onAddButtonClick={handleAddClick}
                addButtonText={strings.userAddButtonText}
                loading={isLoading}
            />

            <DetailsDialog
                permissionKey="security_user"
                adding={selectedUserId === null}
                open={detailsDialogOpen}
                title={dialogTitle}
                onValidateForm={handleValidate}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                contentSize={{ width: 800, height: 533.93 }}>

                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label={strings.details} />
                        <Tab label={strings.rolesTabLabel} />
                        <Tab label={strings.propertiesTabLabel} />
                    </Tabs>
                </Box>
                <TabPanelKeepMounted value={tabValue} index={0}>
                    <TextField
                        id="user-name"
                        label={strings.userNameLabel}
                        width={400}
                        disabled={selectedUserId !== null ? true : false}
                        value={selectedUser.username}
                        onChange={handleUserNameChange}
                        error={Boolean(errors.username)}
                        helperText={errors.username} />
                    <Spacer />
                    <TextField
                        id="first-name"
                        label={strings.firstNameLabel}
                        width={200}
                        value={selectedUser.firstName}
                        onChange={handleFirstNameChange}
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName} />
                    <Spacer />
                    <TextField
                        id="last-name"
                        label={strings.lastNameLabel}
                        width={200}
                        value={selectedUser.lastName}
                        onChange={handleLastNameChange}
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName} />                    
                    <Spacer />
                    <TextField
                        id="display-name"
                        label={strings.displayNameLabel}
                        width={400}
                        value={selectedUser.displayName === undefined ? "" : selectedUser.displayName!!}
                        onChange={handleDisplayNameChange}
                        error={Boolean(errors.displayName)}
                        helperText={errors.displayName} />
                    <Spacer />                    
                    <TextField
                        id="job-title"
                        label={strings.jobTitleLabel}
                        width={400}
                        value={selectedUser.jobTitle}
                        onChange={handleJobTitleChange}
                        error={Boolean(errors.jobTitle)}
                        helperText={errors.jobTitle} />
                    
                    <Checkbox
                        label={strings.enabled}
                        checked={selectedUser.enabled}
                        onChange={handleEnabledChange}
                    />
                    <Spacer />
                </TabPanelKeepMounted>
                <TabPanelKeepMounted value={tabValue} index={1}>
                    <Spacer />
                    <TransferList
                        id={selectedUser.id}
                        leftKeyValues={convertRolesToKeyValue(roles)}
                        rightKeyValues={convertUserRolesToKeyValue(selectedUser.roles)}
                        onChangeHandler={handleRolesChange}
                        height={426.53}
                    />
                </TabPanelKeepMounted>
                <TabPanelKeepMounted value={tabValue} index={2}>
                    <Spacer />
                    <TransferList
                        id={selectedUser.id}
                        leftKeyValues={convertPropertiesToKeyValue(properties)}
                        rightKeyValues={convertUserPropertiesToKeyValue(selectedUser.properties)}
                        onChangeHandler={handlePropertiesChange}
                        height={426.53}
                    />
                </TabPanelKeepMounted>

            </DetailsDialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );    
};

export default UserList;
