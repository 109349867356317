import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { getTemplates } from "../../../api/messaging/templatesApi";
import { getSchedules, createSchedule, updateSchedule, deleteSchedule } from "../../../api/messaging/schedulesApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { initialScheduledMessageState, Schedule as ScheduleModel } from "../../../models/modules/messaging/Schedule";
import { mapTemplatesToKeyValues, Template as TemplateModel } from "../../../models/modules/messaging/Template";
import { useUser } from "../../../providers/UserProvider";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import { default as DialogSelect } from "../../common/details/Select";
import GuestSelector, { parseData as parseToData } from "../../common/GuestSelector";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import { default as Scheduler, Recurrence, SchedulerRefObject } from "../../common/Scheduler";
import Spacer from "../../common/Spacer";
import { v4 as uuid } from "uuid";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import useGuestSelectorData from "../../../hooks/useGuestSelectorData";
import { GuestSelectorData } from "../../../models/common/GuestSelectorData";
import RttIcon from '@mui/icons-material/Rtt';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import Box from '@mui/material/Box';

interface ValidationErrors {
    templateId: string,
    toData: string;      
}

const Scheduled = () => {
    const initialErrorState: ValidationErrors = {
        templateId: "",
        toData: ""        
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [gridRefresh, setGridRefresh] = useState(false);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [templates, setTemplates] = useState<TemplateModel[]>([]);
    const [schedules, setSchedules] = useState<ScheduleModel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [tabValue, setTabValue] = useState<number>(0);
    const [selectedMessageId, setSelectedMessageId] = useState("");
    const [selectedSchedule, setSelectedSchedule] = useState<ScheduleModel>(initialScheduledMessageState);
    const [isLoading, setIsLoading] = useState(true);        
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);    
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();  
    const schedulerRef = useRef<SchedulerRefObject>(null);
    const {selectorIdsToValues, formattedSelectorValues} = useGuestSelectorData("en");

    useEffect(() => {
        async function loadSchedules() {
            try {
                const payload = await getSchedules(axiosInstance, user.currentProperty?.code ?? "");
                setSchedules(payload);
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingMessageSchedules.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }            

            try {
                const payload = await getTemplates(axiosInstance, user.currentProperty?.code ?? "");
                setTemplates(payload);
            }
            catch (error: unknown) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingTemplates.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        loadSchedules();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingMessageSchedules, strings.errorRetreivingTemplates]);

    function handleNewMessageClick(event: React.MouseEvent<HTMLButtonElement>) {        
        setSelectedMessageId("");
        setSelectedSchedule(initialScheduledMessageState);
        setDetailsDialogOpen(true);
    }

    function handleEditClick(id: string) {        
        setSelectedMessageId(id);
        var schedule = schedules?.find(m => m.id === id);
        if (schedule) {
            setSelectedSchedule(schedule);
            setDetailsDialogOpen(true);
        }
    }    

    function handleDeleteClick(id: string) {
        alertDialog({
            title: strings.deleteMessageScheduleAlertTitle,
            message: strings.deleteMessageScheduleAlertMessage,
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedSchedule(id);
        });
    }

    async function deleteSelectedSchedule(id: string) {
         try {
            await deleteSchedule(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.errorDeletingMessageSchedule.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.messageScheduleDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedMessageId("");
        setSelectedSchedule(initialScheduledMessageState);
    }

    function dataToString(json: string) {       
        var o = JSON.parse(json);
        const startTimeString = (new Date(o.data.startTime)).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'});

        switch (o.recurrence) {
            case Recurrence.Once:
                return `${strings.once}: @${(new Date(o.data.startDateTime)).toLocaleTimeString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute:'2-digit'})}`;
            case Recurrence.Daily:
                return `${strings.daily}: @${startTimeString}`;
            case Recurrence.Weekly:
                return `${strings.weekly}: ${getWeekString(o.data)} @${startTimeString}`;                
            case Recurrence.Monthly:
                return `${strings.monthly}: ${o.data.day} @${startTimeString}`;                
            case Recurrence.Yearly:
                return `${strings.yearly}: ${getMonthString(o.data.month)}, ${o.data.day} @${startTimeString}`;
            default:
                return "";
        }    
    }

    function getWeekString(o: any) {
        var days = [];
        if (o.monday) {
            days.push(strings.monday);
        }
        if (o.tuesday) {
            days.push(strings.tuesday);
        }
        if (o.wednesday) {
            days.push(strings.wednesday);
        }
        if (o.thursday) {
            days.push(strings.thursday);
        }
        if (o.friday) {
            days.push(strings.friday);
        }
        if (o.saturday) {
            days.push(strings.saturday);
        }
        if (o.sunday) {
            days.push(strings.sunday);
        }

        return days.join(", ");
    }

    function getMonthString(month: string) {
        switch (month) {
            case "1":
                return strings.january;
            case "2":
                return strings.february;
            case "3":
                return strings.march;
            case "4":
                return strings.april;
            case "5":
                return strings.may;
            case "6":
                return strings.june;
            case "7":
                return strings.july;
            case "8":
                return strings.august;
            case "9":
                return strings.september;
            case "10":
                return strings.october;
            case "11":
                return strings.november;
            case "12":
                return strings.december;
            default:
                return "";
        }
    }

    function getTemplateName(id: string) {
        var name = "";
        const template = templates.find(t => t.id === id);
        if (template) {
            name = template.internalName;
        }
        return name;
    }

    function buildNameField(templateId: string) {
        const template = templates.find(t => t.id === templateId);
        return (
            <>
                <span style={{ marginRight: theme.spacing(1) }}>{getIcon(template?.type ?? "Text")}</span>
                <span>{template?.internalName}</span>
            </>
        );
    }

    const gridColumns: GridColDef[] = [                          
        { field: "templateInternalName", headerName: strings.messageTemplateName, renderCell: (params) => buildNameField(params.row.templateId), flex: 1 },   
        { field: "to", headerName: strings.to, valueGetter: (value, row) => formattedSelectorValues(selectorIdsToValues(row.toData), strings), flex: 3 },           
        { field: "recurrence", headerName: strings.recurrence, valueGetter: (value, row) => dataToString(row.data), flex: 3 }, 
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_messaging" 
                    rowId={params.id.toString()} 
                    clickHandler={handleEditClick} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_messaging"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleDeleteClick(params.id.toString())}
                />
            ],
        },
    ];

    const dialogTitle = selectedMessageId === "" ? strings.newMessageScheduleTitle : strings.editMessageScheduleTitle;

    function handleTemplateChange(event: SelectChangeEvent) {       
        setSelectedSchedule((prevState) => ({
            ...prevState,
            templateId: event.target.value as string
        }));
    }

    function handleFilterAccept(data: GuestSelectorData) {
        setSelectedSchedule((prevState) => ({
            ...prevState,
            toData: data
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);        
    }

    function handleValidate() { 
        setErrors(initialErrorState);

        var scheduleValid = schedulerRef?.current?.validate() ?? false;
        var valid = true;        

        var errors = validate<ScheduleModel, ValidationErrors>([
            { property: "templateId", type: ValidationType.Required, message: strings.validationMessageScheduleTemplate }                              
        ], selectedSchedule);
                
        if (selectedSchedule.toData === null && errors !== null) {
            errors.toData = strings.validationMessageScheduleTo;
        }
        
        if (errors) {
            setErrors(errors);
            valid = false;
        }

        return scheduleValid && valid;
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        var json = schedulerRef?.current?.getJson();

        if (!json || json === "") {
            return false;
        }
         
        try {
            if (selectedMessageId === "") {
                var newSchedule: ScheduleModel = {
                    ...selectedSchedule,
                    id: uuid(),
                    data: json
                };
                    
                await createSchedule(axiosInstance, user.currentProperty?.code ?? "", newSchedule)
            }
            else {
                var newSchedule: ScheduleModel = {
                    ...selectedSchedule,                        
                    data: json
                };

                await updateSchedule(axiosInstance, user.currentProperty?.code ?? "", newSchedule);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (selectedMessageId === "" ? strings.errorAddingMessageSchedule : strings.errorUpdatingMessageSchedule).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }

        setSelectedMessageId("");
        setGridRefresh(!gridRefresh);
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
        setNotify({
            isOpen: true,
            message: strings.messageScheduleSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }    

    function getIcon(type: string) {
        switch (type) {            
            case "Image":
                return (<ImageIcon fontSize="small" />);
            case "Video":
                return (<VideocamIcon fontSize="small" />);
            case "Text":
            default:
                return (<RttIcon fontSize="small" />);
        }
    }

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}>           
            <DataGrid
                permissionKey="content_messaging"
                rows={schedules}
                columns={gridColumns}
                onAddButtonClick={handleNewMessageClick}
                addButtonText={strings.newSchedule}
                loading={isLoading}
            />               

            <DetailsDialog
                permissionKey="content_messaging"
                adding={selectedMessageId === ""}
                open={detailsDialogOpen}
                title={dialogTitle}
                onCancelClick={handleCancelClick}
                onValidateForm={handleValidate}
                onSaveClick={handleSaveClick}
                contentSize={{ width: 590, height: 769.53 }}
            >
                <Grid container direction="column">
                    <Grid item sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(2) }}>
                        <DialogSelect
                            label={strings.messageTemplate}                                
                            keyValues={mapTemplatesToKeyValues(templates.filter(t => t.enabled === true)).map(kvp => {
                                return {
                                    key: kvp.key,
                                    value: kvp.value.name,
                                    icon: getIcon(kvp.value.type)
                                };
                            })}
                            selectedValue={selectedSchedule?.templateId ?? ""}
                            onChangeHandler={handleTemplateChange}
                            sx={{ minWidth: 300 }}                                
                            error={Boolean(errors.templateId)}
                            helperText={errors.templateId}
                        />
                        <Spacer />
                        <GuestSelector
                            id="message-to"
                            label={strings.to}                                
                            filterData={selectedSchedule?.toData ?? {}}
                            width={558}                                
                            onFilterAccept={handleFilterAccept}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {}}     
                            permissionKey="content_messaging"
                            error={Boolean(errors.toData)}
                            helperText={errors.toData}
                        />
                        <Spacer />
                    </Grid>
                    <Grid item>
                        <Scheduler
                            json={selectedSchedule?.data ?? ""}
                            startLabel={strings.sendTime}
                            startHelperText={strings.validationMessageSendTime}
                            hideEnd={true}
                            initialRecurrence={1}
                            ref={schedulerRef}                                
                        />
                    </Grid>
                </Grid>
            </DetailsDialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>           
    )    
}

export default Scheduled;