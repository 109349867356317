import ApartmentIcon from "@mui/icons-material/Apartment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CopyrightIcon from "@mui/icons-material/Copyright";
import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home";
import HotelIcon from "@mui/icons-material/Hotel";
import InfoIcon from "@mui/icons-material/Info";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MailIcon from "@mui/icons-material/Mail";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from "@mui/icons-material/Menu";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SettingsIcon from "@mui/icons-material/Settings";
import SpeedIcon from "@mui/icons-material/Speed";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';
import PaletteIcon from '@mui/icons-material/Palette';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import WidgetsIcon from "@mui/icons-material/Widgets";
import SummarizeIcon from '@mui/icons-material/Summarize';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import MuiIconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton  from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { alpha, CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import * as React from "react";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Avatar from "../../components/common/Avatar";
import SearchField, { SearchEvent } from "../../components/common/search/SearchField";
import Spacer from "../../components/common/Spacer";
import CompanyManager from "../administration/company/CompanyManager";
import MenuManager from "../../components/configuration/menus/MenuManager";
import SecurityManager from "../../components/configuration/security/SecurityManager";
import InformationList from "../../components/modules/generalinformation/InformationList";
import Activities from "../../components/modules/activities/Activities";
import Promotions from "../../components/modules/promotions/Promotions";
import Messaging from "../../components/modules/messaging/Messaging";
import RoomManager from "../../components/modules/roommanager/RoomManager";
import ReportingHome from "../dashboard/reporting/itv/ReportingHome";
import AdminSecurityManager from "../configuration/security/admin/AdminSecurityManager";
import Restaurants from "../modules/restaurants/Restaurants";
import CustomerList from "../configuration/security/admin/CustomerList";
import FeatureList from "../configuration/security/admin/FeatureList";
import Home from "../../components/wrapper/Home";
import NotAuthorized from "./NotAuthorized";
import Languages from "../configuration/settings/Languages";
import Configuration from "../administration/configuration/Configuration";
import LanguageIcon from '@mui/icons-material/Language';
import LogoImage from "../../images/bbhlogo.png";
//import LogoImage from "../../images/logo.png";
import { useLocalizedStrings } from "../../localization/LocalizedStringsProvider";
import themePrimary from "../../styles/themePrimary";
import { FormControl, Grid, InputLabel, Popover, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useAuth } from "oidc-react";
import SupportHome from "../dashboard/support/SupportHome";
import Network from "../dashboard/network/Network";
import Gateway from "../dashboard/gateway/Gateway";
import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import BuildIcon from '@mui/icons-material/Build';
import { useUser, FeatureType } from "../../providers/UserProvider";
import { Access } from "../../models/configuration/security/Permission";
import { convertUserBrandPropertiesToKeyValue, UserBrand, UserProperty, UserBrandPropertiesKeyValue, initialUserState } from "../../models/configuration/security/User";
import SkinManager from "../administration/skin/SkinManager";
import ListSubheader from "@mui/material/ListSubheader";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import BusinessIcon from '@mui/icons-material/Business';
import LayersIcon from '@mui/icons-material/Layers';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Locations from "../configuration/settings/Locations";
import Services from "../modules/guestservices/Services";
import { getCurrentUser } from "../../api/security/userApi";
import PropertyClock from "../common/PropertyClock";
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import VideoList from "../modules/videolibrary/VideoList";
import RoomService from "../modules/roomservice/RoomService";
import RoomServiceIcon from '@mui/icons-material/RoomService';
import TrafficIcon from '@mui/icons-material/Traffic';
import Controller from "../administration/pms/Controller";
import PeopleIcon from '@mui/icons-material/People';
import GuestList from "../modules/roommanager/GuestList";
import ErrorIcon from '@mui/icons-material/Error';
import ErrorMessages from "../administration/configuration/ErrorMessages";

const DRAWER_WIDTH = 320;
const ADMIN_TENANT_ID = "admin";

// for adding a new typography variant:
declare module "@mui/material/Typography" {
  interface TypographyVariants {
    copyright: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    copyright?: React.CSSProperties;
  }

  interface TypographyPropsVariantOverrides {
    copyright: true;
  }
}

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        logo: {
            borderStyle: "none",
            width: "220px",
            marginLeft: "50px",
            marginRight: "50px",
        },
        logoOn: {
            opacity: 1,
            transition: themePrimary.transitions.create("opacity", {
                easing: themePrimary.transitions.easing.sharp,
                duration: themePrimary.transitions.duration.leavingScreen,
            }),
        },
        logoOff: {
            opacity: 0,
            transition: themePrimary.transitions.create("opacity", {
                easing: themePrimary.transitions.easing.sharp,
                duration: themePrimary.transitions.duration.leavingScreen,
            }),
        },
        muiListItemRoot: {
            "& .MuiListItemText-root": {
                color: themePrimary.palette.secondary.main
            },
            "& .Mui-selected": {
                background: alpha("#FFFFFF", 0.0), // This is being overridden by .css-na941s-MuiListItem-root.Mui-selected, and I can't figure out how to remove
                "& .MuiListItemText-root": {
                    color: themePrimary.palette.primary.main
                },
                "& .MuiListItemIcon-root": {
                    color: themePrimary.palette.primary.main
                }
            },            
            "& .MuiListItemButton-root:hover, & .MuiListItemButton-root.Mui-selected:hover": {     
                backgroundColor: themePrimary.palette.primary.light,
                "& .MuiListItemText-root": {
                    color: themePrimary.palette.primary.contrastText
                },
                "& .MuiListItemIcon-root": {
                    color: themePrimary.palette.primary.contrastText
                }
            },
        },
        propertySelect: {           
            "& .MuiSelect-outlined": {
                color: "#ffffff",
                fontWeight: "500"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
            },
            "& .MuiSelect-icon": {
                color: "#ffffff"
            }
        },
        propertySelectContainer: {
            display: 'flex',
            marginRight: "8px",
        },
        propertySelectLabel: {
            marginTop: 'auto !important',
            marginBottom: 'auto !important',
        },
        tabUnselected: {
            color: themePrimary.palette.secondary.main
        },
        tabSelected: {
            color: themePrimary.palette.primary.main
        },
        userPopover: {
            padding: '10px',
        },
    })
);

const openedMixin = (theme: Theme): CSSObject => ({
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ///...theme.mixins.toolbar,
    minHeight: 64 // The above line is not building
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

enum DrawerTabs {
    CMS = 0,
    Support = 1,    
    Settings = 2,
    Admin = 3,
    Reporting = 4
}

enum DrawerItems {
    CompanyManager,
    AccountManager,
    RoomManager,
    MenuManager,    
    Information,
    Dining,
    GuestServices,
    Activities,
    Messaging,
    Promotions,
    ImageOnly,
    Languages,
    Locations,
    Settings,
    Support,
    Network,
    Gateway,
    Skinning,
    Configuration,
    Reporting,
    CustomerManager,
    FeatureManager,
    VideoLibrary,
    RoomService,
    PMSController,
    Guests,
    ErrorMessages
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    }),
);

interface DrawerState {
    open: boolean;
    selectedDrawerTab: number;
    selectedDrawerListItemIndex: number;
}

const Layout =  () => {
    const theme = useTheme();
    const classes = useStyles();
    const strings = useLocalizedStrings();
    const drawerState = getDrawerState();
    const [open, setOpen] = React.useState(drawerState.open);
    const [wasOpen, setWasOpen] = React.useState(open);
    const [brandProperties, setBrandProperties] = React.useState<UserBrandPropertiesKeyValue[]>([]);
    const [userPopoverAnchorEl, setUserPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);    
    const [selectedDrawerTab, setSelectedDrawerTab] = React.useState(drawerState.selectedDrawerTab);
    const [selectedDrawerListItemIndex, setSelectedDrawerListItemIndex] = React.useState(drawerState.selectedDrawerListItemIndex);
    const [title, setTitle] = React.useState(strings.homeTitle);
    const { user, setUser, checkAccess, hasIncludedFeatures, hasIncludedPropertyFeatures, isSuperAdmin, isAdminCustomer } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();    

    React.useEffect(() => {
        const temp = getTabAndIndex();
        setSelectedDrawerTab(temp.tab);
        setSelectedDrawerListItemIndex(temp.index);
    }, []);

    React.useEffect(() => {
        if (window.location.pathname !== "/signin-callback") {
            window.localStorage.setItem('pathName', window.location.pathname);
        }

        setBrandProperties(getBrandPropertiesForSelect());

        // when any page is reloaded the app title resets to home. 
        // hack to detect a reload and then check the route and reset
        // the title back to what it was. There's probably a better way.
        if (performance.navigation.TYPE_RELOAD) {
            if (checkPath('/menumanager')) {
                setTitle(strings.drawerMenuManager);
            }
            else if (checkPath('/promotions')) {
                setTitle(strings.drawerPromotions);
            }   
            else if (checkPath('/videolibrary')) {
                setTitle(strings.drawerVideoLibrary);               
            }
            else if (checkPath('/information')) {
                setTitle(strings.drawerInformation);
            }
            else if (checkPath('/restaurants')) {
                setTitle(strings.drawerDining);
            } 
            else if (checkPath('/activities')) {
                setTitle(strings.drawerActivities);                
            }
            else if (checkPath('/messaging')) {
                setTitle(strings.drawerMessaging);
            }
            else if (checkPath('/guestservices')) {
                setTitle(strings.drawerGuestServices);                
            }                        
            else if (checkPath('/roomservice')) {
                setTitle(strings.drawerRoomService);             
            }
            else if (checkPath('/support')) {
                setTitle(strings.drawerSupport);
            }
            else if (checkPath('/network')) {
                setTitle(strings.drawerNetwork);
            }
            else if (checkPath('/gateway')) {
                setTitle(strings.drawerGateway);
            }
            else if (checkPath('/reporting/itv')) {
                setTitle(strings.drawerReportingITV);
            }            
            else if (checkPath('/roommanager')) {
                setTitle(strings.drawerRoomManager);
            }            
            else if (checkPath('/languages')) {
                setTitle(strings.drawerLanguages);
            }
            else if (checkPath('/locations')) {
                setTitle(strings.drawerLocations);
            }
            else if (checkPath('/company')) {
                setTitle(strings.drawerCompanyManager);
            }
            else if (checkPath('/security/admin/customers')) {
                setTitle(strings.drawerCustomers);
            }
            else if (checkPath('/security/admin/features')) {
                setTitle(strings.drawerFeatures);
            }          
            else if (checkPath('/security')) {
                setTitle(strings.drawerSecurity);
            }
            else if (checkPath('/skin')) {
                setTitle(strings.drawerSkinning);
            }
            else if (checkPath('/configuration')) {
                setTitle(strings.drawerConfiguration);
            }                                           
            else if (checkPath('/pmscontroller')) {
                setTitle(strings.drawerPMSController);             
            }
            else {
                setTitle(strings.homeTitle);                
            }
        }                

    }, [location.pathname, strings.drawerSettings, strings.drawerInformation, strings.drawerCompanyManager, strings.drawerRoomManager, strings.drawerSecurity, strings.homeTitle]);

    React.useEffect(() => {
        // Persist the selected drawer "tab" and item
        const drawerState: DrawerState = {
            open: open,
            selectedDrawerTab: selectedDrawerTab,
            selectedDrawerListItemIndex: selectedDrawerListItemIndex
        }

        window.localStorage.setItem('drawerState', JSON.stringify(drawerState));
    }, [open, selectedDrawerTab, selectedDrawerListItemIndex]);

    React.useEffect(() => {
        if (!auth || !auth.userData) {
            setOpen(false);
        }
        else {
            setOpen(wasOpen);
        }
    }, [auth]);

    const showCMS = () => hasIncludedFeatures(["ITV", "MobileApp"]) && (checkAccess("content_menu", Access.Read, true) || checkAccess("content_promotion", Access.Read, true) || checkAccess("content_information", Access.Read, true) || checkAccess("content_messaging", Access.Read, true) || checkAccess("content_activities", Access.Read, true) || checkAccess("content_restaurant", Access.Read, true) || checkAccess("content_guestservices", Access.Read, true) || checkAccess("content_roomservice", Access.Read, true));
    const showDashboard = () => checkAccess("dashboard_guests", Access.Read, true) || checkAccess("dashboard_support", Access.Read, false) || checkAccess("dashboard_network", Access.Read, false) || checkAccess("dashboard_gateway", Access.Read, true);
    const showReports = () => hasIncludedFeatures(["ITV"]) && checkAccess("reporting_itv", Access.Read, true);
    const showSettings = () => checkAccess("configuration_property", Access.Read, true) || checkAccess("security_user", Access.Read, false)|| checkAccess("security_role", Access.Read, false) || checkAccess("configuration_settings", Access.Read, true);
    const showAdmin = () => checkAccess("administration_company", Access.Read, false) || checkAccess("administration_skin", Access.Read, false) || checkAccess("administration_configuration", Access.Read, true) || checkAccess("administration_pmscontroller", Access.Read, false, false);

    function getDrawerState() {
        const emptyDrawerState: DrawerState = {
            open: false,
            selectedDrawerTab: -1,
            selectedDrawerListItemIndex: -1
        }

        // Get the persisted drawer "tab" and item, or default
        const temp = window.localStorage.getItem('drawerState');
        const drawerState: DrawerState = temp ? JSON.parse(temp) : emptyDrawerState;

        return drawerState;
    }

    function checkPath(pathname: string) {        
        return location.pathname.startsWith(pathname);
    }

    // Get the "tab" and item from the url
    function getTabAndIndex(): { tab: number, index: number } {
        if (checkPath('/menumanager')) {
            return { tab: DrawerTabs.CMS, index: DrawerItems.MenuManager };
        }
        else if (checkPath('/promotions')) {
            return { tab: DrawerTabs.CMS, index: DrawerItems.Promotions };
        }
        else if (checkPath('/videolibrary')) {
            return { tab: DrawerTabs.CMS, index: DrawerItems.VideoLibrary };
        }
        else if (checkPath('/information')) {
            return { tab: DrawerTabs.CMS, index: DrawerItems.Information };
        }
        else if (checkPath('/restaurants')) {
            return { tab: DrawerTabs.CMS, index: DrawerItems.Dining }
        }
        else if (checkPath('/activities')) {
            return { tab: DrawerTabs.CMS, index: DrawerItems.Activities }
        }        
        else if (checkPath('/messaging')) {
            return { tab: DrawerTabs.CMS, index: DrawerItems.Messaging };
        }
        else if (checkPath('/guestservices')) {
            return { tab: DrawerTabs.CMS, index: DrawerItems.GuestServices }
        }
        else if (checkPath('/roomservice')) {
            return { tab: DrawerTabs.CMS, index: DrawerItems.RoomService }
        }
        else if (checkPath('/support')) {
            return { tab: DrawerTabs.Support, index: DrawerItems.Support };
        }
        else if (checkPath('/network')) {
            return { tab: DrawerTabs.Support, index: DrawerItems.Network };
        }
        else if (checkPath('/gateway')) {
            return { tab: DrawerTabs.Support, index: DrawerItems.Gateway };
        }
        else if (checkPath('/reporting/itv')) {
            return { tab: DrawerTabs.Reporting, index: DrawerItems.Reporting }
        }
        else if (checkPath('/security')) {
            return { tab: DrawerTabs.Settings, index: DrawerItems.AccountManager };
        }
        if (checkPath('/roommanager')) {
            return { tab: DrawerTabs.Settings, index: DrawerItems.RoomManager };
        }
        else if (checkPath('/languages')) {
            return { tab: DrawerTabs.Settings, index: DrawerItems.Languages };
        }
        else if (checkPath('/locations')) {
            return { tab: DrawerTabs.Settings, index: DrawerItems.Locations };
        }
        else if (checkPath('/security/admin/customers')) {
            return { tab: DrawerTabs.Settings, index: DrawerItems.CustomerManager }
        }
        else if (checkPath('/security/admin/features')) {
            return { tab: DrawerTabs.Settings, index: DrawerItems.FeatureManager }
        }
        else if (checkPath('/company')) {
            return { tab: DrawerTabs.Admin, index: DrawerItems.CompanyManager };
        }                
        else if (checkPath('/skin')) {
            return { tab: DrawerTabs.Admin, index: DrawerItems.Skinning };
        }
        else if (checkPath('/configuration')) {
            return { tab: DrawerTabs.Admin, index: DrawerItems.Configuration };
        }        
        else if (checkPath('/pmscontroller')) {
            return { tab: DrawerTabs.Admin, index: DrawerItems.PMSController }
        }
        else {
            if (showCMS()) {
                return { tab: DrawerTabs.CMS, index: -1 };
            }
            else if (showDashboard()) {
                return { tab: DrawerTabs.Support, index: -1 };
            }            
            else if (showSettings()) {
                return { tab: DrawerTabs.Settings, index: -1 };
            }
            else if (showAdmin()) {
                return { tab: DrawerTabs.Admin, index: -1 };
            }
            else if (showReports()) {
                return { tab: DrawerTabs.Reporting, index: -1 };
            }
            else {
                return { tab: -1, index: -1 }   
            }
        }
    }    

    function clearSelection() {
        setSelectedDrawerListItemIndex(-1);
        setTitle(strings.homeTitle);
    }

    const handlHomeClick = (event: React.MouseEvent<HTMLElement>) => {
        clearSelection();
    };

    const handleHelpClick = (event: React.MouseEvent<HTMLElement>) => {
        //setAnchorEl(event.currentTarget);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
        setWasOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setWasOpen(false);
    };

    const handleDrawerTabClicked = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setSelectedDrawerTab(index);
    }

    const handleDrawerListItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setSelectedDrawerListItemIndex(index);
        setTitle((event.currentTarget as HTMLElement).innerText);
    };

    const handleSearchSubmit = (event: SearchEvent<HTMLInputElement>) => {
    }

    const handleSearchKeyPress = (event: SearchEvent<HTMLInputElement>) => {
        alert(event.target.value);
    }

    const handleUserPopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserPopoverAnchorEl(event.currentTarget);
      };
    
      const handleUserPopoverClose = () => {
        setUserPopoverAnchorEl(null);
      };

    const signIn = () => {
        // force call to get current user whenever user signs in
        auth.signIn().then(() => {
            setUser(initialUserState);
        });

        setSelectedDrawerTab(0);
        setSelectedDrawerListItemIndex(-1);
    }

    const signOut = () => {
        auth.signOutRedirect();
    }

    function handlePropertySelectChange(e: SelectChangeEvent) {         
        changeProperty(e.target.value as string);
    }

    function changeProperty(code: string) {
        var currentUser = user;
        currentUser.currentProperty = flattenBrandProperties(user.brands).find(p => p.code === code) as UserProperty;
        setUser(currentUser);

        /*
        var currentUser = user;
        var reloadRequired = false;

        // HACK: There doesn't seem to be a way have different "types" of MenuItem. I thought I could maybe use className="brand" or className="property" but
        // class name is not available on the target. So prefix each value with either brand_ or property_.
        const split = code.split('_');
        if (split[0] === "brand") {
            // If switching from property to brand
            reloadRequired = currentUser.currentBrand === undefined;

            currentUser.currentBrand = user.brands.find(b => b.code === split[1]);
            currentUser.currentProperty = undefined;
        }
        else {
            // If switching from brand to property
            reloadRequired = currentUser.currentProperty === undefined;

            currentUser.currentBrand = undefined;
            currentUser.currentProperty = flattenBrandProperties(user.brands).find(p => p.code === split[1]) as UserProperty;
        }

        setUser(currentUser); 

        if (reloadRequired) {            
            navigate("/");
            clearSelection();

            const temp = getTabAndIndex();
            //alert(`tab: ${temp.tab}, index: ${temp.index}`);
            setSelectedDrawerTab(temp.tab);
            setSelectedDrawerListItemIndex(temp.index);
        }*/
    }

    function getBrandPropertiesForSelect(): UserBrandPropertiesKeyValue[] {
        if (user.id) {            
            // return kvp for drop down state
            return convertUserBrandPropertiesToKeyValue(user.brands);
        }
        else {
            return [];
        }
    }

    function flattenBrandProperties(brands: UserBrand[]): UserProperty[] {
        let flatProperties: UserProperty[] = [];
        for (let b of brands) {
            for (let p of b.properties) {
                flatProperties = [...flatProperties, p]
            }
        }
        return flatProperties;
    }

    function innerHasIncludedFeatures(featureTypes: FeatureType[]) {
        if (user.currentProperty) {            
            // If we have a property seleceted
            return hasIncludedPropertyFeatures(featureTypes);
        }
        else {
            // If we have a brand selected
            return hasIncludedFeatures(featureTypes);
        }
    }

    function getPropertyMenu() {
        if (brandProperties.length > 1) {
            return brandProperties.filter(b => b.properties.length > 0).map(bp => renderBrandPropertyGroup(bp));
        }
        else if (brandProperties.length === 1 && brandProperties[0].properties.length > 0) {
            return (
                brandProperties[0].properties.map((p, index) => {
                    return (
                        <MenuItem key={index} value={p.key} sx={{ pl: theme.spacing(3) }} >
                            {p.value.toUpperCase()}
                        </MenuItem>
                    );
                })
            )
        }
        else {
            return (<></>);
        }
    }

    function renderBrandPropertyGroup(brand: UserBrandPropertiesKeyValue) {
        const items = brand.properties.map((p, index) => {
            return (
                <MenuItem key={index} value={p.key} sx={{ pl: theme.spacing(3) }} >
                    {p.value.toUpperCase()}
                </MenuItem>
            );
        });
        return [<div><ListSubheader>{brand.value.toUpperCase()}</ListSubheader></div>, items];
    };
    
    async function handleAdminCustomerChange(event: SelectChangeEvent) {
        const tenantId = event.target.value;
        const currentCustomer = user.customers?.find(c => c.id === tenantId);

        auth.userManager.removeUser();
        auth.userManager.signinRedirect({ acr_values: "tenant_id:" + tenantId, prompt: "login" }).then(() => {
            navigate("/");
            clearSelection();

            setUser({ ...user, id: undefined, currentCustomer, currentBrand: undefined, currentProperty: undefined });

            const temp = getTabAndIndex();
            setSelectedDrawerTab(temp.tab);
            setSelectedDrawerListItemIndex(temp.index);
        });
    }

    React.useEffect(() => {
        // If there's only 1 brand/property just select it.
        if (brandProperties.length === 1 && brandProperties[0].properties.length === 1) {       
            const key = brandProperties[0].properties[0].key;
            changeProperty(key);
        }
    }, [brandProperties]);    

    if (auth.isLoading) return <div></div>       

    return (
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        {(auth && auth.userData) &&
                            <MuiIconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: "36px",
                                    ...(open && { display: "none" }),
                                }}
                            >
                                <MenuIcon />
                            </MuiIconButton>
                        }
                        <Typography variant="h6" noWrap component="div">
                            {title}
                        </Typography>
                        <div style={{ marginLeft: "24px" }}>
                            <div></div>
                            {/*<SearchField*/}
                            {/*    lightBackground={true}*/}
                            {/*    label={strings.appBarSearch}*/}
                            {/*    onSubmit={handleSearchSubmit}*/}
                            {/*    onKeyPress={handleSearchKeyPress}                                */}
                            {/*/>*/}
                        </div>
                        <Box sx={{ flexGrow: 1 }} />
                        {(auth && auth.userData && user.id && isSuperAdmin()) &&
                        <>
                            <div className={classes.propertySelectContainer}>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <Select className={classes.propertySelect}
                                            id="admin-customer-select"
                                            inputProps={{'aria-label': 'Without label'}}
                                            MenuProps={{
                                                anchorOrigin: {vertical: "bottom", horizontal: "left"},
                                                transformOrigin: {vertical: "top", horizontal: "left"}
                                            }}
                                            value={user.currentCustomer?.id ?? ADMIN_TENANT_ID}
                                            onChange={handleAdminCustomerChange}
                                    >       
                                        <MenuItem key={ADMIN_TENANT_ID} value={ADMIN_TENANT_ID}>ADMIN</MenuItem>
                                        {
                                            user.customers?.map((c, idx) => {
                                                return (<MenuItem key={idx} value={c.id}>{c.name.toUpperCase()}</MenuItem>)
                                            })
                                        }
                                    </Select>
                                </FormControl>                                    
                            </div>
                        
                        </>
                        }
                        {(auth && auth.userData && user.id) &&
                        <>
                            <div className={classes.propertySelectContainer}>                            
                                <FormControl sx={{ m: 1, minWidth: 120, display: (isSuperAdmin(true) || !isSuperAdmin()) ? "block" : "none" }} size="small">
                                    <Select
                                        id="brand-property-select"
                                        className={classes.propertySelect}
                                        value={user.currentProperty?.code ?? user.currentBrand?.code ?? "0"}
                                        renderValue={(selected) => {
                                            if (user.currentProperty !== undefined) {
                                                return (
                                                    <Stack direction="row">
                                                        <Typography fontWeight="inherit">{user.currentProperty.name?.["en"].toUpperCase()}</Typography>
                                                        <Spacer />
                                                        <PropertyClock />
                                                    </Stack>
                                                );
                                            }                                            
                                            else {
                                                return strings.chooseProperty.toUpperCase();
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        onChange={handlePropertySelectChange}
                                        MenuProps={{
                                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                            transformOrigin: { vertical: "top", horizontal: "left" }
                                        }}>                                        
                                        {getPropertyMenu()}
                                    </Select>
                                </FormControl>
                            </div>
                        </>
                        }
                        <Box>
                            {(auth && auth.userData) &&
                                <MuiIconButton
                                    size="large"
                                    edge="end"
                                    aria-label="home"
                                    aria-haspopup="true"
                                    component={Link} to="/"
                                    onClick={handlHomeClick}
                                    color="inherit"
                                >
                                    <HomeIcon />
                                </MuiIconButton>
                            }
                            <MuiIconButton
                                size="large"
                                edge="end"
                                aria-label="Help"
                                aria-haspopup="true"
                                onClick={handleHelpClick}
                                color="inherit"
                                sx={{ display: "none" }}
                            >
                                <HelpIcon />
                            </MuiIconButton>
                            {(auth && auth.userData) && 
                                <MuiIconButton
                                    id="user-avatar"
                                    size="large"
                                    edge="end"
                                    onClick={handleUserPopoverOpen}
                                    aria-label="account of current user"
                                    aria-haspopup="true"                                
                                    color="inherit"
                                >
                                    <Avatar />
                                </MuiIconButton>
                            }
                            {(auth && auth.userData) &&
                                <Menu
                                    id="userPopover"
                                    MenuListProps={{
                                        'aria-labelledby': 'userPopover',
                                    }}
                                    anchorEl={userPopoverAnchorEl}
                                    open={Boolean(userPopoverAnchorEl)}
                                    onClose={handleUserPopoverClose}
                                >
                                    <MenuItem>
                                        <ListItemIcon>
                                            <PersonIcon fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>{auth.userData.profile.name!!}</ListItemText>
                                    </MenuItem>                                                                        
                                    <MenuItem onClick={signOut}>
                                        <ListItemIcon>
                                            <LogoutIcon fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>{strings.signOut}</ListItemText>
                                    </MenuItem>                                    
                                </Menu>
                            }
                            {(!auth || !auth.userData) && 
                                <Button
                            sx={{ my: 0.5, marginLeft: theme.spacing(2), backgroundColor: theme.palette.primary.light, ':hover': { backgroundColor: theme.palette.primary.main } }}
                                    variant="contained"                                    
                                    onClick={signIn}
                                    startIcon={<LoginIcon />}
                                    aria-label="Sign In"
                                >
                                    {strings.signIn}
                                </Button>
                            }                           
                        </Box>                        
                    </Toolbar>
                </AppBar>
                {(auth && auth.userData) &&
                    <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        {(showCMS() || showDashboard()  || showReports() || showSettings() || showAdmin()) &&
                            <>
                                <Box data-testid="Spacer" sx={{ width: "40px", flexGrow: 0, flexShrink: 0 }} />
                                <Box sx={{ flexGrow: 1 }}></Box>
                                {showCMS() &&  
                                    <Tooltip title={strings.drawerContent}>
                                        <MuiIconButton                                
                                            sx={{ "&:hover": { color: theme.palette.primary.light }, color: selectedDrawerTab === DrawerTabs.CMS ? theme.palette.primary.main : theme.palette.secondary.main }}
                                            onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerTabClicked(event, DrawerTabs.CMS)}>
                                            <CopyrightIcon />
                                        </MuiIconButton>
                                    </Tooltip>
                                }
                                <Spacer />
                                {showDashboard() &&  
                                    <Tooltip title={strings.drawerDashboard}>
                                        <MuiIconButton                                
                                            sx={{ "&:hover": { color: theme.palette.primary.light }, color: selectedDrawerTab === DrawerTabs.Support ? theme.palette.primary.main : theme.palette.secondary.main }}
                                            onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerTabClicked(event, DrawerTabs.Support)}>
                                            <SpeedIcon />
                                        </MuiIconButton>
                                    </Tooltip>
                                }
                                <Spacer />
                                {showReports() &&
                                    <Tooltip title={strings.drawerReporting}>
                                        <MuiIconButton
                                            sx={{ "&:hover": { color: theme.palette.primary.light }, color: selectedDrawerTab === DrawerTabs.Reporting ? theme.palette.primary.main : theme.palette.secondary.main }}
                                            onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerTabClicked(event, DrawerTabs.Reporting)}>
                                            <SummarizeIcon />
                                        </MuiIconButton>
                                    </Tooltip>
                                }
                                <Spacer />
                                {showSettings() &&
                                        <Tooltip title={strings.drawerSettings}>
                                            <MuiIconButton                                
                                                sx={{ "&:hover": { color: theme.palette.primary.light }, color: selectedDrawerTab === DrawerTabs.Settings ? theme.palette.primary.main : theme.palette.secondary.main }}
                                                onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerTabClicked(event, DrawerTabs.Settings)}>
                                                <SettingsIcon  />
                                            </MuiIconButton>
                                        </Tooltip>
                                }
                                <Spacer />
                                {/*Customers should never see admin panel. Admin-only with customer and property selected*/}
                                {showAdmin() && // && isSuperAdmin(true) &&
                                    <Tooltip title={strings.drawerAdministration}>
                                        <MuiIconButton                                
                                            sx={{ "&:hover": { color: theme.palette.primary.light }, color: selectedDrawerTab === DrawerTabs.Admin ? theme.palette.primary.main : theme.palette.secondary.main }}
                                            onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerTabClicked(event, DrawerTabs.Admin)}>
                                            <AdminPanelSettingsIcon  />
                                        </MuiIconButton>
                                    </Tooltip>
                                }
                            </>
                        }
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <MuiIconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </MuiIconButton>
                    </DrawerHeader>
                    <Divider />
                    <div hidden={selectedDrawerTab !== DrawerTabs.CMS}>
                        <List className={classes.muiListItemRoot}>        
                            {checkAccess("content_menu", Access.Read, true) &&                    
                                <ListItemButton
                                    key={strings.drawerMenuManager}
                                    component={Link} to="/menumanager"
                                    selected={selectedDrawerListItemIndex === DrawerItems.MenuManager}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.MenuManager)}
                                >
                                    <ListItemIcon>
                                        <WidgetsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerMenuManager} />
                                </ListItemButton>
                            }
                            {checkAccess("content_promotion", Access.Read, true) &&  
                                <ListItemButton
                                    key={strings.drawerPromotions}
                                    component={Link} to="/promotions/schedule"
                                    selected={selectedDrawerListItemIndex === DrawerItems.Promotions}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Promotions)}
                                >
                                    <ListItemIcon>
                                        <LocalOfferIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerPromotions} />
                                </ListItemButton>
                            }
                            {checkAccess("content_videolibrary", Access.Read, true) &&  
                                <ListItemButton
                                    key={strings.drawerVideoLibrary}
                                    component={Link} to="/videolibrary/videolist"
                                    selected={selectedDrawerListItemIndex === DrawerItems.VideoLibrary}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.VideoLibrary)}
                                >
                                    <ListItemIcon>
                                        <VideoLibraryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerVideoLibrary} />
                                </ListItemButton>
                            }
                        </List>
                        {(checkAccess("content_menu", Access.Read, true) || checkAccess("content_promotion", Access.Read, true)) &&  
                            <Divider />
                        }
                        <List className={classes.muiListItemRoot}>
                            {checkAccess("content_information", Access.Read, true) &&  
                                <ListItemButton
                                    key={strings.drawerInformation}
                                    component={Link} to="/information"
                                    selected={selectedDrawerListItemIndex === DrawerItems.Information}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Information)}
                                >
                                    <ListItemIcon>
                                        <InfoIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerInformation} />
                                </ListItemButton>
                            }
                            { checkAccess("content_restaurant", Access.Read, true) &&
                                <ListItemButton
                                    key={strings.drawerDining}
                                    component={Link } to="/restaurants/restaurants"
                                    selected={selectedDrawerListItemIndex === DrawerItems.Dining}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Dining)}
                                    
                                >
                                    <ListItemIcon>
                                        <RestaurantIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerDining} />
                                </ListItemButton> 
                            }
                             {checkAccess("content_activities", Access.Read, true) &&  
                                <ListItemButton
                                    key="daily-activities"
                                    component={Link} to="/activities/schedule"
                                    selected={selectedDrawerListItemIndex === DrawerItems.Activities}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Activities)}
                                >
                                    <ListItemIcon>
                                        <SelfImprovementIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerActivities} />
                                </ListItemButton>    
                            }    
                            
                            {checkAccess("content_messaging", Access.Read, true) &&  
                                <ListItemButton
                                    key="messaging-sent"
                                    component={Link} to="/messaging/sent"
                                    selected={selectedDrawerListItemIndex === DrawerItems.Messaging}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Messaging)}
                                >
                                    <ListItemIcon>
                                        <MailIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerMessaging} />
                                </ListItemButton>    
                            } 

                            {checkAccess("content_guestservices", Access.Read, true) &&  
                                <ListItemButton
                                    key="guestservices-services"
                                    component={Link} to="/guestservices/services"
                                    selected={selectedDrawerListItemIndex === DrawerItems.GuestServices}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.GuestServices)}
                                >
                                    <ListItemIcon>
                                        <DryCleaningIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerGuestServices} />
                                </ListItemButton>    
                            } 

                            {checkAccess("content_roomservice", Access.Read, true) &&  
                                <ListItemButton
                                    key="room-service"
                                    component={Link} to="/roomservice/menus"
                                    selected={selectedDrawerListItemIndex === DrawerItems.RoomService}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.RoomService)}
                                >
                                    <ListItemIcon>
                                        <RoomServiceIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerRoomService} />
                                </ListItemButton>    
                            } 
                                                       
                            {/* <ListItem
                                    key={strings.drawerImageOnly}
                                >
                                    <ListItemIcon>
                                        <ImageIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerImageOnly} />
                                </ListItem> 
                            */}

                        </List>
                    </div>
                    <div hidden={selectedDrawerTab !== DrawerTabs.Support}>
                        <List className={classes.muiListItemRoot}>
                            {(hasIncludedFeatures(["ITV", "MobileApp"]) && checkAccess("dashboard_guests", Access.Read, true)) &&  
                                <ListItemButton
                                        key={strings.drawerGuests}
                                        component={Link} to="/guests"
                                        selected={selectedDrawerListItemIndex === DrawerItems.Guests}
                                        onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Guests)}
                                    >
                                    <ListItemIcon>
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerGuests} />
                                </ListItemButton>
                            }
                            {checkAccess("dashboard_support", Access.Read, false) &&  
                                <ListItemButton
                                        key={strings.drawerSupport}
                                        component={Link} to="/support/summary"
                                        selected={selectedDrawerListItemIndex === DrawerItems.Support}
                                        onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Support)}
                                    >
                                    <ListItemIcon>
                                        <SupportAgentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerSupport} />
                                </ListItemButton>
                            }
                            {((innerHasIncludedFeatures(["NETWORK", "NETWORK_CIRCUITONLY", "POTS", "WIFI"])) && checkAccess("dashboard_network", Access.Read, false)) &&  
                                <ListItemButton
                                        key={strings.drawerNetwork}
                                        component={Link} to="/network/status"
                                        selected={selectedDrawerListItemIndex === DrawerItems.Network}
                                        onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Network)}
                                    >
                                    <ListItemIcon>
                                        <NetworkCheckIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerNetwork} />
                                </ListItemButton>
                            }
                            {/* hide for now. It was WIFI, per scope meeting: {(hasIncludedFeatures(["WIFI"])) && (checkAccess("dashboard_gateway", Access.Read, true)) && */}
                            {false &&  
                                <ListItemButton
                                        key={strings.drawerGateway}
                                        component={Link} to="/gateway"
                                        selected={selectedDrawerListItemIndex === DrawerItems.Gateway}
                                        onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Gateway)}
                                    >
                                    <ListItemIcon>
                                        <VpnLockIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerGateway} />
                                </ListItemButton>
                            }   
                        </List>
                    </div>
                    <div hidden={selectedDrawerTab !== DrawerTabs.Settings}>
                        <List className={classes.muiListItemRoot}>
                            {(checkAccess("security_user", Access.Read, false) || checkAccess("security_role", Access.Read, false)) && 
                                <ListItemButton
                                    key={strings.drawerSecurity}
                                    component={Link} to={(isSuperAdmin() && user?.currentCustomer === undefined) ? "/security/admin/users" : "/security/users" }
                                    selected={selectedDrawerListItemIndex === DrawerItems.AccountManager}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.AccountManager)}
                                >
                                    <ListItemIcon>
                                        <ManageAccountsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerSecurity} />
                                </ListItemButton>
                            }
                            {checkAccess("administration_company", Access.Read, false) && isSuperAdmin() && user?.currentCustomer === undefined &&
                                <ListItemButton
                                    key={strings.drawerCustomers}
                                    component={Link} to={(isSuperAdmin() && user?.currentCustomer === undefined) ? "/security/admin/customers" : "/"}
                                    selected={selectedDrawerListItemIndex === DrawerItems.CustomerManager}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.CustomerManager)}
                                >
                                    <ListItemIcon>
                                        <BusinessIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerCustomers} />
                                </ListItemButton>
                            } 
                            {checkAccess("administration_company", Access.Read, false) && isSuperAdmin() && user?.currentCustomer === undefined &&
                                <ListItemButton
                                    key={strings.drawerFeatures}
                                    component={Link} to={(isSuperAdmin() && user?.currentCustomer === undefined) ? "/security/admin/features" : "/"}
                                    selected={selectedDrawerListItemIndex === DrawerItems.FeatureManager}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.FeatureManager)}
                                >
                                    <ListItemIcon>
                                        <LayersIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerFeatures} />
                                </ListItemButton>
                            }
                            {(hasIncludedFeatures(["ITV", "MobileApp"])) && (checkAccess("configuration_property", Access.Read, true)) &&
                                <ListItemButton
                                    key={strings.drawerRoomManager}
                                    component={Link} to="/roommanager/rooms"
                                    selected={selectedDrawerListItemIndex === DrawerItems.RoomManager}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.RoomManager)}
                                >
                                    <ListItemIcon>
                                        <HotelIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerRoomManager} />
                                </ListItemButton>
                            }
                            {(hasIncludedFeatures(["ITV", "MobileApp"])) && (checkAccess("configuration_settings", Access.Read, true)) &&
                                <ListItemButton
                                    key={strings.drawerLanguages}
                                    component={Link} to="/languages"
                                    selected={selectedDrawerListItemIndex === DrawerItems.Languages}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Languages)}
                                >
                                    <ListItemIcon>
                                        <LanguageIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerLanguages} />
                                </ListItemButton>
                            }      
                            {(checkAccess("configuration_settings", Access.Read, true)) &&
                                <ListItemButton
                                    key={strings.drawerLocations}
                                    component={Link} to="/locations"
                                    selected={selectedDrawerListItemIndex === DrawerItems.Locations}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Locations)}
                                >
                                    <ListItemIcon>
                                        <LocationOnIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerLocations} />
                                </ListItemButton>
                            }   
                            {((isAdminCustomer() && checkAccess("administration_configuration", Access.Read, false)) || (!isAdminCustomer() && checkAccess("configuration_errors", Access.Read, true))) &&
                                <ListItemButton
                                    key={strings.drawerErrors}
                                    component={Link} to="/errors"
                                    selected={selectedDrawerListItemIndex === DrawerItems.ErrorMessages}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.CustomerManager)}
                                >
                                    <ListItemIcon>
                                        <ErrorIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerErrors} />
                                </ListItemButton>
                            } 
                        </List>
                    </div>
                    <div hidden={selectedDrawerTab !== DrawerTabs.Admin}>
                        <List className={classes.muiListItemRoot}>
                            {(user.currentCustomer !== undefined) && (checkAccess("administration_company", Access.Read, false) || checkAccess("administration_company", Access.Update, false)) &&  
                                <ListItemButton
                                    key={strings.drawerCompanyManager}
                                    component={Link} to="/company/brands"
                                    selected={selectedDrawerListItemIndex === DrawerItems.CompanyManager}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.CompanyManager)}
                                >
                                    <ListItemIcon>
                                        <ApartmentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerCompanyManager} />
                                </ListItemButton>
                            }
                            {(checkAccess("administration_skin", Access.Read, false) || checkAccess("administration_skin", Access.Update, false)) &&  
                                <ListItemButton
                                    key={strings.drawerSkinning}
                                    component={Link} to={isAdminCustomer() ? "/skin/default/skins" : "/skin/skins"}
                                    selected={selectedDrawerListItemIndex === DrawerItems.Skinning}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Skinning)}
                                >
                                    <ListItemIcon>
                                        <PaletteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerSkinning} />
                                </ListItemButton>
                            }
                            {((isAdminCustomer() && checkAccess("administration_configuration", Access.Read, false)) || (!isAdminCustomer() && checkAccess("administration_configuration", Access.Update, true))) &&  
                                <ListItemButton
                                    key={strings.drawerSettings}
                                    component={Link} to="/configuration/pms"
                                    selected={selectedDrawerListItemIndex === DrawerItems.Configuration}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Configuration)}
                                >
                                    <ListItemIcon>
                                        <BuildIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerConfiguration} />
                                </ListItemButton>
                            }
                            {(checkAccess("administration_pmscontroller", Access.Read, false, false)) && isSuperAdmin(false) &&  
                                <ListItemButton
                                    key={strings.drawerPMSController}
                                    component={Link} to="/pmscontroller"
                                    selected={selectedDrawerListItemIndex === DrawerItems.PMSController}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.PMSController)}
                                >
                                    <ListItemIcon>
                                        <TrafficIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerPMSController} />
                                </ListItemButton>
                            }
                        </List>
                    </div>
                    <div hidden={selectedDrawerTab !== DrawerTabs.Reporting}>
                        <List className={classes.muiListItemRoot}>
                            {hasIncludedFeatures(["ITV"]) &&
                                <ListItemButton
                                    key="reporting-itv"
                                    component={Link} to="/reporting/itv/sessions"
                                    selected={selectedDrawerListItemIndex === DrawerItems.Reporting}
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleDrawerListItemClick(event, DrawerItems.Reporting)}
                                >
                                    <ListItemIcon>
                                        <ConnectedTvIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={strings.drawerReportingITV} />
                                </ListItemButton>
                            }
                        </List>
                    </div>                    
                    <Box
                        sx={{ display: "flex", height: "100%", alignItems: "flex-end", marginBottom: theme.spacing(2) }}
                        className={clsx(classes.logo, {
                            [classes.logoOn]: open,
                            [classes.logoOff]: !open,
                        })}
                    >
                        <Grid container justifyContent="center">
                            <Grid item>
                                <img src={LogoImage} alt="logo" />
                            </Grid>
                            <Grid item>
                                <Typography variant="copyright" >
                                    {strings.copyright.replace("{{date}}", new Date().getFullYear().toString())}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    </Drawer>
                }
                <Box component="main" sx={{ flexGrow: 1, minWidth: 1200 }}>
                    <DrawerHeader />                                     
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/not-authorized" element={<NotAuthorized />} />
                        <Route path="/signout-callback" element={<Home />} />
                        <Route path="/signin-callback" element={<Home />} />
                        <Route path="/company/*" element={checkAccess("administration_company", Access.Read, false) ? <CompanyManager /> : <NotAuthorized /> } />
                        <Route path="/roommanager/*" element={checkAccess("configuration_property", Access.Read, true) ? <RoomManager /> : <NotAuthorized /> } />
                        <Route path="/security/*" element={checkAccess("security_user", Access.Read, false) || checkAccess("security_role", Access.Read, false) ? <SecurityManager /> : <NotAuthorized /> } />
                        <Route path="/security/admin/*" 
                            element={(checkAccess("security_user", Access.Read, false) || checkAccess("security_role", Access.Read, false)) && isSuperAdmin() && isAdminCustomer() 
                            ? <AdminSecurityManager /> 
                            : <NotAuthorized /> } 
                        />
                        <Route path="/security/admin/customers/*"
                            element={checkAccess("administration_company", Access.Read, false) && isSuperAdmin() && isAdminCustomer()
                                ? <CustomerList />
                                : <NotAuthorized />}
                        />
                        <Route path="/security/admin/features/*"
                            element={checkAccess("administration_company", Access.Read, false) && isSuperAdmin() && isAdminCustomer()
                                ? <FeatureList />
                                : <NotAuthorized />}
                        />
                        <Route path="/languages/*" element={checkAccess("configuration_settings", Access.Read, true) ? <Languages /> : <NotAuthorized /> } />
                        <Route path="/locations/*" element={checkAccess("configuration_settings", Access.Read, true) ? <Locations /> : <NotAuthorized /> } />
                        <Route path="/configuration/*" element={checkAccess("configuration_settings", Access.Read, false, false) ? <Configuration /> : <NotAuthorized /> } />
                        <Route path="/menumanager/*" element={checkAccess("content_menu", Access.Read, true) ? <MenuManager /> : <NotAuthorized /> } />
                        <Route path="/information/*" element={checkAccess("content_information", Access.Read, true) ? <InformationList /> : <NotAuthorized /> } />
                        <Route path="/activities/*" element={checkAccess("content_activities", Access.Read, true) ? <Activities /> : <NotAuthorized /> } />
                        <Route path="/promotions/*" element={checkAccess("content_promotion", Access.Read, true) ? <Promotions /> : <NotAuthorized /> } />
                        <Route path="/messaging/*" element={(checkAccess("content_messaging", Access.Read, true) || checkAccess("content_messaging_review", Access.Read, true)) ? <Messaging /> : <NotAuthorized /> } />
                        <Route path="/guests/*" element={checkAccess("dashboard_guests", Access.Read, true) ? <GuestList /> : <NotAuthorized /> } />
                        <Route path="/support/*" element={checkAccess("dashboard_support", Access.Read, false) ? <SupportHome /> : <NotAuthorized /> } />
                        <Route path="/network/*" element={checkAccess("dashboard_network", Access.Read, false) ? <Network /> : <NotAuthorized /> } />
                        {/* <Route path="/gateway/*" element={checkAccess("dashboard_gateway", Access.Read, true) ? <Gateway /> : <NotAuthorized /> } /> */}
                        <Route path="/skin/*" element={checkAccess("administration_skin", Access.Read, false) ? <SkinManager /> : <NotAuthorized /> } />
                        <Route path="/reporting/itv/*" element={checkAccess("reporting_itv", Access.Read, true) ? <ReportingHome /> : <NotAuthorized /> } />
                        <Route path="/restaurants/*" element={checkAccess("content_restaurant", Access.Read, true) ? <Restaurants /> : <NotAuthorized /> } />
                        <Route path="/guestservices/*" element={checkAccess("content_guestservices", Access.Read, true) ? <Services /> : <NotAuthorized /> } />
                        <Route path="/videolibrary/*" element={checkAccess("content_guestservices", Access.Read, true) ? <VideoList /> : <NotAuthorized /> } />
                        <Route path="/roomservice/*" element={checkAccess("content_roomservice", Access.Read, true) ? <RoomService /> : <NotAuthorized /> } />
                        <Route path="/pmscontroller/*" element={checkAccess("administration_pmscontroller", Access.Read, false) ? <Controller /> : <NotAuthorized /> } />
                        <Route path="/errors/*" element={(checkAccess("configuration_errors", Access.Read, true) || checkAccess("administration_configuration", Access.Read, false)) ? <ErrorMessages /> : <NotAuthorized /> } />
                    </Routes>
                </Box>
            </Box>
    );
}

export default Layout;