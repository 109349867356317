import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import React from "react";
import { createOption, createOptionSet, deleteOption, deleteOptionSet, getOptionSets, updateOption, updateOptionSet, saveOptionDisplayOrder } from "../../../api/roomservice/optionApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Option as OptionModel, OptionSet as OptionSetModel, initialOptionSetState, initialOptionState } from "../../../models/modules/roomservice/Option";
import { useUser } from "../../../providers/UserProvider";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import { moveBottom, moveDown, moveTop, moveUp } from "../../common/DisplayOrderButton";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import DisplayOrderGrid, { DisplayOrderGridRefObject } from "../../common/datatable/DisplayOrderGrid";
import Option from "./Option";
import OptionSet from "./OptionSet";

const OptionSetList = () => {    
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    // Option Set   
    const [optionSets, setOptionSets] = React.useState<OptionSetModel[]>([]);
    const [selectedOptionSetId, setSelectedOptionSetId] = React.useState("");
    const [selectedOptionSet, setSelectedOptionSet] = React.useState<OptionSetModel>(initialOptionSetState);
    const [addOptionSetMenuAnchorEl, setAddOptionSetMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const addOptionSetMenuOpen = Boolean(addOptionSetMenuAnchorEl);        
    const [deleteOptionSetMenuAnchorEl, setDeleteOptionSetMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const deleteOptionSetMenuOpen = Boolean(deleteOptionSetMenuAnchorEl); 
    const [optionSetDialogOpen, setOptionSetDialogOpen] = React.useState(false);

    // Option   
    const [selectedOptionId, setSelectedOptionId] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState<OptionModel>(initialOptionState);    
    const [optionDialogOpen, setOptionDialogOpen] = React.useState(false);
    const optionGridRef = React.useRef<DisplayOrderGridRefObject>(null);

    const [loading, setLoading] = React.useState(true);
    const [gridRefresh, setGridRefresh] = React.useState(false);    
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);            
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();        

    type RowType = 'OptionSet' | 'Option'

    React.useEffect(() => {
        const getInitialData = async() => {                        
            try {           
                clearRows("OptionSet");

                const response = await getOptionSets(axiosInstance, user?.currentProperty?.code ?? "");
                response.sort((a, b) => a.internalName.localeCompare(b.internalName));
                setOptionSets(response);

                // Reselect rows
                var optionSet = response.find(m => m.id === selectedOptionSetId);
                if (optionSet) {
                    setSelectedOptionSet(optionSet);                                         
                }
            }
            catch (error: unknown) {
            setNotify({
                    isOpen: true,
                    message: strings.roomServiceErrorRetreivingOptionSets.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });

                return;
            }
            finally {
                setLoading(false);
            }
        }

        getInitialData();
    }, [user.currentProperty?.code, gridRefresh])      

    function selectRow(id: string, rowType: RowType, openDialogOnSelect: boolean = false) { 
        switch (rowType) {
            case 'OptionSet': {
                setSelectedOptionSetId(id);               
                const optionSet = optionSets.find(os => os.id === id);
                if (optionSet) {
                    setSelectedOptionSet(optionSet);   
                    return optionSet;
                }
                break;
            }
            case 'Option': {
                setSelectedOptionId(id);               
                const option = selectedOptionSet?.options.find(o => o.id === id);
                if (option) {
                    setSelectedOption(option);    
                    return option;
                }
                break;
            }
            default: {

            }
        }

        return false;
    }

    function clearRows(type: RowType) {
        switch (type) {  
            case 'OptionSet': {                
                setSelectedOptionSetId("");
                setSelectedOptionSet(initialOptionSetState);
                setSelectedOptionId("");
                setSelectedOption(initialOptionState);
                break;
            }
            case 'Option': {                
                setSelectedOptionId("");
                setSelectedOption(initialOptionState);
                break;
            }
            default: {

            }
        }
    }   

    // OPTION SET
    function handleOptionSetCanPerformAction() {
        return !optionGridRef.current?.isDirty();
    }

    function handleOptionSetSelectionChange(optionSetSelectionModel: GridRowSelectionModel) {
        if (optionSetSelectionModel.length > 0) {
            selectRow(optionSetSelectionModel[0].toString(), 'OptionSet');
        }
    }

    function handleAddOptionSetButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        clearRows('OptionSet');
        setAddOptionSetMenuAnchorEl(null);
        setOptionSetDialogOpen(true);
    }

    function handleEditOptionSetClick(id: string) {
        if (selectRow(id, "OptionSet")) {
            setOptionSetDialogOpen(true);
        }
    }

    function handleDeleteOptionSetClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (!handleOptionSetCanPerformAction()) {
            return;
        }

        setSelectedOptionSetId(id);        

        const name = optionSets.find(os => os.id === id)?.internalName ?? ""; 
        alertDialog({
            title: strings.roomServiceDeleteOptionSetAlertTitle,
            message: strings.roomServiceDeleteOptionSetAlertMessage2.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedOptionSet(id);
        });       
    }

    async function deleteSelectedOptionSet(id: string) {       
        try {            
            await deleteOptionSet(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.roomServiceErrorDeletingOptionSet.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.roomServiceOptionSetDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        clearRows("OptionSet");
    }

    function handleOptionSetCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setOptionSetDialogOpen(false);
        clearRows("OptionSet");
    } 

    async function handleOptionSetSaved(optionSet: OptionSetModel, adding: boolean, event: React.MouseEvent<HTMLButtonElement>) {     
        try {
            if (adding) {
                await createOptionSet(axiosInstance, user?.currentProperty?.code ?? "", optionSet);
            }
            else {
                await updateOptionSet(axiosInstance, user?.currentProperty?.code ?? "", optionSet);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (adding
                    ? strings.roomServiceErrorAddingOptionSet
                    : strings.roomServiceErrorUpdatingOptionSet
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }
       
        setNotify({
            isOpen: true,
            message: strings.roomServiceOptionSetSavedSuccessfully,
            msgType: "success",
        });

        setOptionSetDialogOpen(false);
        setGridRefresh(!gridRefresh);
        return true;
    }       

    const optionSetColumns: GridColDef[] = [
        { field: "internalName", headerName: strings.internalName, flex: 2 },
        { field: "minimum", headerName: strings.min, valueGetter: (value, row) => row.minimum, flex: 1 },
        { field: "maximum", headerName: strings.max, valueGetter: (value, row) => row.maximum, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 2,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditOptionSetClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleDeleteOptionSetClick(params.id.toString(), event)}
                />
            ],
        }
    ]

    // OPTION
    function handleOptionSelectionChange(optionSelectionModel: GridRowSelectionModel) {
        if (optionSelectionModel.length > 0) {
            selectRow(optionSelectionModel[0].toString(), 'Option');
        }
    }

    /*function handleOptionSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const options = [...selectedOptionSet.options];
        options.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateOptionOrder(options);        
    }

    function updateOptionOrder(optionList: OptionModel[]) {   */
    function handleOptionSaveDisplayOrder(idList: any[]) {
        //const idList = optionList.map(o => o.id);
        saveOptionDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", selectedOptionSet.id, idList)
            .then(() => setGridRefresh(!gridRefresh))
            .catch((error: unknown) => {
                
                    
                    
                setNotify({
                    isOpen: true,
                    message: strings.roomServiceErrorUpdatingOption.replace("{{error}}", (error as Error).message),
                    msgType: "error",
                });
            })
    }  

    function handleAddOptionButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        clearRows("Option");
        setOptionDialogOpen(true);        
    }    

    async function handleEditOptionClick(id: string) {
        if (optionGridRef.current?.isDirty()) {
            return
        }

        if (selectRow(id, "Option")) {
            setOptionDialogOpen(true);
        }
    }

    function handleOptionDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {     
        if (optionGridRef.current?.isDirty()) {
            return
        }

        setSelectedOptionId(id);        

        const name = selectedOptionSet.options.find(o => o.id === id)?.name?.en ?? ""; 
        alertDialog({
            title: strings.roomServiceDeleteOptionAlertTitle,
            message: strings.roomServiceDeleteOptionAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedOption(id);
        });       
    }

    async function deleteSelectedOption(id: string) {       
        try {            
            await deleteOption(axiosInstance, user.currentProperty?.code ?? "", selectedOptionSet.id, id);
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: strings.roomServiceErrorDeletingOption.replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.roomServiceOptionDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        clearRows("Option");
    }

    function handleOptionCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setOptionDialogOpen(false);
        clearRows("Option");
    } 

    async function handleOptionSaved(option: OptionModel, adding: boolean, event: React.MouseEvent<HTMLButtonElement>) {         
        try {
            if (adding) {
                await createOption(axiosInstance, user?.currentProperty?.code ?? "", selectedOptionSet.id, option);
            }
            else {
                await updateOption(axiosInstance, user?.currentProperty?.code ?? "", selectedOptionSet.id, option);
            }
        }
        catch (error: unknown) {
            setNotify({
                isOpen: true,
                message: (adding
                    ? strings.roomServiceErrorAddingOption
                    : strings.roomServiceErrorUpdatingOption
                ).replace("{{error}}", (error as Error).message),
                msgType: "error",
            });

            return false;
        }
       
        setNotify({
            isOpen: true,
            message: strings.roomServiceOptionSavedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setOptionDialogOpen(false);
        return true;

        //// First update the selected option
        //var tempOptions = [...selectedOptionSet.options]; 
        //if (adding) {
        //    // Add new option
        //    option.displayOrder = selectedOptionSet.options.length;
        //    tempOptions.push(option);
        //}
        //else {
        //    // Update existing option
        //    var optionIndex = selectedOptionSet.options.findIndex(o => o.id === option.id);
        //    if (optionIndex !== -1) {                
        //        tempOptions[optionIndex] = option;                 
        //    }
        //}   

        //// Now update the selected option set with the new options array
        //setSelectedOptionSet((prevState) => ({
        //    ...prevState,
        //    options: tempOptions
        //}));

        return true;
    }       

    const optionColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, valueGetter: (value, row) => row.name.en, flex: 3 }, 
        //{ field: "sku", headerName: strings.sku, valueGetter: (value, row) => row.sku, flex: 2 },
        { field: "price", headerName: strings.price, type: "number", valueGetter: (value, row) => row.pricePerUnit, valueFormatter: (value?: number) => value && value !== 0 ? value.toLocaleString(undefined, { style: "currency", currency: "USD" }) : "", flex: 1 }, 
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditOptionClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleOptionDeleteClick(params.id.toString(), event)}
                />
            ],
        }
    ]   

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 155px)" }}>                                                   
            <Grid container spacing={2} sx={{ height: "100%" }}>                
                <Grid item xs={6} sx={{ height: "100%" }}>                        
                    <OptionSet
                        open={optionSetDialogOpen}
                        adding={selectedOptionSetId === ""}
                        optionLevel={0}
                        saveButtonLabel={strings.saveButtonTitle}
                        optionSet={selectedOptionSet}
                        onCancelClick={handleOptionSetCancelClick}
                        onSaved={handleOptionSetSaved}
                        onNotification={(options: NotificationOptions) => { setNotify(options) }}                        
                    />
                            
                    <DataGrid 
                        columns={optionSetColumns} 
                        rows={optionSets}
                        canPerformAction={handleOptionSetCanPerformAction}
                        onRowSelectionModelChange={handleOptionSetSelectionChange} 
                        rowSelectionModel={selectedOptionSetId}                                            
                        permissionKey="content_roomservice"                             
                        addButtonText={strings.roomServiceAddOptionSetButton}
                        onAddButtonClick={handleAddOptionSetButtonClick}   
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={6} sx={{ height: "100%" }}>       
                    <Option
                        open={optionDialogOpen}
                        adding={selectedOptionId === ""}
                        level={0}
                        optionSetId={selectedOptionSetId}
                        saveButtonLabel={strings.saveButtonTitle}
                        option={selectedOption}
                        onCancelClick={handleOptionCancelClick}
                        onSaved={handleOptionSaved}
                        onNotification={(options: NotificationOptions) => { setNotify(options) }}
                    />

                    <DisplayOrderGrid
                        permissionKey="content_roomservice"
                        rows={selectedOptionSet.options ?? []}                            
                        columns={optionColumns}
                        rowSelectionModel={selectedOptionId}
                        onRowSelectionModelChange={handleOptionSelectionChange}
                        addButtonDisabled={selectedOptionSetId === ""}
                        onAddButtonClick={handleAddOptionButtonClick}
                        addButtonText={strings.roomServiceAddOptionButton}
                        onSaveDisplayOrder={handleOptionSaveDisplayOrder}
                        ref={optionGridRef}
                    />   
                </Grid>
            </Grid> 

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>           
    )    
};

export default OptionSetList;